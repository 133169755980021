import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import NotFound from "../components/Dashboard/NotFound/NotFound";
import LandingPageView from "../components/_NEW//LandingPage/sections/view/LandingPageView";
import LoadingScreen from "../components/_NEW/LandingPage/loadingScreen/LoadingScreen";
import LandingPageService from "../service/LandingPageService";
import PublicService from "../service/PublicService";
import OldView from "../components/_OLD/LandingPage/view/LandingPageViewOld";
import { checkIsOldPage } from "../utils/checkIsOldPage";

const LandingPage = () => {
  const { type, id, promoter_id, promotion_link_id } = useParams();
  const navigate = useNavigate();

  /**
   * Validations
   *
   * states = "unchecked", true, false
   */
  const isValidType = [
    "finance",
    "stormgas",
    "property",
    "individual",
    "basic",
    "xyz",
  ];
  const [promotionLinkIsValid, setPromotionLinkIsValid] = useState("unchecked"); // unchecked, true , false
  const [landingPageIdIsValid, setLandingPageIdIsValid] = useState("unchecked"); // unchecked, true , false

  /**
   * Data
   */
  const [clickId, setClickId] = useState("");
  const [landingPageData, setLandingPageData] = useState(null);
  const [localReviews, setLocalReviews] = useState(null);
  const [promoter, setPromoter] = useState(null);
  const [user, setUser] = useState(null);
  const staticCheck = isValidType.includes(type);
  const isLoading =
    promotionLinkIsValid === "unchecked" ||
    landingPageIdIsValid === "unchecked" ||
    clickId.length === 0 ||
    landingPageData === null ||
    user === null ||
    localReviews === null ||
    promoter === null;

  const [isOldPage, setIsOldPage] = useState(true);

  /**
   * Generate click
   */
  const generateAClick = async () => {
    if (promoter_id.includes("viewmode")) {
      return setClickId("viewmode");
    }

    let key;
    try {
      key = JSON?.parse?.(localStorage?.getItem("visited_key"));
    } catch (e) {}
    key = key || localStorage?.getItem("visited_key");

    if (Array.isArray(key)) {
      let verified = false;
      await Promise.all(
        key.map((e) =>
          LandingPageService.clickCheck({
            click_id: e,
            landingpage_id: id,
          }).then(({ data }) => {
            verified = data.verified;

            if (data.verified) {
              setClickId(e);
            }
          })
        )
      );
      if (verified) {
        return;
      }
    }

    const { data } = await LandingPageService.detail(id);
    console.log(data);
    const { data: clickId } = await LandingPageService.click({
      landingpage_id: data._id,
      promoter_id: promoter_id,
      user_id: data.user_id,
    });
    setClickId(clickId?.click_id);
    let clickIds = [];

    let visited_key;
    try {
      visited_key = JSON?.parse?.(localStorage?.getItem("visited_key"));
    } catch (e) {}
    visited_key = visited_key || localStorage?.getItem("visited_key");

    if (!visited_key) {
      clickIds.push(clickId?.click_id);
    } else {
      clickIds = [
        ...JSON.parse(localStorage.getItem("visited_key")),
        clickId?.click_id,
      ];
    }

    localStorage.setItem("visited_key", JSON.stringify(clickIds));
  };

  const getPromoterAndSet = async () => {
    if (promoter_id !== "viewmode") {
      const { data: Promoter } = await PublicService.getPromoterInitials(
        promoter_id
      );
      setPromoter(Promoter);
    } else {
      setPromoter({
        firstname: "Promoter Vorname",
        lastname: "Promoter Nachname",
      });
    }
  };

  /**
   * Promotion link check
   */
  useEffect(() => {
    // guards
    if (!staticCheck) return;
    if (promotionLinkIsValid != "unchecked") return;

    // if its not there, its ok
    if (promotion_link_id === undefined) {
      setPromotionLinkIsValid(true);
      return;
    }

    // promotion link not checked
    PublicService.getPromotionLink(promotion_link_id)
      .then((data) => {
        if (data?.data?.promotionLink?._id) {
          setPromotionLinkIsValid(true);
        } else {
          setPromotionLinkIsValid(false);
        }
      })
      .catch((_) => {
        setPromotionLinkIsValid(false);
      });
  }, [promotionLinkIsValid]);

  /**
   * Landingpage check
   */
  useEffect(() => {
    // guards
    if (!staticCheck) return;
    if (landingPageIdIsValid !== "unchecked") return;

    // promotion link not checked
    PublicService.getLandingpage(id)
      .then(async ({ data }) => {
        setLandingPageIdIsValid(true);
        setLandingPageData(data.landingPage);
        setLocalReviews(data.localReviews);

        const relevantKeys = [
          "accompanyingTextOne",
          "introVideoHeader",
          "introductoryText",
          "accompanyingTextTwo",
          "accompanyingTextThree",
          "questionaireIntroductionText",
        ];

        const checkedNewPage =
          data.landingPage.theme === "xyz"
            ? true
            : relevantKeys.some(
                (key) =>
                  data.landingPage[key] && checkIsOldPage(data.landingPage[key])
              );

        setIsOldPage(!checkedNewPage);

        const userRes = await PublicService.getUserName(
          data.landingPage.user_id
        );
        setUser(userRes.data);
      })
      .catch((_) => {
        setLandingPageIdIsValid(false);
      });
  }, [id]);

  /**
   * On Success
   */
  useEffect(() => {
    if (!(promotionLinkIsValid === true && landingPageIdIsValid === true))
      return;

    generateAClick();
    getPromoterAndSet();
  }, [promotionLinkIsValid, landingPageIdIsValid]);

  /**
   * Link nicht richtig
   */
  if (!staticCheck) {
    return <NotFound />;
  }

  /**
   * Link is not valid
   */
  if (!promotionLinkIsValid || !landingPageIdIsValid) {
    navigate(
      "/notfound?note=Dieser Link ist leider nicht mehr gültig. Bitte fordere beim Absender einen neuen an."
    );
  }

  /**
   * Loading...
   */
  if (isLoading) {
    return <LoadingScreen />;
  } else {
    if (!isOldPage) {
      return (
        <LandingPageView
          user={user}
          clickid={clickId}
          landingPageData={landingPageData}
          promoter={promoter}
          localReviews={localReviews}
          type={type}
          promoter_id={promoter_id}
          landingPageId={id}
        />
      );
    } else {
      return (
        <OldView
          user={user}
          clickid={clickId}
          landingPageData={landingPageData}
          promoter={promoter}
          localReviews={localReviews}
          type={type}
          promoter_id={promoter_id}
          landingPageId={id}
        />
      );
    }
  }
};

export default LandingPage;
