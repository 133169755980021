import React, { forwardRef, useImperativeHandle, useRef } from "react";
import Box from "../Layout/Box";
import MediaUpload from "../shared/MediaUpload";
import { useLandingPage } from "../../../components/New_LP_Configuration/context/LandingPageContext";

const IntroductionToClient = forwardRef((props, ref) => {
  // Hole direkt die landingPageData aus dem Context
  const { landingPageData, setLandingPageData } = useLandingPage();

  // Extrahiere die URLs aus dem Context
  const videoSrc = landingPageData.persentationVideo;
  const thumbnailSrc = landingPageData.thumbnail;

  const thumbnailRef = useRef(null);
  const videoRef = useRef(null);

  useImperativeHandle(ref, () => ({
    async save() {
      console.log("IntroductionToClient.save()");
      const [newThumbnailUrl, newVideoUrl] = await Promise.all([
        thumbnailRef.current?.saveLocalToCloud?.(),
        videoRef.current?.saveLocalToCloud?.(),
      ]);

      console.log(newThumbnailUrl);
      return {
        landingPageData: {
          ...(newThumbnailUrl.success && {
            thumbnail: newThumbnailUrl.secure_url,
          }),
          ...(newVideoUrl.success && {
            presentationVideoDuration: newVideoUrl.duration,
          }),
          ...(newVideoUrl.success && {
            persentationVideo: newVideoUrl.secure_url,
          }),
        },
      };
    },
  }));

  return (
    <Box>
      <h1 className="text-lg font-bold text-center lg:text-2xl sm:text-xl">
        Dein Video
      </h1>

      <MediaUpload
        ref={videoRef}
        full={true}
        type="video"
        id="landing-page-config-change-video"
        textSrcNotThere="Video hochladen"
        textSrcThere="Video ändern"
        url={videoSrc}
        setUrl={(newUrl) =>
          setLandingPageData((prev) => ({
            ...prev,
            persentationVideo: newUrl,
          }))
        }
      />

      <div className="font-semibold text-center mb-[30px] mt-10 lg:text-2xl sm:text-xl">
        Videovorschaubild
      </div>

      <MediaUpload
        ref={thumbnailRef}
        full={true}
        id={"upload-UploadTutorials-first"}
        url={thumbnailSrc}
        setUrl={(newUrl) =>
          setLandingPageData((prev) => ({
            ...prev,
            thumbnail: newUrl,
          }))
        }
        textSrcNotThere={"Miniaturbild ändern"}
        textSrcThere={"Miniaturbild hochladen"}
      />
    </Box>
  );
});

export default IntroductionToClient;
