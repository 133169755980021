import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useReducer,
  useRef,
  useState,
} from "react";
import { useLandingPage } from "../../../components/New_LP_Configuration/context/LandingPageContext";
import { stripObjectKeep } from "../../../utils/StripObject";
import ClipboardButtonsBar from "../../../components/Dashboard/kampaign/ClipboardButtonsBar";
import Box from "../Layout/Box";
import MediaUpload from "../shared/MediaUpload";
import { Button, Card, Modal } from "antd";
import ReactCrop, { Crop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { UploadIcon } from "../../../assets/Svgs/UploadIcon";
import { useMessageContext } from "../../../context/messageContext";
import { selectUser } from "../../../redux/auth/selectors";
import UploadToCloudService from "../../../service/UploadToCloudService";
import { aboTarif } from "../../../utils/divers";

const HeroConfig = forwardRef(({ type }, ref) => {
  const { landingPageData, setLandingPageData } = useLandingPage();
  const initialConfig = stripObjectKeep(landingPageData, [
    "primary_image",
    "logoSrc",
    "background_image",
    "introductoryText",
    "accompanyingTextOne",
    "accompanyingTextTwo",
    "accompanyingTextThree",
    "logo",
    "activateBackgroundIntroductoryText",
    "activateBackgroundAccompanyingTextOne",
    "activateBackgroundAccompanyingTextTwo",
    "activateBackgroundAccompanyingTextThree",
    "activateBackgroundVideoText",
    "introVideoHeader",
    "questionaireIntroductionText",
  ]);

  const [heroConfig, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case "SET":
        return action.payload.data;
      case "SET_KEY":
        return { ...state, [action.payload.key]: action.payload.value };
      default:
        return state;
    }
  }, initialConfig);

  const primaryImageRef = useRef(null);
  const backgroundImageRef = useRef(null);
  const logoRef = useRef(null);

  const isTextContrasRelevant = type === "finance" || type === "stormgas";
  const { showMessage } = useMessageContext();
  const { user } = useSelector(selectUser);

  useImperativeHandle(ref, () => ({
    async save() {
      const [newPrimaryImage, newBackgroundImage] = await Promise.all([
        primaryImageRef?.current?.saveLocalToCloud?.(),
        backgroundImageRef?.current?.saveLocalToCloud?.(),
      ]);

      let newLogo = "";
      console.log("!!", logoRef?.current?.value);
      console.log("!!", heroConfig.logoSrc);

      const typeOptions = {
        image: {
          allowedFormats: ["image/jpeg", "image/png", "image/jpg"],
          maxFileSizeInMB: 10,
          resourceType: "image",
          wrapperClass: "w-full h-[200px]",
        },
      };
      const maxFileSizeInBytes =
        typeOptions["image"].maxFileSizeInMB * 1024 * 1024;
      const cleanFile = (file) => {
        if (!file) {
          showMessage("error", "Datei ungültig");
          return false;
        }

        if (!typeOptions["image"].allowedFormats.includes(file.type)) {
          showMessage("error", "Ungültiges Dateiformat.");
          return false;
        }

        if (file.size > maxFileSizeInBytes) {
          showMessage(
            "error",
            `Die Dateigröße überschreitet das maximale Limit von ${typeOptions["image"].maxFileSizeInMB} MB.`
          );
          return false;
        }

        // probably a blob
        if (!file.name) return file;

        // Create a cleaned file name
        const cleanedFileName = file.name.trim().replace(/[^\w\d]/g, "_");

        // Create a new File object with the cleaned file name
        const cleanedFile = new File([file], cleanedFileName, {
          type: file.type,
        });

        // Return the new File object
        return cleanedFile;
      };

      if (!heroConfig?.logoSrc?.includes?.("https://") && heroConfig?.logoSrc) {
        const fileFromSession = await fetch(heroConfig.logoSrc);

        const blob = await fileFromSession.blob();
        console.log(blob);
        const cleandBlob = cleanFile(blob);
        console.log(cleandBlob);
        if (!cleandBlob) {
          throw new Error("Ungültige Datei nach der Sanierung.");
        }
        const res = await UploadToCloudService.upload(
          cleandBlob,
          typeOptions["image"].resourceType
        );
        console.log(res);
        const { secure_url } = res;
        newLogo = secure_url;
      }

      console.log(newLogo);

      return {
        landingPageData: {
          ...heroConfig,
          ...(newPrimaryImage?.success && {
            primary_image: newPrimaryImage?.secure_url ?? "",
          }),
          ...(newBackgroundImage?.success && {
            background_image: newBackgroundImage?.secure_url ?? "",
          }),
          ...{
            logoSrc: newLogo || heroConfig?.logoSrc || "",
          },
        },
      };
    },
  }));

  const handleSetKey = (key, value) => {
    dispatch({
      type: "SET_KEY",
      payload: { key, value },
    });
  };

  return (
    <Box className="!flex !flex-col gap-4 p-4 items-center justify-center text-center">
      <>
        <LogoUploadRow
          uploadRef={logoRef}
          text="Dein Logo"
          setLandingPageData={setLandingPageData}
          url={heroConfig.logoSrc}
          setUrl={(newUrl) => handleSetKey("logoSrc", newUrl)}
          textSrcNotThere="Logo hochladen"
          textSrcThere="Logo ändern"
          imageUploadId="upload-logo-landingpageconfig-first"
          emptyCheck={false}
          disabled={aboTarif(user) < 2}
        />
      </>
    </Box>
  );
});

export const ImageUploadRow = ({
  text,
  uploadRef,
  url,
  setUrl,
  textSrcNotThere,
  textSrcThere,
  imageUploadId,
  emptyCheck = true,
}) => (
  <div className="flex flex-col min-h-[200px] items-center gap-4 primary:gap-10">
    <div className="w-full text-base font-semibold text-center lg:text-9xl sm:text-xl primary:text-left">
      {text}
    </div>
    <MediaUpload
      ref={uploadRef}
      id={imageUploadId}
      url={url}
      setUrl={setUrl}
      textSrcNotThere={textSrcNotThere}
      textSrcThere={textSrcThere}
      emptyCheck={emptyCheck}
    />
  </div>
);

export const LogoUploadRow = ({
  text,
  uploadRef,
  setLandingPageData,
  url,
  setUrl,
  textSrcNotThere,
  textSrcThere,
  imageUploadId,
  emptyCheck = true,
  disabled = false,
  center = false,
}) => {
  const [crop, setCrop] = useState();
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const [isCropModalVisible, setIsCropModalVisible] = useState(false);
  const imgRef = useRef();

  const onCropComplete = useCallback(
    (crop, pixelCrop) => {
      console.log(crop);
      console.log(pixelCrop);
      const canvas = document.createElement("canvas");
      const image = new Image();
      image.crossOrigin = "anonymous";
      image.src = url;
      image.onload = () => {
        const scaleX = image.naturalWidth / imgRef.current.width;
        const scaleY = image.naturalHeight / imgRef.current.height;

        // Use the original crop dimensions
        canvas.width = crop.width;
        canvas.height = crop.height;

        const ctx = canvas.getContext("2d");
        if (ctx) {
          ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
          );
        }

        canvas.toBlob((blob) => {
          if (blob) {
            // Revoke the previous object URL to prevent memory leaks
            if (croppedImageUrl) {
              URL.revokeObjectURL(croppedImageUrl);
            }
            const newCroppedImageUrl = URL.createObjectURL(blob);
            setCroppedImageUrl(newCroppedImageUrl);
          }
        }, "image/png"); // Changed to PNG for better quality
      };
    },
    [url, imgRef]
  );

  const handleRemove = () => {
    setUrl("");
    setCroppedImageUrl(null);
  };

  const { showMessage } = useMessageContext();

  const handleApplyCrop = useCallback(async () => {
    console.log("x", croppedImageUrl);
    if (croppedImageUrl) {
      const typeOptions = {
        image: {
          allowedFormats: ["image/jpeg", "image/png", "image/jpg"],
          maxFileSizeInMB: 10,
          resourceType: "image",
          wrapperClass: "w-full h-[200px]",
        },
      };
      const maxFileSizeInBytes =
        typeOptions["image"].maxFileSizeInMB * 1024 * 1024;
      const cleanFile = (file) => {
        if (!file) {
          showMessage("error", "Datei ungültig");
          return false;
        }

        if (!typeOptions["image"].allowedFormats.includes(file.type)) {
          showMessage("error", "Ungültiges Dateiformat.");
          return false;
        }

        if (file.size > maxFileSizeInBytes) {
          showMessage(
            "error",
            `Die Dateigröße überschreitet das maximale Limit von ${typeOptions["image"].maxFileSizeInMB} MB.`
          );
          return false;
        }

        // probably a blob
        if (!file.name) return file;

        // Create a cleaned file name
        const cleanedFileName = file.name.trim().replace(/[^\w\d]/g, "_");

        // Create a new File object with the cleaned file name
        const cleanedFile = new File([file], cleanedFileName, {
          type: file.type,
        });

        // Return the new File object
        return cleanedFile;
      };

      console.log(0);
      if (croppedImageUrl) {
        console.log(1);
        const fileFromSession = await fetch(croppedImageUrl);

        console.log(2);
        const blob = await fileFromSession.blob();
        console.log(3);
        console.log(blob);
        const cleandBlob = cleanFile(blob);
        console.log(cleandBlob);
        console.log(4);
        if (!cleandBlob) {
          throw new Error("Ungültige Datei nach der Sanierung.");
        }
        console.log(5);
        const res = await UploadToCloudService.upload(
          cleandBlob,
          typeOptions["image"].resourceType
        );
        console.log(6);
        console.log(res);
        const { secure_url } = res;
        console.log("123123!!", secure_url);
        setUrl(secure_url);
      }

      setIsCropModalVisible(false);
    }
  }, [croppedImageUrl]);

  return (
    <div
      className={`flex flex-col min-h-[200px]  ${
        center ? "justify-center" : "justify-center w-full"
      } items-center gap-4  `}
    >
      <div className="text-2xl font-semibold text-center primary:text-center w-full">
        {text}
      </div>
      <div className="flex flex-col items-center gap-4 w-full h-full">
        <label
          htmlFor={"x1"}
          className={`relative block cursor-pointer w-full flex ${
            center ? "justify-center" : "justify-end"
          } min-h-[200px]`}
          onClick={() => {
            if (disabled) return;
            uploadRef.current.value = "";
            uploadRef.current?.click?.();
          }}
        >
          {disabled && (
            <>
              <div
                style={{ zIndex: 99999999999999999 }}
                className="absolute inset-0 bg-gray-100 backdrop-filter backdrop-blur-md"
              />
              <Card
                style={{ zIndex: 99999999999999999 }}
                className="absolute  left-1/2 transform -translate-x-1/2 p-2 text-center"
              >
                <h2 className="text-xl font-bold mb-4">Upgrade benötigt</h2>

                <Link to="/dashboard/upgrade">
                  <Button type="primary" className="bg-[#001D47] h-[40px] mr-2">
                    Upgrade
                  </Button>
                </Link>
              </Card>
            </>
          )}
          <img
            className={`absolute object-cover   ${
              center
                ? "rounded-full w-[200px] h-[200px]"
                : "rounded-11xl w-full h-full"
            }`}
            src={url}
          />
          {!center && (
            <div className="absolute top-0 bottom-0 left-0 right-0 cover-upload-bg-second rounded-11xl">
              <div className="absolute flex flex-col items-center text-white bottom-4 right-4 xs:bottom-6 xs:right-6">
                <UploadIcon />
                <p className="text-base font-semibold sm:text-xl">
                  Logo hochladen
                </p>
              </div>
            </div>
          )}
        </label>

        {!disabled && (
          <>
            <div
              className={`flex gap-2 w-full ${
                center ? "justify-center" : "justify-end"
              }`}
            >
              <Button onClick={() => setIsCropModalVisible(true)}>
                Zuschneiden
              </Button>

              <Button onClick={handleRemove} danger>
                Entfernen
              </Button>
            </div>
          </>
        )}

        <Modal
          open={isCropModalVisible}
          onCancel={() => {
            setIsCropModalVisible(false);
          }}
          onOk={handleApplyCrop}
          destroyOnClose
        >
          <ReactCrop
            crop={crop}
            onChange={(c) => setCrop(c)}
            onComplete={onCropComplete}
          >
            <img ref={imgRef} src={url} alt="Crop" />
          </ReactCrop>
        </Modal>

        <input
          type="file"
          ref={uploadRef}
          id={imageUploadId}
          className="hidden"
          onChange={(e) => {
            const file = e.target.files?.[0];
            if (file) {
              const reader = new FileReader();
              reader.onload = (e) => {
                setUrl(e.target?.result);
                setLandingPageData((prevData) => ({
                  ...prevData,
                  logoSrc: e.target?.result,
                }));
              };
              reader.readAsDataURL(file);
            }
          }}
          accept="image/*"
        />
      </div>
    </div>
  );
};

export default HeroConfig;
