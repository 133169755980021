import React from "react";
import { Heading, Img, Text } from "./..";

export default function UserProfileSelection({
  headingText = "01 Aussuchen",
  descriptionText = "Schau dir die Beschreibungen der Links an und wähle den für die Empfehlung am besten passenden aus.",
  userImage = "/images3/img_user.svg",
  imageHeight = "44px",
  mT = "mt-5",
  descriptionClass,
  headingClass,
  ...props
}) {
  return (
    <div
      {...props}
      className={`${props.className} flex flex-col items-end w-[24%] mdx:w-full p-3.5 bg-[#fafafa] rounded-[10px]`}
    >
      <div className="flex flex-col items-start gap-3 self-stretch">
        <Heading
          size="headingxs"
          as="h6"
          className={`text-[16px] font-semibold text-[#000000]-900 ${headingClass}`}
        >
          {headingText}
        </Heading>
        <Text
          as="p"
          className={`w-full text-[12px] font-normal leading-4 text-blue_gray-400 ${descriptionClass}`}
        >
          {descriptionText}
        </Text>
      </div>
      <Img
        src={userImage}
        alt="User Image"
        className={`${mT} mr-4 h-[${imageHeight}]`}
      />
    </div>
  );
}
