import React, { useState, useRef } from "react";
import { ImagePlus } from "lucide-react";
import { useLandingPage } from "../../context/LandingPageContext";
import { Modal, Switch, Input, Tooltip } from "antd";
import Inputs from "../../../_NEW/shared/Input";
import { EditOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import EditableText from "../templates/shared/EditableField";
import { useIsMobileView } from "./ViewToggle";
import ClipboardButtonsBar from "../../../Dashboard/kampaign/ClipboardButtonsBar";

const ClosingFormularKonfig = ({ visible }) => {
  const { landingPageData, setLandingPageData } = useLandingPage();
  const [privacy, setPrivacy] = useState(false);
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const isMobile = useIsMobileView();
  const [error, setError] = useState("");

  // Modal States
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const fileInputRef = useRef(null);

  const handleBackgroundClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Öffnet Datei-Uploa
    }
  };

  const handleBackgroundChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const blobURL = URL.createObjectURL(file);
      updateLandingPageData("background_image", blobURL);
    }
  };

  // Öffnet ein Modal mit individuellem Inhalt
  const openModal = (type) => {
    setModalType(type);
    setIsModalOpen(true);
    setError("");
  };

  // Aktualisiert die LandingPage-Einstellungen
  const updateLandingPageData = (field, value) => {
    setLandingPageData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const validateInputs = () => {
    if (modalType === "whatsapp" && landingPageData.activateWhatsApp) {
      // Überprüfen, ob WhatsApp aktiviert ist und alle Felder ausgefüllt sind
      if (
        !landingPageData.whatsappNumber ||
        !landingPageData.whatsappButtonTextTwo ||
        !landingPageData.whatsappText
      ) {
        setError("Alle WhatsApp-Felder müssen ausgefüllt werden.");
        return false;
      }
    }
    if (modalType === "calendly" && landingPageData.activateCalendly) {
      // Überprüfen, ob Calendly aktiviert ist und der Link ausgefüllt ist
      if (!landingPageData.calendlyLink) {
        setError("Der Calendly-Link muss ausgefüllt werden.");
        return false;
      }
    }
    setError(""); // Reset error if validation passes
    return true;
  };

  const handleSaveAndClose = () => {
    if (validateInputs()) {
      setIsModalOpen(false); // Close modal only if validation is successful
    }
  };

  return (
    visible && (
      <div className="relative w-screen min-h-screen py-5 overflow-scroll">
        <div
          className="absolute inset-0 z-0 hover:cursor-pointer"
          onClick={handleBackgroundClick}
          style={{
            backgroundImage: `url(${landingPageData?.background_image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />

        {/* Schwarzes Overlay-Icon leicht versetzt */}
        <div
          onClick={handleBackgroundClick}
          className="absolute top-8 right-24 z-10 bg-white/70 hover:bg-white p-2 rounded-full shadow cursor-pointer transition"
          title="Hintergrund bearbeiten"
        >
          <ImagePlus className="w-8 h-8 text-black" />
        </div>
        <input
          type="file"
          accept="image/*"
          ref={fileInputRef}
          onChange={handleBackgroundChange}
          style={{ display: "none" }}
        />
        <div className="relative z-10 top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] flex items-center justify-center py-5">
          <div
            className="w-[90%] xs:w-[80%] p-5 bg-white rounded-sm max-w-[1000px] overflow-scroll"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="max-w-[800px] m-auto flex flex-col gap-5">
              <div className="flex flex-col gap-4 p-8">
                {/* Positiver Verlauf */}
                <div className="flex items-start gap-2">
                  <Tooltip title="Dieser Text wird angezeigt, wenn Fragen positiv verlaufen">
                    <QuestionCircleOutlined className="mt-1 text-blue-500" />
                  </Tooltip>
                  <EditableText
                    maxLines={2}
                    text={landingPageData.aufgrundDeinerAngaben}
                    onSave={(newValue) =>
                      updateLandingPageData("aufgrundDeinerAngaben", newValue)
                    }
                    element="h2"
                    className={`font-bold mb-2 text-green-600 ${
                      isMobile ? "text-xl" : "text-xl md:text-3xl"
                    }`}
                  />
                </div>

                {/* Negativer Verlauf */}
                <div className="flex items-start gap-2">
                  <Tooltip title="Dieser Text wird angezeigt, wenn Fragen negativ verlaufen">
                    <QuestionCircleOutlined className="mt-1 text-red-500" />
                  </Tooltip>
                  <EditableText
                    maxLines={2}
                    text={landingPageData.negativeText}
                    onSave={(newValue) =>
                      updateLandingPageData("negativeText", newValue)
                    }
                    element="h2"
                    className={`font-bold mb-2 text-red-600 ${
                      isMobile ? "text-xl" : "text-xl md:text-3xl"
                    }`}
                  />
                </div>
              </div>

              {/* Eingabefelder */}
              <div className="flex flex-col gap-5 md:flex-row">
                <Inputs
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  label="Dein Name"
                />
                <Inputs
                  onChange={(e) => setNumber(e.target.value)}
                  value={number}
                  label="Mobilnummer"
                  tel
                />
              </div>

              <Datenschutz checkbox={privacy} setCheckbox={setPrivacy} />

              {/* Buttons öffnen Modal mit Konfigurationen */}
              <div className="flex flex-col gap-2 md:flex-row">
                <Button
                  text={
                    landingPageData.activateWhatsApp
                      ? landingPageData.whatsappButtonTextTwo ||
                        "WhatsApp schreiben"
                      : "WhatsApp deaktiviert"
                  }
                  backgroundColor="#25D366"
                  onClick={() => openModal("whatsapp")}
                  disabled={!landingPageData.activateWhatsApp} // Button ist deaktiviert, wenn activateWhatsApp false ist
                />
                <Button
                  text="Termin aussuchen"
                  backgroundColor="#0084F7"
                  onClick={() => openModal("calendly")}
                  disabled={!landingPageData.activateCalendly} // Button ist deaktiviert, wenn activateCalendly false ist
                />
                <Button
                  text="Rückrufbitte"
                  backgroundColor="#FFD814"
                  textColor="black"
                  onClick={() => openModal("recall")}
                  disabled={!landingPageData.activate_recall} // Button ist deaktiviert, wenn activate_recall false ist
                />
              </div>
            </div>
          </div>
        </div>

        {/* Modal für Konfiguration */}
        <Modal
          title="Einstellungen"
          open={isModalOpen}
          onCancel={() => setIsModalOpen(false)}
          footer={[
            <Button
              key="close"
              text="Speichern & Schließen"
              onClick={handleSaveAndClose}
              backgroundColor="#001D47"
            />,
          ]}
        >
          {modalType && (
            <div className="flex flex-col gap-3">
              {modalType === "whatsapp" && (
                <>
                  <div className="flex justify-between items-center">
                    <label>WhatsApp aktivieren</label>
                    <Switch
                      checked={landingPageData.activateWhatsApp}
                      onChange={(checked) =>
                        updateLandingPageData("activateWhatsApp", checked)
                      }
                      size="small"
                    />
                  </div>
                  <label>WhatsApp-Nummer</label>
                  <Input
                    value={landingPageData.whatsappNumber}
                    onChange={(e) =>
                      updateLandingPageData("whatsappNumber", e.target.value)
                    }
                  />
                  <label>WhatsApp Button Text (Version 2)</label>
                  <Input
                    value={landingPageData.whatsappButtonTextTwo}
                    onChange={(e) =>
                      updateLandingPageData(
                        "whatsappButtonTextTwo",
                        e.target.value
                      )
                    }
                  />
                  <div className="flex flex-row gap-0 items-center">
                    <label>WhatsApp Nachricht</label>
                    <ClipboardButtonsBar
                      names={["Promoter Vorname", "Promoter Nachname"]}
                      classes="!text-[0.8rem]"
                    />
                  </div>

                  <Input.TextArea
                    value={landingPageData.whatsappText}
                    onChange={(e) =>
                      updateLandingPageData("whatsappText", e.target.value)
                    }
                  />
                </>
              )}

              {modalType === "calendly" && (
                <>
                  <div className="flex justify-between items-center">
                    <label>Calendly aktivieren</label>
                    <Switch
                      checked={landingPageData.activateCalendly}
                      onChange={(checked) =>
                        updateLandingPageData("activateCalendly", checked)
                      }
                      size="small"
                    />
                  </div>
                  <label>Calendly-Link</label>
                  <Input
                    value={landingPageData.calendlyLink}
                    onChange={(e) =>
                      updateLandingPageData("calendlyLink", e.target.value)
                    }
                  />
                </>
              )}

              {modalType === "recall" && (
                <>
                  <div className="flex justify-between items-center">
                    <label>Rückruf aktivieren</label>
                    <Switch
                      checked={landingPageData.activate_recall}
                      onChange={(checked) =>
                        updateLandingPageData("activate_recall", checked)
                      }
                      size="small"
                    />
                  </div>
                </>
              )}
              {error && <div className="text-red-500">{error}</div>}
            </div>
          )}
        </Modal>
      </div>
    )
  );
};

export default ClosingFormularKonfig;

const Button = ({
  backgroundColor = "grey",
  text,
  textColor = "white",
  onClick,
  disabled = false, // Der disabled-Prop steuert den deaktivierten Zustand
}) => (
  <div className="relative w-full group">
    {/* Edit-Icon oben rechts */}
    <div className="absolute top-1 right-1 z-10">
      <EditOutlined
        className="text-white bg-black/50 rounded-full p-1 group-hover:scale-110 transition-transform"
        style={{ fontSize: "18px" }}
      />
    </div>

    {/* Klickbarer Button */}
    <button
      onClick={onClick}
      style={{
        background: backgroundColor,
        color: textColor,
      }}
      className={`w-full p-3 font-semibold rounded-sm md:p-4 text-md md:text-lg ${
        disabled
          ? "opacity-50" // Wenn der Button deaktiviert ist
          : ""
      }`}
    >
      {text}
    </button>
  </div>
);

const Datenschutz = ({ checkbox, setCheckbox }) => (
  <>
    <div className="font-semibold text-[14px]">
      Diese Daten werden nur für die Erstkontaktaufnahme genutzt und können auf
      Wunsch gelöscht werden.
    </div>
    <div className="flex items-center gap-3">
      <input
        required
        type="checkbox"
        checked={checkbox}
        onChange={(e) => setCheckbox(e.target.checked)}
        className="accent-blue-500 scale-110"
      />
      <p className="font-semibold text-[14px]">
        Ich stimme der Datenschutzerklärung zu
      </p>
    </div>
  </>
);
