import React, { useEffect } from "react";
import FinanceView from "./FinanceView";
import PropertyView from "./PropertyView";
import StromGasView from "./StromGasView";
import BasicView from "./BasicView";
import { sendEventUser } from "../../../../../utils/analytics";
import XyzView from "./XyzView";

const View = (props) => {
  const { type, clickid, landingPageId } = props;

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (clickid !== "viewmode") {
        sendEventUser({
          category: "page",
          action: "page_viewLP",
          label: "lppageview",
          value: 1,
          landingPageId: landingPageId,
        });
      }
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [landingPageId]);

  if (type === "finance" || type === "individual") {
    return <FinanceView {...props} />;
  }

  if (type === "property") {
    return <PropertyView {...props} />;
  }

  if (type === "xyz") {
    return <XyzView {...props} />;
  }

  if (type === "stormgas") {
    return <StromGasView {...props} />;
  }

  if (type === "basic") {
    return <BasicView {...props} />;
  }

  return null;
};

View.defaultProps = {
  affiliate: false,
};

export default View;
