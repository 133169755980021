import React, { useMemo, useEffect } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Rate, Avatar } from "antd";
import { iconsList } from "../../shared/IconMapping";
import { StarFilled, StarOutlined } from "@ant-design/icons";
import { Collapse } from "antd";
import { ThumbsUpIcon, CircleCheckBig, LoaderCircle } from "lucide-react";
import Footer from "../footer/Footer";
import "../../../../New_LP_Configuration/components/templates/layouts/TemplateXYZ.css";
import "../../../../New_LP_Configuration/components/templates/layouts/templateXyz/XYZ.css";
import { fontMapping } from "../../../../New_LP_Configuration/components/templates/shared/fontMapping";
import { sendEventUser } from "../../../../../utils/analytics";
import VideoWithThumbnailView from "../../shared/VideoWithThumbnail";
import Slide from "../localReviews/Slider";
import PromoSection from "../../shared/XYZ/PromoSection";

const { Panel } = Collapse;

const DEFAULT_IMAGE = "https://placehold.co/400x300@2x.png";

const XyzView = ({
  landingPageData,
  type,
  promoter_id,
  landingPageId,
  user,
  promoter,
  clickid,
  affiliate,
  localReviews,
}) => {
  const { id, promoterID } = useParams();
  const { templateXyzRef, logoSrc, thumbnail, persentationVideo } =
    landingPageData;
  var {
    headerSection,
    aboutSection,
    cardSection,
    pictureSection,
    bannerSection,
    rating,
    darkMode,
    font,
    templateColor,
    faq,
  } = templateXyzRef;
  var { header, subheader } = headerSection;
  var { activate: activateBanner, logos } = bannerSection;
  var {
    activate: activateAbout,
    card1header,
    card1text,
    card2header,
    card2text,
    card3header,
    card3text,
    header: aboutheader,
    subheader: aboutsubheader,
    subheadertext,
  } = aboutSection;
  var { activate: activateCardSection, header: headerCard } = cardSection;
  var { activate: activatePicture, one, two } = pictureSection;
  var { stars, text: ratingText } = rating;
  const getIconComponent = (iconName) => {
    const foundIcon = iconsList.find((icon) => icon.name === iconName);
    return foundIcon ? foundIcon.Icon : null;
  };
  const Icon1 = getIconComponent(aboutSection.icon1);
  const Icon2 = getIconComponent(aboutSection.icon2);
  const Icon3 = getIconComponent(aboutSection.icon3);

  const navigate = useNavigate();
  const replacePlaceholders = (text, replacements) => {
    if (typeof text !== "string") {
      console.warn("Invalid text input for replacement:", text);
      return text;
    }

    Object.keys(replacements).forEach((key) => {
      const value = replacements[key];
      text = text.replace(new RegExp(key, "g"), value);
    });

    return text;
  };

  const bgColor = templateColor.replace(
    /rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*[\d.]+)?\)/,
    (match, r, g, b) => `rgba(${r},${g},${b},0.1)`
  );

  const promoterReplacements = {
    "{Promoter Vorname}": promoter?.firstname?.trim() || "Promoter Vorname",
    "{Promoter Nachname}": promoter?.lastname?.trim() || "Promoter Nachname",
    "{PPartnerVorname}": promoter?.firstname?.trim() || "Promoter Vorname",
    "{PPartnerNachname}": promoter?.lastname?.trim() || "Promoter Nachname",
  };
  header = replacePlaceholders(header, promoterReplacements);
  subheader = replacePlaceholders(subheader, promoterReplacements);
  aboutheader = replacePlaceholders(aboutheader, promoterReplacements);
  aboutsubheader = replacePlaceholders(aboutsubheader, promoterReplacements);
  subheadertext = replacePlaceholders(subheadertext, promoterReplacements);
  card1header = replacePlaceholders(card1header, promoterReplacements);
  card1text = replacePlaceholders(card1text, promoterReplacements);
  card2header = replacePlaceholders(card2header, promoterReplacements);
  card2text = replacePlaceholders(card2text, promoterReplacements);
  card3header = replacePlaceholders(card3header, promoterReplacements);
  card3text = replacePlaceholders(card3text, promoterReplacements);
  headerCard = replacePlaceholders(headerCard, promoterReplacements);
  one.header = replacePlaceholders(one.header, promoterReplacements);
  one.text = replacePlaceholders(one.text, promoterReplacements);
  two.header = replacePlaceholders(two.header, promoterReplacements);
  two.text = replacePlaceholders(two.text, promoterReplacements);
  ratingText = replacePlaceholders(ratingText, promoterReplacements);
  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
  }, [darkMode]);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };

  useEffect(() => {
    const setGradientBackground = () => {
      const isDarkMode = darkMode;
      const logosWrapper = document.querySelector(".logos-wrapper");
      console.log(logosWrapper);

      if (!logosWrapper) {
        console.warn("logos-wrapper not found");
        return;
      }

      // Styles dynamisch ändern
      logosWrapper.style.setProperty(
        "--gradient-bg-left",
        isDarkMode
          ? "linear-gradient(to left, rgba(255, 255, 255, 0), rgb(30 41 59))"
          : "linear-gradient(to left, rgba(255, 255, 255, 0), white)"
      );

      logosWrapper.style.setProperty(
        "--gradient-bg-right",
        isDarkMode
          ? "linear-gradient(to right, rgba(255, 255, 255, 0), rgb(30 41 59))"
          : "linear-gradient(to right, rgba(255, 255, 255, 0), white)"
      );
    };

    setGradientBackground();

    document.documentElement.addEventListener(
      "classChange",
      setGradientBackground
    );
    return () => {
      document.documentElement.removeEventListener(
        "classChange",
        setGradientBackground
      );
    };
  }, [darkMode]);

  const avatarUrls = useMemo(() => {
    return Array(5)
      .fill(0)
      .map((_, index) => `/ReviewPeople/${index + 1}.jfif`);
  }, []);

  const sectionOrder = ["one", "two", "three", "four", "five"];
  const faqKeys = Object.keys(faq).filter(
    (key) => faq[key] && (faq[key].question || faq[key].answer)
  );

  const activeSections = sectionOrder.filter((key) => {
    const section = pictureSection[key];
    return (
      section && (section.header || section.text || section.img || section.icon)
    );
  });

  return (
    <div className="flex w-full flex-col">
      <div className={`w-[100vw]`}>
        {/* Header Section */}
        <motion.section
          className="w-full bg-[#E0E9F1] py-8 md:py-12 md:px-8 px-4 override-font"
          style={{
            "--font-family": fontMapping[font] || fontMapping.modern,
          }}
        >
          <motion.div
            className="text-center flex flex-col items-center gap-4"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.3 }}
            variants={containerVariants}
          >
            {/* Logo */}
            <motion.img
              src={logoSrc}
              alt="logo"
              className="h-[60px]"
              variants={itemVariants}
            />
            <div
              className={`bg-white dark:bg-slate-800 rounded-2xl py-8 md:py-12 px-4 md:px-32 lg:px-56
              flex flex-col items-center gap-8 max-w-full dark:text-slate-50`}
            >
              {/* Headline */}
              <motion.h1
                className="font-bold text-4xl md:text-6xl group relative"
                dangerouslySetInnerHTML={{ __html: header }}
                variants={itemVariants}
              />

              {/* Subheader */}
              <motion.p
                className="text-slate-600 dark:text-slate-300 max-w-3xl mx-auto group relative text-lg"
                dangerouslySetInnerHTML={{ __html: subheader }}
                variants={itemVariants}
              />

              {/* Button */}

              <motion.button
                className={`text-white px-8 py-4 md:px-8 md:py-4 md:text-xl shadow-2xl
               rounded-full hover:opacity-90 transition-opacity text-xl relative`}
                style={{
                  background: templateXyzRef?.templateColor,
                  boxShadow: `inset 2px 2px 5px rgba(255, 255, 255, 0.4), 
                  inset -2px -2px 5px rgba(0, 0, 0, 0.2),
                  4px 4px 10px rgba(0, 0, 0, 0.3)`,
                }}
                variants={itemVariants}
                onClick={() =>
                  navigate(
                    `/questionare/${type}/${landingPageId}/${promoter_id}/${clickid}`,
                    { state: { affiliate } }
                  )
                }
              >
                {landingPageData.whatsappButtonTextOne}
              </motion.button>

              {/* Avatar & Ratings */}
              <motion.div
                className="flex flex-col items-center gap-2"
                variants={itemVariants}
              >
                <div className="flex flex-row items-center">
                  <div className="flex">
                    <Avatar.Group>
                      {avatarUrls.map((url, index) => (
                        <Avatar
                          key={index}
                          src={url}
                          style={{
                            width: "2.8rem",
                            height: "2.8rem",
                            fontSize: "2.8rem",
                            backgroundColor: "#f0f0f0",
                            marginLeft: index === 0 ? "0" : "-15px",
                          }}
                        />
                      ))}
                    </Avatar.Group>
                  </div>
                  <div className="flex flex-col gap-2 items-start">
                    <div className="flex">
                      <Rate
                        allowHalf
                        count={5}
                        value={rating.stars}
                        disabled
                        style={{ fontSize: "1.2rem" }}
                      />
                    </div>
                    <span className="ml-2 text-md inline-flex justify-center">
                      <span
                        dangerouslySetInnerHTML={{ __html: rating.text }}
                      ></span>
                    </span>
                  </div>
                </div>
              </motion.div>

              {/* Video mit Thumbnail (erscheint zuletzt) */}
              <motion.div className="" variants={itemVariants}>
                <VideoWithThumbnailView
                  thumbnailSrc={thumbnail}
                  videoSrc={persentationVideo}
                  themeColor={templateColor}
                />
              </motion.div>
              {/* Banner Section */}
              {activateBanner && (
                <section className="-mt-8 px-4 w-full dark:bg-slate-800">
                  <p
                    className="text-slate-600 dark:text-slate-300 max-w-3xl mx-auto group relative text-sm md:text-xl"
                    dangerouslySetInnerHTML={{ __html: bannerSection.header }}
                  ></p>
                  <div className="logos-wrapper">
                    <div className="logos">
                      {/* ERSTE Slide */}
                      <div className="logos-slide">
                        {logos
                          .filter((Icon) => Icon)
                          .map((Icon, idx) => (
                            <img className="icon" key={idx} src={Icon} />
                          ))}
                      </div>

                      {/* ZWEITE Slide */}
                      <div className="logos-slide">
                        {logos
                          .filter((Icon) => Icon)
                          .map((Icon, idx) => (
                            <img className="icon" key={idx} src={Icon} />
                          ))}
                      </div>
                    </div>
                  </div>
                </section>
              )}
            </div>
          </motion.div>
        </motion.section>
        {/* Statisitic Cards */}
        {activateCardSection && (
          <motion.section
            className="py-8 md:py-12 px-6 md:px-16 lg:px-36 dark:bg-slate-800 dark:text-white override-font"
            style={{
              "--font-family": fontMapping[font] || fontMapping.modern,
            }}
          >
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.3 }}
              variants={containerVariants}
            >
              {/* Header */}
              <motion.h2
                className="font-bold mb-6 md:mb-8 text-4xl text-center"
                dangerouslySetInnerHTML={{ __html: headerCard }}
                variants={itemVariants} // Header wird zuerst animiert
              ></motion.h2>

              {/* Erste Zeile mit zwei Cards */}
              <motion.div
                className="grid gap-4 md:gap-6 mb-6 md:mb-8 md:grid-cols-2 grid-cols-1"
                variants={containerVariants} // Staggering für Cards
              >
                <motion.div
                  className="p-2 md:p-6 rounded-2xl hidden md:flex flex-col items-center justify-center text-center"
                  style={{
                    background: bgColor,
                    color: templateColor,
                  }}
                  variants={itemVariants}
                >
                  <div className="flex items-center gap-2 mb-2">
                    <ThumbsUpIcon className="md:w-14 md:h-14 h-8 w-8" />
                    <span className="text-6xl font-bold ml-2">
                      {cardSection.card1Number}
                    </span>
                  </div>
                  <p
                    className="text-lg"
                    dangerouslySetInnerHTML={{ __html: cardSection.card1Text }}
                  ></p>
                </motion.div>

                <motion.div
                  className="p-6 rounded-2xl text-white md:flex flex-col items-center justify-center text-center"
                  style={{ background: templateXyzRef?.templateColor }}
                  variants={itemVariants}
                >
                  <div className="flex items-center justify-center mb-2">
                    <span className="text-3xl md:text-6xl font-bold">
                      {cardSection.card2Number}
                    </span>
                    <div className="flex ml-2">
                      <Rate
                        allowHalf
                        value={cardSection.card2Number || 5.0}
                        disabled
                        className="ml-2 text-4xl flex gap-1"
                        character={({ index }) => {
                          const rating = cardSection.card2Number || 5.0;
                          const wholeStars = Math.floor(rating);
                          const isHalfStar = rating - wholeStars >= 0.5;

                          const starSize = "1.5rem";

                          const starStyle = {
                            color: "white",
                            fontSize: starSize,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          };

                          if (index < wholeStars) {
                            return <StarFilled style={starStyle} />;
                          } else if (index === wholeStars && isHalfStar) {
                            return (
                              <span
                                className="relative inline-flex items-center justify-center"
                                style={{ width: starSize, height: starSize }}
                              >
                                {/* Volle Outline im Hintergrund */}
                                <StarOutlined
                                  style={{
                                    ...starStyle,
                                    position: "absolute",
                                    left: 0,
                                    top: 0,
                                    width: starSize,
                                    height: starSize,
                                  }}
                                />
                                {/* Linke Hälfte gefüllt */}
                                <span
                                  className="absolute top-0 left-0 w-1/2 h-full overflow-hidden flex items-center justify-center"
                                  style={{ width: "50%", height: "100%" }}
                                >
                                  <StarFilled
                                    style={{
                                      ...starStyle,
                                      position: "absolute",
                                      left: 0,
                                      width: starSize,
                                      height: starSize,
                                    }}
                                  />
                                </span>
                              </span>
                            );
                          } else {
                            return <StarOutlined style={starStyle} />;
                          }
                        }}
                      />
                    </div>
                  </div>
                  <p
                    className="text-lg"
                    dangerouslySetInnerHTML={{ __html: cardSection.card2Text }}
                  ></p>
                </motion.div>
              </motion.div>

              {/* Zweite Zeile mit drei Cards */}
              <motion.div
                className="grid gap-4 md:gap-6 grid-cols-2 md:grid-cols-3"
                variants={containerVariants}
              >
                <motion.div
                  className="p-2 md:p-6 rounded-2xl text-white md:hidden flex flex-col items-center justify-start text-center"
                  style={{
                    background: bgColor,
                    color: templateColor,
                  }}
                  variants={itemVariants}
                >
                  <div className="flex items-center mb-2">
                    <ThumbsUpIcon className="md:w-14 md:h-14 h-8 w-8" />
                    <span className="text-3xl md:text-6xl font-bold ml-2">
                      {cardSection.card1Number}
                    </span>
                  </div>
                  <p
                    className="text-lg"
                    dangerouslySetInnerHTML={{ __html: cardSection.card1Text }}
                  ></p>
                </motion.div>
                <motion.div
                  className="p-2 md:p-6 rounded-2xl text-white flex flex-col items-center justify-start md:justify-center text-center"
                  style={{ background: templateXyzRef?.templateColor }}
                  variants={itemVariants}
                >
                  <div className="flex items-center mb-2">
                    <LoaderCircle className="md:w-14 md:h-14 h-8 w-8" />
                    <span className="text-3xl md:text-6xl font-bold ml-2">
                      {cardSection.card3Number}
                    </span>
                  </div>
                  <p
                    className="text-lg"
                    dangerouslySetInnerHTML={{ __html: cardSection.card3Text }}
                  ></p>
                </motion.div>

                <motion.div
                  className="p-2 md:p-6 rounded-2xl flex flex-col items-center justify-start md:justify-center text-center"
                  style={{
                    background: bgColor,
                    color: templateColor,
                  }}
                  variants={itemVariants}
                >
                  <div className="flex items-center mb-2">
                    <span className="text-3xl md:text-6xl font-bold">
                      {cardSection.card4Number}
                    </span>
                    <div className="flex ml-2">
                      <Rate
                        allowHalf
                        value={cardSection.card4Number || 5.0}
                        disabled
                        className="ml-2 text-4xl flex gap-1 items-start"
                        character={({ index }) => {
                          const rating = cardSection.card4Number || 5.0;
                          const wholeStars = Math.floor(rating);
                          const isHalfStar = rating - wholeStars >= 0.5;

                          // Unterscheidung für mobile und größere Geräte
                          const starSizeMobile = "0.5rem";
                          const starSizeDesktop = "1.2rem";

                          const starStyle = {
                            color: templateColor,
                            fontSize: `clamp(${starSizeMobile}, 2vw, ${starSizeDesktop})`, // Dynamische Anpassung je nach Gerät
                            width: `clamp(${starSizeMobile}, 2vw, ${starSizeDesktop})`,
                            height: `clamp(${starSizeMobile}, 2vw, ${starSizeDesktop})`,
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "center",
                          };

                          if (index < wholeStars) {
                            return <StarFilled style={starStyle} />;
                          } else if (index === wholeStars && isHalfStar) {
                            return (
                              <span
                                className="relative inline-flex items-start justify-center"
                                style={{
                                  width: starStyle.width,
                                  height: starStyle.height,
                                  fontSize: starStyle.fontSize,
                                  display: "inline-block",
                                  position: "relative",
                                }}
                              >
                                {/* Volle Outline im Hintergrund */}
                                <StarOutlined
                                  style={{
                                    ...starStyle,
                                    position: "absolute",
                                    left: 0,
                                    top: 0,
                                  }}
                                />
                                {/* Linke Hälfte gefüllt */}
                                <span
                                  className="absolute top-0 left-0 w-1/2 h-full overflow-hidden flex items-start justify-center"
                                  style={{
                                    width: "50%",
                                    height: "100%",
                                    position: "absolute",
                                    left: 0,
                                    top: 0,
                                  }}
                                >
                                  <StarFilled
                                    style={{
                                      ...starStyle,
                                      position: "absolute",
                                      left: 0,
                                      top: 0,
                                    }}
                                  />
                                </span>
                              </span>
                            );
                          } else {
                            return <StarOutlined style={starStyle} />;
                          }
                        }}
                      />
                    </div>
                  </div>
                  <p
                    className="text-lg"
                    dangerouslySetInnerHTML={{ __html: cardSection.card4Text }}
                  ></p>
                </motion.div>

                <motion.div
                  className="p-2 md:p-6 rounded-2xl text-white flex flex-col items-center justify-start md:justify-center text-center"
                  style={{
                    background: templateXyzRef?.templateColor,
                  }}
                  variants={itemVariants}
                >
                  <div className="flex items-center mb-2">
                    <CircleCheckBig className="md:w-14 md:h-14 h-8 w-8" />
                    <span className="text-3xl md:text-6xl font-bold ml-2">
                      {cardSection.card5Number}
                    </span>
                  </div>
                  <p
                    className="text-lg"
                    dangerouslySetInnerHTML={{ __html: cardSection.card5Text }}
                  ></p>
                </motion.div>
              </motion.div>
            </motion.div>
          </motion.section>
        )}
        {/* Features Section*/}
        {activateAbout && (
          <motion.section
            className="w-full py-8 px-8 md:px-24 md:py-12 bg-gray-50 dark:bg-slate-600 bg-[#E0E9F1] dark:text-white override-font"
            style={{
              "--font-family": fontMapping[font] || fontMapping.modern,
            }}
          >
            <motion.div
              className="text-center"
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.5 }}
              variants={containerVariants}
            >
              {/* Haupt-Überschrift */}
              <motion.h2
                className="font-bold mb-2 text-4xl"
                dangerouslySetInnerHTML={{ __html: aboutheader }}
                variants={itemVariants}
              ></motion.h2>

              {/* Unter-Überschrift */}
              <motion.h3
                className="font-bold mb-4 text-xl dark:text-neutral-200 text-neutral-700"
                dangerouslySetInnerHTML={{ __html: aboutsubheader }}
                variants={itemVariants}
              ></motion.h3>

              {/* Grid mit 3 Spalten ab Medium-Breakpoint */}
              <motion.div
                className="grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-8 bg-white dark:bg-slate-800 p-4 md:p-12 rounded-2xl"
                variants={containerVariants}
              >
                {/* Card 1 */}
                <motion.div
                  className="text-center flex flex-col gap-4"
                  variants={itemVariants}
                >
                  <div className="w-16 h-16 mx-auto mb-8">
                    {Icon1 && <Icon1 size={84} color={templateColor} />}
                  </div>
                  <h4
                    className="font-bold mb-2 text-2xl"
                    dangerouslySetInnerHTML={{ __html: card1header }}
                  ></h4>
                  <p
                    className="text-gray-600 dark:text-gray-300"
                    dangerouslySetInnerHTML={{ __html: card1text }}
                  ></p>
                </motion.div>

                {/* Card 2 */}
                <motion.div
                  className="text-center flex flex-col gap-4"
                  variants={itemVariants}
                >
                  <div className="w-16 h-16 mx-auto mb-8">
                    {Icon2 && <Icon2 size={84} color={templateColor} />}
                  </div>
                  <h4
                    className="font-bold mb-2 text-2xl"
                    dangerouslySetInnerHTML={{ __html: card2header }}
                  ></h4>
                  <p
                    className="text-gray-600 dark:text-gray-300"
                    dangerouslySetInnerHTML={{ __html: card2text }}
                  ></p>
                </motion.div>

                {/* Card 3 */}
                <motion.div
                  className="text-center flex flex-col gap-4"
                  variants={itemVariants}
                >
                  <div className="w-16 h-16 mx-auto mb-8">
                    {Icon3 && <Icon3 size={84} color={templateColor} />}
                  </div>
                  <h4
                    className="font-bold mb-2 text-2xl"
                    dangerouslySetInnerHTML={{ __html: card3header }}
                  ></h4>
                  <p
                    className="text-gray-600 dark:text-gray-300"
                    dangerouslySetInnerHTML={{ __html: card3text }}
                  ></p>
                </motion.div>
              </motion.div>
              {/* Button */}
              <motion.button
                className={`text-white px-8 py-4 md:px-8 md:py-4 md:text-xl shadow-2xl rounded-full hover:opacity-90 transition-opacity text-xl relative mt-12`}
                style={{
                  background: templateXyzRef?.templateColor,
                  boxShadow: `inset 2px 2px 5px rgba(255, 255, 255, 0.4), 
                  inset -2px -2px 5px rgba(0, 0, 0, 0.2),
                  4px 4px 10px rgba(0, 0, 0, 0.3)`,
                }}
                variants={itemVariants}
                onClick={() =>
                  navigate(
                    `/questionare/${type}/${landingPageId}/${promoter_id}/${clickid}`,
                    { state: { affiliate } }
                  )
                }
              >
                {landingPageData.whatsappButtonTextTwo}
              </motion.button>

              {/* Testimonials & Button */}
              <motion.div
                className="flex flex-row items-center justify-center mt-12 w-full"
                variants={containerVariants}
              >
                {/* Avatare */}
                <motion.div className="flex" variants={itemVariants}>
                  <Avatar.Group>
                    {avatarUrls.map((url, index) => (
                      <Avatar
                        key={index}
                        src={url}
                        style={{
                          width: "2.7rem",
                          height: "2.7rem",
                          fontSize: "2.7rem",
                          backgroundColor: "#f0f0f0",
                          marginLeft: index === 0 ? "0" : "-15px",
                        }}
                      />
                    ))}
                  </Avatar.Group>
                </motion.div>

                {/* Sterne Bewertung */}
                <div
                  className="flex items-start flex-col gap-2"
                  variants={itemVariants}
                >
                  <div className="flex">
                    <Rate
                      allowHalf
                      count={5}
                      value={rating.stars2}
                      disabled
                      style={{ fontSize: "1.2rem" }}
                    />
                  </div>
                  <span className="ml-2 text-md inline-flex justify-center">
                    <span
                      dangerouslySetInnerHTML={{ __html: rating.text2 }}
                    ></span>
                  </span>
                </div>
              </motion.div>
            </motion.div>
          </motion.section>
        )}
        {/* Sektion 4 - Schutz */}
        {activatePicture && (
          <motion.section
            className="w-full py-8 px-8 md:px-12 lg:px-36 md:py-12 bg-slate-100 dark:bg-slate-800 dark:text-white override-font"
            style={{
              "--font-family": fontMapping[font] || fontMapping.modern,
            }}
          >
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.2 }}
              variants={containerVariants}
              className="space-y-12"
            >
              {activeSections.map((key, index) => {
                const item = pictureSection[key];
                const imageSrc = item.img || DEFAULT_IMAGE;

                return (
                  <motion.div
                    key={key}
                    className={`flex flex-col ${
                      index % 2 === 0 ? "md:flex-row" : "md:flex-row-reverse"
                    } items-center gap-12`}
                  >
                    {/* Textblock */}
                    <motion.div
                      className={`w-full md:w-1/2 relative`}
                      variants={itemVariants}
                    >
                      <h2
                        className="font-bold mb-4 text-3xl md:text-5xl"
                        dangerouslySetInnerHTML={{ __html: item.header }}
                      ></h2>
                      <p
                        className="text-neutral-500 dark:text-neutral-400 text-lg"
                        dangerouslySetInnerHTML={{ __html: item.text }}
                      ></p>
                    </motion.div>
                    <motion.div className={`w-full md:w-1/2 relative`}>
                      <div className="bg-white dark:bg-slate-500 rounded-md p-8">
                        <img
                          src={imageSrc}
                          alt="Bild"
                          className="rounded-lg w-full aspect-[4/3] object-cover"
                        />
                      </div>
                      <div className="absolute -top-8 -right-8">
                        <img
                          src={`/3dicon/${item.icon}`}
                          className="w-32 h-32"
                        />
                      </div>
                    </motion.div>
                  </motion.div>
                );
              })}
            </motion.div>
          </motion.section>
        )}
        <motion.section
          className="w-full pt-8 px-4 md:py-12 pb-0 bg-gray-50 dark:bg-slate-600 bg-[#E0E9F1] dark:text-white override-font"
          style={{
            "--font-family": fontMapping[font] || fontMapping.modern,
          }}
        >
          <motion.div
            className="text-center"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.3 }}
            variants={containerVariants}
          >
            {landingPageData.activateLocalReviews && (
              <>
                <motion.h2
                  className="font-bold mb-6 md:mb-8 text-4xl"
                  variants={itemVariants}
                >
                  Das sagen unsere Kunden
                </motion.h2>

                {/* Horizontal scrollbarer Container */}
                <motion.div className="p-8" variants={containerVariants}>
                  <div className="pb-5 md:hidden">
                    <Slide
                      speed={2}
                      items={localReviews.map((data, i) => (
                        <div
                          key={i}
                          className="flex flex-col gap-8 rounded-11xl p-7 sm:w-[400px] w-[300px] md:w-full md:mr-0 mr-4 dark:!text-white dark:bg-slate-800 bg-white !text-slate-950"
                        >
                          <div className="flex items-center gap-3 md:gap-6">
                            <img
                              className="object-cover w-16 rounded-full aspect-square md:w-20"
                              src={data?.customerImage || "/images/smiley.png"}
                              alt="Profilbild"
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = "/images/smiley.png";
                              }}
                            />
                            <h1 className="md:text-[23px] text-base font-semibold">
                              {data?.reviewedby}
                            </h1>
                          </div>
                          <p
                            className="text-[#868686] text-sm font-semibold break-words md:text-xl 
          h-[75px] md:h-[105px] overflow-y-scroll touch-pan-y"
                          >
                            {data?.comment}
                          </p>
                        </div>
                      ))}
                    />
                  </div>
                  <div className="hidden md:grid max-w-[1600px] m-auto w-screen !pt-0 sm:w-auto xl:grid-cols-3 sm:grid-cols-2 gap-7 p-7">
                    {localReviews.map((data, i) => (
                      <div key={i} className="pb-5">
                        <div className="flex flex-col gap-8 rounded-11xl p-7 sm:w-[400px] w-[300px] md:w-full md:mr-0 mr-4 bg-white dark:bg-slate-800 !text-slate-950 dark:!text-white">
                          <div className="flex items-center gap-3 md:gap-6">
                            <img
                              className="object-cover w-16 rounded-full aspect-square md:w-20"
                              src={data?.customerImage || "/images/smiley.png"}
                              alt="Profilbild"
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = "/images/smiley.png";
                              }}
                            />
                            <h1 className="md:text-[23px] text-base font-semibold">
                              {data?.reviewedby}
                            </h1>
                          </div>
                          <p
                            className="text-[#868686] text-sm font-semibold break-words md:text-xl 
          h-[75px] md:h-[105px] overflow-y-scroll touch-pan-y"
                          >
                            {data?.comment}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </motion.div>
              </>
            )}
          </motion.div>
        </motion.section>
        {/* Button für Beratungstermin */}
        <div className="flex items-center justify-center w-full p-8 pt-0 dark:bg-slate-600 bg-[#E0E9F1]">
          <motion.button
            className={`text-white  px-8 py-4 md:px-8 md:py-4 md:text-xl shadow-2xl rounded-full hover:opacity-90 transition-opacity text-xl relative`}
            style={{
              background: templateXyzRef?.templateColor,
              boxShadow: `inset 2px 2px 5px rgba(255, 255, 255, 0.4), 
                inset -2px -2px 5px rgba(0, 0, 0, 0.2),
                4px 4px 10px rgba(0, 0, 0, 0.3)`,
            }}
            variants={itemVariants}
            onClick={() =>
              navigate(
                `/questionare/${type}/${landingPageId}/${promoter_id}/${clickid}`,
                { state: { affiliate } }
              )
            }
          >
            {templateXyzRef.buttonThree}
          </motion.button>
        </div>
        <motion.section
          className="w-full py-8 px-8 bg-[#E0E9F1] dark:bg-slate-800 override-font"
          style={{
            "--font-family": fontMapping[font] || fontMapping.modern,
          }}
        >
          <motion.div
            className="flex flex-col items-center justify-center gap-8"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.3 }}
            variants={containerVariants}
          >
            {/* Überschrift */}
            <motion.h2
              className="text-5xl font-bold text-center mb-6 text-black dark:text-white"
              variants={itemVariants}
            >
              Häufig gestellte Fragen
            </motion.h2>

            {/* Read-only Accordion */}
            <Collapse
              accordion
              className="faq-collapse !w-[100%] md:!w-[80%]"
              bordered={false}
              style={{ backgroundColor: !darkMode ? "#E0E9F1" : "#ffffff" }}
            >
              {faqKeys.map((key) => {
                const item = faq[key];
                return (
                  <Panel
                    header={
                      <motion.span
                        className="text-2xl font-bold text-black dark:text-white"
                        dangerouslySetInnerHTML={{ __html: item.question }}
                        variants={itemVariants}
                      ></motion.span>
                    }
                    key={key}
                  >
                    <p
                      className="text-muted-foreground text-black dark:text-white"
                      dangerouslySetInnerHTML={{ __html: item.answer }}
                    ></p>
                  </Panel>
                );
              })}
            </Collapse>
          </motion.div>
        </motion.section>
        <motion.section
          className="w-full space-y-8 bg-white dark:bg-slate-800 dark:text-white override-font"
          style={{
            "--font-family": fontMapping[font] || fontMapping.modern,
          }}
        >
          <PromoSection
            templateData={templateXyzRef}
            avatarUrls={avatarUrls}
            type={type}
            landingPageId={landingPageId}
            affiliate={affiliate}
            clickid={clickid}
            promoter_id={promoter_id}
            containerVariants={containerVariants}
            itemVariants={itemVariants}
          />
        </motion.section>
        <Footer
          landingPageData={landingPageData}
          ladingPageId={landingPageId}
          user={user}
          promoter_id={promoter_id}
          type="xyz"
        />
      </div>
    </div>
  );
};

export default XyzView;
