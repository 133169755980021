import React, { createContext, useContext, useState } from "react";

const LandingPageContext = createContext();

export const LandingPageProvider = ({ children }) => {
  const [landingPageData, setLandingPageData] = useState({});
  const [templateData, setTemplateData] = useState(null);
  const [localReviews, setLocalReviews] = useState([]);
  const [questionData, setQuestionData] = useState([]);

  return (
    <LandingPageContext.Provider
      value={{
        landingPageData,
        setLandingPageData,
        templateData,
        setTemplateData,
        localReviews,
        setLocalReviews,
        questionData,
        setQuestionData,
      }}
    >
      {children}
    </LandingPageContext.Provider>
  );
};

export const useLandingPage = () => useContext(LandingPageContext);
