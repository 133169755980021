import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
} from "react";
import { message } from "antd";
import Upload from "antd/es/upload/Upload";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { Rate, Switch, Button, Divider, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useLandingPage } from "../../../../components/New_LP_Configuration/context/LandingPageContext";
import UploadToCloudService from "../../../../service/UploadToCloudService";
import FontSelector from "../../shared/FontSelector";

const MAX_ICONS = 7;
const MIN_ICONS = 5;

const TemplateXyzConfig = forwardRef(({ templateData }, ref) => {
  const { setTemplateData } = useLandingPage();

  // State für aktive Sektionen (ohne pictureSection)
  const [activeSections, setActiveSections] = useState(() => ({
    bannerSection: templateData?.bannerSection?.activate ?? true,
    cardSection: templateData?.cardSection?.activate ?? true,
    aboutSection: templateData?.aboutSection?.activate ?? true,
  }));

  // Funktion zum Umschalten der Sektionen
  const handleToggleSection = (section) => {
    setActiveSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));

    setTemplateData((prev) => ({
      ...prev,
      [section]: {
        ...prev[section],
        activate: !prev[section].activate,
      },
    }));
  };

  const [icons, setIcons] = useState(
    templateData?.bannerSection?.logos || Array(MAX_ICONS).fill(null)
  );
  const [iconPreviews, setIconPreviews] = useState(Array(MAX_ICONS).fill(null));
  const [iconFiles, setIconFiles] = useState(Array(MAX_ICONS).fill(null));

  useEffect(() => {
    if (templateData?.bannerSection?.logos) {
      setIconPreviews(
        templateData.bannerSection.logos.map((url) => url || null)
      );
    }
  }, [templateData]);

  useImperativeHandle(ref, () => ({
    async save() {
      try {
        // Berechne wie viele gültige Icons (entweder vorhanden oder neue Dateien) existieren
        const validCount = iconFiles.reduce((count, file, index) => {
          return file || icons[index] ? count + 1 : count;
        }, 0);

        if (validCount < MIN_ICONS) {
          const errorMessage = `Bitte lade mindestens ${MIN_ICONS} Banner-Icons hoch (aktuell: ${validCount})`;
          throw new Error(errorMessage);
        }

        // Jetzt nur noch hochladen, was lokal neu ist
        const uploadedIconUrls = await Promise.all(
          iconFiles.map(async (file, index) => {
            if (!file) return icons[index];
            if (file.secure_url) return file.secure_url;
            const res = await UploadToCloudService.upload(
              file.originFileObj || file,
              "image"
            );
            return res.secure_url;
          })
        );

        const validUploadedIcons = uploadedIconUrls.filter(Boolean);

        setIcons(validUploadedIcons);

        setTemplateData((prev) => {
          if (!prev) return prev;
          return {
            ...prev,
            bannerSection: {
              ...prev.bannerSection,
              logos: validUploadedIcons,
            },
          };
        });

        return {
          templateData: {
            darkMode: templateData.darkMode,
            templateColor: templateData.templateColor,
            bannerSection: {
              ...templateData.bannerSection,
              logos: validUploadedIcons,
            },
            aboutSection: {
              ...templateData.aboutSection,
              activate: activeSections.aboutSection,
            },
            cardSection: {
              ...templateData.cardSection,
              activate: activeSections.cardSection,
            },
          },
        };
      } catch (error) {
        console.error("Fehler beim Speichern:", error);
        throw error;
      }
    },
  }));

  const getBoxStyle = (index) => {
    const isRequired = index < MIN_ICONS;
    const hasIcon = iconPreviews[index];

    if (isRequired && !hasIcon) {
      return {
        boxShadow: "0 0 0 2px rgba(255, 0, 0, 0.6)",
        borderRadius: "6px",
      };
    }

    if (isRequired && hasIcon) {
      return {
        boxShadow: "0 0 0 2px rgba(34, 197, 94, 0.6)",
        borderRadius: "6px",
      };
    }

    return {};
  };

  // Handle Icon-Upload – nur lokale Vorschau generieren und Datei speichern
  const handleIconUploadChange = (index, info) => {
    if (info.file.status === "done") {
      // Falls die Upload-Response eine URL liefert, nimm sie als Preview
      const newIconUrl =
        info.file.response?.url ||
        (typeof info.file.response === "string" ? info.file.response : null);

      if (newIconUrl) {
        const newIcons = [...icons];
        newIcons[index] = newIconUrl;
        setIcons(newIcons);

        const newPreviews = [...iconPreviews];
        newPreviews[index] = newIconUrl;
        setIconPreviews(newPreviews);

        const newIconFiles = [...iconFiles];
        newIconFiles[index] = info.file;
        setIconFiles(newIconFiles);

        // Update global context
        setTemplateData((prev) => {
          if (!prev) return prev;
          const updatedLogos = newIcons.filter(Boolean);
          return {
            ...prev,
            bannerSection: {
              ...prev.bannerSection,
              logos: updatedLogos,
            },
          };
        });
      }
    }
  };

  const handleRemoveIcon = (index) => {
    const remainingIcons = iconPreviews.filter(
      (preview, i) => i !== index && preview
    );

    const updatedPreviews = [...iconPreviews];
    const updatedFiles = [...iconFiles];
    const updatedIcons = [...icons];

    updatedPreviews[index] = null;
    updatedFiles[index] = null;
    updatedIcons[index] = null;

    setIconPreviews(updatedPreviews);
    setIconFiles(updatedFiles);
    setIcons(updatedIcons);

    // Update Kontext
    setTemplateData((prev) => {
      if (!prev) return prev;
      const updatedLogos = updatedIcons.filter(Boolean);
      return {
        ...prev,
        bannerSection: {
          ...prev.bannerSection,
          logos: updatedLogos,
        },
      };
    });
  };

  // Upload-Button-Rendering
  const renderUploadButton = () => (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  // Bild-/Icon-Vorschau
  const renderImagePreview = (imageUrl, index) => {
    if (typeof imageUrl !== "string") return renderUploadButton();
    return (
      <div style={{ position: "relative", width: "100%", height: "100%" }}>
        <img
          src={imageUrl}
          alt="Preview"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderRadius: "2px",
          }}
        />
        <DeleteOutlined
          onClick={(e) => {
            e.stopPropagation();
            handleRemoveIcon(index);
          }}
          style={{
            position: "absolute",
            top: 4,
            right: 4,
            fontSize: "16px",
            color: "red",
            backgroundColor: "white",
            borderRadius: "50%",
            padding: "2px",
            cursor: "pointer",
          }}
        />
      </div>
    );
  };

  // Dummy-Request-Funktion, um lokale Uploads zu simulieren
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      const blobUrl = URL.createObjectURL(file);
      onSuccess({ url: blobUrl }, file);
    }, 100);
  };

  return (
    <div className="flex flex-col gap-6 font-semibold text-2xl text-center">
      <div className="w-full">
        <FontSelector />
      </div>
      <Divider style={{ borderColor: "black", borderWidth: "2px" }} />
      <div className="w-full">Aktiviere/Deaktiviere deine Sektionen</div>
      <div className="flex flex-col gap-3 font-semibold text-2xl text-center">
        {/* Banner-Sektion */}
        <div className="flex items-center justify-between text-lg">
          <span>Banner-Sektion</span>
          <Switch
            checked={activeSections.bannerSection}
            onChange={() => handleToggleSection("bannerSection")}
          />
        </div>
      </div>

      {/* Icons Upload */}
      <div className="w-full text-center">
        <div className="inline-flex items-center justify-center gap-2">
          <span>Upload Banner-Icons</span>
          <Tooltip title="Bitte schneide die Bilder vor dem Upload möglichst so zu, dass sie keinen Rand haben. Minimum 5 Maximum 7">
            <InfoCircleOutlined className="text-gray-500 cursor-pointer" />
          </Tooltip>
        </div>
      </div>
      <div className="flex flex-row flex-wrap gap-2">
        {Array.from({ length: MAX_ICONS }).map((_, index) => (
          <Tooltip
            key={`tooltip_icon_${index}`}
            title={
              index < MIN_ICONS
                ? iconPreviews[index]
                  ? "Pflichtfeld belegt"
                  : "Pflichtfeld – Icon fehlt"
                : "Optionales Icon"
            }
          >
            <div style={getBoxStyle(index)}>
              <Upload
                accept="image/*"
                name={`icon_${index}`}
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                customRequest={dummyRequest}
                onChange={(info) => handleIconUploadChange(index, info)}
              >
                {iconPreviews[index]
                  ? renderImagePreview(iconPreviews[index], index)
                  : renderUploadButton()}
              </Upload>
            </div>
          </Tooltip>
        ))}
      </div>
    </div>
  );
});

export default TemplateXyzConfig;
