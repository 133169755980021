import { Button } from "antd";
import { BsArrowLeft } from "react-icons/bs";
import { MdCancel } from "react-icons/md";
import React from "react";

const Header = ({ onArrowClick, onClose, onButtonClick, className }) => {
  return (
    <div className={className}>
      {/** Logo */}
      <div className="flex-1">
        <img src={"/images/logo.png"} alt="" width="50" className="m-auto" />
      </div>
      {/** Topbar */}
      <div className="flex items-center justify-evenly pb-10">
        {/** Arrow */}
        <div className="xl:text-primary text-xl text-[#868686] flex-1">
          <BsArrowLeft onClick={onArrowClick} className="cursor-pointer" />
        </div>
        <div className="xl:text-primary text-xl text-[#868686] flex-1 relative">
          <MdCancel
            onClick={onClose}
            className="cursor-pointer absolute right-0 top-0 -translate-y-[50%]"
          />
        </div>
      </div>

      {/** Textbox  */}
      <div className="text-base font-semibold text-center space-y-4">
        <p className="text-2xl">Seiteneinstellungen</p>
        <p className="text-lg">
          Bestimme hier die Basics - das Meiste kannst du direkt auf der Seite
          anpassen
        </p>
      </div>
    </div>
  );
};

export default Header;
