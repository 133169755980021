import React, { useState, useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal, message, Space, Tooltip } from "antd";
import FloatLabel from "../FloatingLabel";
import { Button as BlueButton, Button } from "../Einstellungen/components";
import { FaWhatsapp } from "react-icons/fa6";
import { IoShareOutline } from "react-icons/io5";
import PublicService from "../../service/PublicService";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/auth/selectors";
import UserProfileSelection from "../PromoterSite/components/UserProfileSelection";
import { Heading, Img } from "../PromoterSite/components";
import FAQModal from "./FAQModal";
import { motion } from "framer-motion";
import { InfoTooltip2 } from "../PromoterSite/components/InfoTooltip2";
import { RxCross2 } from "react-icons/rx";

const Card = ({ handleEmpfehlen }) => {
  return (
    <div className="bg-kacheln-bg p-[10px] rounded-sm border-2 border-blue-main w-full mt-4 mb-4">
      <div className="">
        <h2 className="text-[14px] font-bold text-center mb-2">
          MACH DOCH EIN GESCHENK
        </h2>
        <div className="relative">
          <div className="text-[12px] text-[#3B82F6] rounded-full p-2 text-center font-bold">
            Verschenke mit Deiner Empfehlung einen kostenlosen
            Empfehlungsratgeber & einen garantierten 14 tägigen Testzeitraum
          </div>
        </div>

        {/* Subscription Info */}
        <p className="text-center font-semibold text-[12px]">
          Sobald ein aktives Abo
          <span className="text-[#3B82F6] underline font-bold mx-1 hover:underline">
            ab Tarif Standard
          </span>
          gebucht wird, gilt deine Empfehlung als erfolgreich.
        </p>
      </div>

      {/* Recommend Button */}

      <div className="w-full flex justify-center mt-2 ">
        <button
          onClick={() => handleEmpfehlen()}
          className="w-full text-[24px] smx:text-[18px] font-bold whitespace-nowrap px-4 py-2 bg-[#FFD814] hover:bg-[#f7ca00] !text-[#000000] animate-jump-in   justify-center items-center flex text-center     rounded-[10px] "
        >
          Jetzt empfehlen
        </button>
      </div>
    </div>
  );
};

export default function ReferralSections() {
  const [jetztEmpfehlenModal, setJetztEmpfehlenModal] = useState(null);
  const [nachrichtZumVerschicken, setNachrichtZumVerschicken] = useState(null);
  const [FAQ, setFAQ] = useState(false);
  const { user } = useSelector(selectUser);

  useEffect(() => {
    if (!user) return;
    PublicService.getAdminConfigure().then((response) => {
      setNachrichtZumVerschicken(
        response?.data?.config?.CMS?.affiliateBegleittext
          ?.replace?.(/{Partner Vorname}/g, user?.firstname)
          ?.replace?.(/{Partner Nachname}/g, user?.lastname)
          ?.replace?.(/{PPartner Vorname}/g, user?.firstname)
          ?.replace?.(/{PPartner Nachname}/g, user?.lastname)
          ?.replace?.(
            /{Link}/g,
            process.env.REACT_APP_SHORTENER_APP_LINK +
              `/egt/empfehlen/${user?.shortCode}`
          )
      );
    });
  }, [user]);

  const handleWhatsapp = () => {
    let finalText = nachrichtZumVerschicken;

    window.open(
      `https://wa.me/?text=${encodeURIComponent(finalText)}`,
      "_blank"
    );
  };
  const handleEmpfehlen = useCallback(() => {
    const link =
      process.env.REACT_APP_SHORTENER_APP_LINK +
      `/egt/empfehlen/${user?.shortCode}`;
    let finalText = nachrichtZumVerschicken;

    setJetztEmpfehlenModal({ finalText, link });
  }, [nachrichtZumVerschicken, user]);

  const handleShare = useCallback(() => {
    const link = jetztEmpfehlenModal.link;
    let finalText = nachrichtZumVerschicken;
    finalText += ` ${link}`;

    if (navigator.share) {
      navigator
        .share({
          title: "Empfehlung",
          text: finalText,
          url: link,
        })
        .then(() => console.log("Successful share"))
        .catch((error) => console.log("Error sharing", error));
    } else {
      // Fallback to email
      window.location.href = `mailto:?subject=Empfehlung&body=${encodeURIComponent(
        finalText
      )}`;
    }
  }, [jetztEmpfehlenModal, nachrichtZumVerschicken]);

  return (
    <div className="w-full mx-auto p-2.5 rounded-[14px] bg-[#fafafa] ">
      <div className="grid grid-cols-1 lg:grid-cols-[68%_32%] gap-2.5">
        {/* Left Column */}
        <div className="space-y-2 border border-solid border-blue_gray-100 bg-[#ffffff] rounded-[14px] p-2">
          <h2 className="text-md font-bold text-left ml-2 mt-2 mdx:hidden">
            Für wen eignet sich EinGuterTipp und sollte weiterempfohlen werden?
          </h2>

          {/* Target Groups */}
          <div className="grid grid-cols-4 gap-2 !text-[14px] lgtx:!text-[12px] mdx:hidden">
            <div className="rounded-[24px] p-2 text-black text-center flex flex-col items-center justify-center font-semibold">
              <img
                src="/images/affiliate-icons-1.png"
                style={{ height: 100 }}
              />
              <h3 className="font-bold mb-1">
                Finanz- und Versicherungsbranche
              </h3>
            </div>

            <div className="rounded-[24px] p-2  smx:my-0 text-black text-center flex flex-col items-center justify-center font-semibold">
              <img
                src="/images/affiliate-icons-2.png"
                style={{ height: 100 }}
              />
              <h3 className="font-bold mb-1">Strukturvertriebe / MLM</h3>
            </div>

            <div className="rounded-[24px] p-2 smx:my-0 text-black text-center flex flex-col items-center justify-center font-semibold">
              <img
                src="/images/affiliate-icons-3.png"
                style={{ height: 100 }}
              />
              <h3 className="font-bold mb-1">
                Sonstige Verkäufer / Direktvertriebe
              </h3>
            </div>

            <div className="rounded-[24px] p-2 smx:my-0 text-black text-center flex flex-col items-center justify-center font-semibold">
              <img
                src="/images/affiliate-icons-4.png"
                style={{ height: 100 }}
              />
              <h3 className="font-bold mb-1">Regionale Dienstleister</h3>
            </div>
          </div>
          <div className="h-px bg-blue_gray-100 mdx:hidden" />

          <div className=" mdx:flex  hidden justify-left   xsss:overflow-visible overflow-auto">
            <div className="flex flex-col items-start gap-[9px]">
              <div className="flex justify-start gap-4 items-center">
                <Heading
                  size="headingxs"
                  as="h2"
                  className="!text-[14px] font-semibold text-[#000000]-900 "
                >
                  Wem Du EGT empfehlen solltest
                </Heading>
                <InfoTooltip2 />
              </div>
              <div className="space-y-[14px]">
                {[
                  "“Jetzt empfehlen” klicken",
                  "Empfänger auswählen & senden",
                  "Belohnt werden",
                ].map((step, index) => (
                  <div className="flex items-center gap-2 ">
                    <span className="text-[12px] flex !h-[20px] !w-[20px] items-center justify-center rounded-full bg-blue-main text-white">
                      {index + 1}
                    </span>
                    <Heading
                      as="h2"
                      size="headingxs"
                      className="text-[16px] smx:!text-[14px] font-semibold text-[#000000]-900"
                    >
                      {step}
                    </Heading>
                  </div>
                ))}
              </div>

              <Card handleEmpfehlen={handleEmpfehlen} />
            </div>
          </div>
          <div className="flex mdx:mx-0 mdx:flex-col h-[222px] lgtx:h-[248px] mdx:hidden justify-between">
            <UserProfileSelection
              headingClass="lgtx:!text-[12px]"
              descriptionClass="lgtx:!text-[10px]"
              headingText="01 Bereitschaft"
              descriptionText="Empfehle EinGuterTipp so oft Du magst weiter und liefere damit einen wertvollen Beitrag für Vertriebe in Deutschland, Österreich und der Schweiz"
              userImage="/images/megaphone.png"
              mT="lgtx:mt-1"
            />
            <Img
              src="/images3/img_zeichenfla_che_2_4x.png"
              alt="List Image"
              className="mb-6 mt-[140px] mx-[-30px] h-[40px] w-[8%] object-contain mdx:w-full"
              style={{ zIndex: 999 }}
            />
            <UserProfileSelection
              headingClass="lgtx:!text-[12px]"
              descriptionClass="lgtx:!text-[10px]"
              headingText="02 Klicken"
              descriptionText="Klicke auf den “Jetzt empfehlen” Button, Nachrichtentext prüfen und ggf. anpassen."
              userImage="/images3/excl export.png"
              mT="lgtx:mt-12"
            />
            <Img
              src="/images3/img_zeichenfla_che_2_4x_62x62.png"
              alt="Zeichenflache"
              className="mb-[110px] mx-[-20px] mt-3 h-[62px] w-[6%] object-contain mdx:w-full"
              style={{ zIndex: 999 }}
            />
            <UserProfileSelection
              headingClass="lgtx:!text-[12px]"
              descriptionClass="lgtx:!text-[10px]"
              headingText="03 Versenden"
              descriptionText="Jetzt versenden klicken und dein WhatsApp öffnet sich. Dort einfach die gewünschten Empfehlungen auswählen und versenden."
              userImage="/images3/img_volume.svg"
              className="gap-1"
              mT="mt-2"
            />
            <Img
              src="/images3/img_zeichenfla_che_2_4x_28x66.png"
              alt="Zeichenflache"
              className="mb-[124px] mx-[-25px] mt-[34px] h-[28px] w-[8%] object-contain mdx:w-full"
              style={{ zIndex: 999 }}
            />
            <UserProfileSelection
              headingClass="lgtx:!text-[12px]"
              descriptionClass="lgtx:!text-[10px]"
              headingText="04 Belohnt werden"
              descriptionText="Hilf Kolleginnen und Kollegen erfolgreicher zu werden indem Du einen wertvollen Empfehlungsratgeber verschenkst und gleichzeitig selbst davon profitierst."
              userImage="/images/gift.png"
              imageHeight={"66px"}
              mT="mt-[-20px] lgtx:mt-[-10px]"
              className="gap-1"
            />
          </div>

          <div className="flex justify-between gap-2 mdx:hidden h-full">
            <a className="flex-1">
              <Button
                color="light_blue_A700"
                shape="round"
                size="xxs"
                className="w-full rounded-[5px] font-semibold py-1 mt-5"
                onClick={() => setFAQ(true)}
              >
                <img
                  src="/images/faq.png"
                  style={{ height: 100, marginBottom: 40 }}
                />
              </Button>
            </a>
          </div>
        </div>

        {/* Right Column */}
        <div className="space-y-2 mgx:pr-0 pr-2 flex flex-col justify-between">
          {/* Free Trial Box */}
          <div className="bg-[#ffffff] border border-solid border-blue_gray-100 rounded-[14px] p-2 flex flex-col justify-between h-full">
            <div className="mdx:hidden">
              <h2 className="text-xl font-bold text-center mb-2">
                MACH DOCH EIN GESCHENK
              </h2>
              <div className="relative">
                <div className="text-[14px] text-[#3B82F6] rounded-full p-2 text-center font-bold">
                  Verschenke mit Deiner Empfehlung einen kostenlosen
                  Empfehlungsratgeber & einen garantierten 14 tägigen
                  Testzeitraum
                </div>
                <img
                  className="absolute h-[96px] right-[-52px] bottom-[-22px]"
                  src="/images/EGT Empfehlen.png"
                />
                <img
                  className="absolute h-[96px] left-[-53px] top-[-24px] rotate-180"
                  src="/images/EGT Empfehlen.png"
                />
              </div>

              {/* Subscription Info */}
              <p className="text-center font-semibold mt-2 text-[12px]">
                Sobald ein aktives Abo
                <span className="text-[#3B82F6] underline font-bold mx-1 hover:underline">
                  ab Tarif Standard
                </span>
                gebucht wird, gilt deine Empfehlung als erfolgreich.
              </p>
            </div>

            {/* Recommend Button */}

            <div className="w-full flex justify-center mt-2 mdx:hidden">
              <button
                onClick={() => handleEmpfehlen()}
                className="w-full text-[24px] smx:text-[18px] font-bold whitespace-nowrap px-4 py-2 bg-[#FFD814] hover:bg-[#f7ca00] !text-[#000000] animate-jump-in   justify-center items-center flex text-center     rounded-[10px] "
              >
                Jetzt empfehlen
              </button>
            </div>

            <div className="relative mt-[100px]">
              {/* Decorative "oder" */}
              <span className="absolute right-[calc(50%-150px)] smxx:right-[0px] top-[-130px] smxx:top-[-110px] text-[#3B82F6] italic transform -rotate-12">
                <img src="/images/oder.png" style={{ width: "300px" }} />
              </span>

              <p className="mb-[50px] text-[16px] font-bold text-center">
                Du möchtest ein größeres Team oder eine Organisation empfehlen?
              </p>

              {/* Arrows */}
              <img
                src="/images/downArrow.png"
                alt="Arrow"
                width={30}
                height={30}
                className="smxx:h-[40px] absolute -left-[-20px] top-[60px] smxx:top-[95px] transform -translate-y-1/2 "
              />
              <img
                src="/images/downArrow.png"
                alt="Arrow"
                width={30}
                height={30}
                className="smxx:h-[40px] absolute -right-[-20px] top-[60px] smxx:top-[95px] transform rotate-45 -translate-y-1/2 "
              />

              <div className="relative">
                <Link
                  to="/affiliate-termin"
                  className="w-full flex justify-center mt-2"
                >
                  <Button
                    className="self-stretch rounded-[10px] px-[34px] font-semibold !text-[#ffffff] smx:px-5 mt-1 w-full text-[20px]"
                    style={{
                      backgroundImage: "url(/images3/mesh.png)",
                    }}
                  >
                    Termin buchen
                  </Button>
                </Link>
              </div>

              <div className=" justify-between gap-2 mdx:flex hidden">
                <div className="flex-1 flex-col flex items-center justify-center">
                  <img
                    src="/images/faq.png"
                    style={{ height: 100, marginBottom: -45, marginTop: 10 }}
                  />
                  <Button
                    color="light_blue_A700"
                    shape="round"
                    size="xxs"
                    className="w-full rounded-[5px] font-semibold py-1 mt-5"
                    onClick={() => setFAQ(true)}
                  >
                    Lesen
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        wrapClassName="standardRoot-modal"
        footer={[]}
        onCancel={() => setFAQ(false)}
        open={FAQ}
        title={null}
        centered={true}
        closeIcon={
          <button className="absolute right-[35px] top-[35px] smx:right-[20px] smx:top-[20px] bg-[#eeeeee] rounded-[5px] text-gray-400 hover:text-gray-600 p-1 ring-1 ring-black ring-opacity-5">
            <RxCross2 color="#00def7" size={25} />
          </button>
        }
      >
        <FAQModal />
      </Modal>

      <Modal
        wrapClassName="standardRoot-modal"
        footer={[]}
        onCancel={() => setJetztEmpfehlenModal(null)}
        closeIcon={<></>}
        open={jetztEmpfehlenModal}
        title=""
        centered={true}
      >
        <div className="ml-1 mb-5 flex items-center justify-between gap-5 md:ml-0">
          <div className=" text-[20px] smx:text-[14px] font-semibold text-[#000000] ">
            Empfehlung verschicken
          </div>
          <img
            src="/images/img_cross_small_1.svg"
            alt="Close Icon"
            className="h-[32px] cursor-pointer"
            onClick={() => setJetztEmpfehlenModal(null)}
          />
        </div>

        {/* {JSON.stringify()} */}

        <FloatLabel
          label={"Typ"}
          unFocusClass="sm:!top-[15px] !top-[20px]  !text-[12px] text-ellipsis sm:!text-[16px] !font-semibold  !w-full "
          className={" flex-grow"}
          value={"EinGuterTipp weiterempfehlen"}
          disabled
        >
          <div className="w-full flex justify-between items-center ">
            <input
              type="text"
              className="text-[#535353] text-[16px] border-[1px] border-[#d8d8d8] outline-none rounded-[14px] p-[15px_12.5px] w-full !appearance-none"
              value={"EinGuterTipp weiterempfehlen"}
              disabled
            />
            <img
              src={"/images/logo.png"}
              alt="Arrow Up"
              className=" h-[24px] w-[20px] object-contain absolute right-3"
            />
          </div>
        </FloatLabel>

        <br />

        <FloatLabel
          label={"Dein Empfehlungslink"}
          unFocusClass="sm:!top-[15px] !top-[20px]  !text-[12px] text-ellipsis sm:!text-[16px] !font-semibold  !w-full "
          className={" flex-grow"}
          value={
            process.env.REACT_APP_SHORTENER_APP_LINK +
            `/egt/empfehlen/${user?.shortCode}`
          }
          disabled
        >
          <div className="w-full flex justify-between items-center ">
            <input
              type="text"
              className="text-[#535353] text-[16px] border-[1px] border-[#d8d8d8] pr-10 outline-none rounded-[14px] p-[15px_12.5px] w-full !appearance-none"
              value={
                process.env.REACT_APP_SHORTENER_APP_LINK +
                `/egt/empfehlen/${user?.shortCode}`
              }
              disabled
            />

            <img
              src="/images/clone 1.svg"
              alt="Arrow Up"
              className="h-[24px] w-[20px] object-contain absolute right-3 cursor-pointer"
              onClick={() => {
                navigator.clipboard.writeText(jetztEmpfehlenModal?.link);
                message.info("Kopiert");
              }}
            />
          </div>
        </FloatLabel>

        <br />
        <br />

        <FloatLabel
          label={"Deine Nachricht"}
          unFocusClass="sm:!top-[15px] !top-[20px]   text-ellipsis text-[16px] !font-semibold  !w-full "
          className={" flex-grow"}
          value={nachrichtZumVerschicken}
          onChange={(e) => setNachrichtZumVerschicken(e.target.value)}
        >
          <div className="w-full flex justify-between items-center ">
            <textarea
              rows={4}
              className="border-[1px] border-[#d8d8d8] outline-none rounded-[14px] p-[15px_12.5px] w-full !appearance-none !text-[16px]"
              value={nachrichtZumVerschicken}
              onChange={(e) => setNachrichtZumVerschicken(e.target.value)}
              onKeyDown={(e) => {
                if (e.code === "Enter" && e.ctrlKey) {
                  e.preventDefault();
                  handleWhatsapp();
                }
              }}
            />
          </div>
        </FloatLabel>

        <BlueButton
          shape="round"
          className="w-full ml-1 mt-[75px] rounded-[10px] px-[34px] md:ml-0 sm:px-5 smx:text-[14px]"
          onClick={handleWhatsapp}
        >
          <Space>
            <FaWhatsapp size={20} />
            Jetzt per WhatsApp versenden
          </Space>
        </BlueButton>

        <BlueButton
          shape="round"
          className="w-full ml-1 mt-[15px] rounded-[10px] px-[34px] md:ml-0 sm:px-5 smx:text-[14px]"
          onClick={handleShare}
        >
          <Space>
            <IoShareOutline size={20} />
            Jetzt anderweitig teilen
          </Space>
        </BlueButton>
      </Modal>
    </div>
  );
}
