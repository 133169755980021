import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useMessageContext } from "../../context/messageContext";
import ResourceToggle from "./components/Shared/RessourceToggle";
import ViewToggle from "./components/Shared/ViewToggle";
import ColorPickerToggle from "./components/Shared/ColorPicker";
import ThemeToggle from "./components/Shared/ThemeToggle";
import QuestionToggle, {
  useIsQuestionMode,
} from "./components/Shared/QuestionToggle";
import ResourceSidebar from "./components/Shared/RessourceMenu";
import { useIsMobileView } from "./components/Shared/ViewToggle";
import LandingPageService from "../../service/LandingPageService";
import localReviewService from "../../service/LocalReviewService";
import QuestionService from "../../service/QuestionService";
import TemplateXYZ from "./components/templates/layouts/TemplateXYZ";
import QuestionSettings from "./components/Shared/QuestionSettings";
import {
  LandingPageProvider,
  useLandingPage,
} from "./context/LandingPageContext";

const LPConfiguratorContent = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { id: landingpageId } = useParams();
  const { showMessage } = useMessageContext();
  const [isLoading, setIsLoading] = useState(true);
  const [globalSettings, setGlobalSettings] = useState({
    primaryColor: "#EF4444",
    font: "Roboto",
  });

  const isMobile = useIsMobileView();
  const isQuestionMode = useIsQuestionMode(); // Frage-Modus Hook
  const {
    landingPageData,
    setLandingPageData,
    setTemplateData,
    setQuestionData, // Jetzt aus dem Context
  } = useLandingPage();
  const [localReviewsData, setLocalReviewsData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          landingPageResponse,
          localReviewsResponse,
          questionDataResponse,
        ] = await Promise.all([
          LandingPageService.detail(landingpageId),
          localReviewService.list(landingpageId),
          QuestionService.listQuestions(landingpageId),
        ]);

        setLandingPageData(landingPageResponse.data);
        setLocalReviewsData(localReviewsResponse.data);
        setTemplateData(landingPageResponse.data.templateXyz);

        // Fragen in den Context setzen
        setQuestionData(questionDataResponse.data);
      } catch (error) {
        showMessage(
          "error",
          "Ups, da ist etwas schief gegangen. Versuche es später noch mal"
        );
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="flex flex-col items-center dark:bg-gray-900 dark:text-white">
      <ResourceToggle onClick={() => setIsSidebarOpen(true)} />
      <ThemeToggle />
      <ViewToggle />
      <ColorPickerToggle
        onChange={(color) =>
          setGlobalSettings({ ...globalSettings, primaryColor: color })
        }
      />
      <QuestionToggle />
      <div
        className={`flex w-full flex-col ${
          isMobile ? "h-[100vh] overflow-y-scroll overflow-x-hidden" : ""
        }`}
      >
        <ResourceSidebar
          open={isSidebarOpen}
          onClose={() => setIsSidebarOpen(false)}
          globalSettings={globalSettings}
          localReviewsData={localReviewsData}
          loading={isLoading}
        />
        {isQuestionMode ? <QuestionSettings /> : <TemplateXYZ />}
      </div>
    </div>
  );
};

const LPConfigurator = () => {
  return (
    <LandingPageProvider>
      <LPConfiguratorContent />
    </LandingPageProvider>
  );
};

export default LPConfigurator;
