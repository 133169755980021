import { Button, Popconfirm, Segmented, Skeleton, Table, Tooltip } from "antd";
import Column from "antd/es/table/Column";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { BsArrowLeftCircle } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import AdminService from "../../../service/AdminService";
import NotificationService from "../../../service/NotificationService";
import { exportToExcel } from "../../../utils/ExportAsExcel";

import { Drag } from "../../Dashboard/Pramien/Svg";
import { ICONSELECTOR } from "../../Notification";
import { dateOption } from "../supports";
import { Heart, Mouse, New, People } from "./Svgs";

// const ICONSELECTOR = ({ type }) => {
//   return (
//     {
//       new_registration: GreenHeart(),
//       emailZustimmungFallig: Head(),
//       linkNichtGeklickt: Folder(),
//       empfehlungOhneStatusAnderung: StopWatch(),
//       custom: PeopleBlue(),
//       canceled_subscription: RejectedIcon(),
//       new_support_ticket: GreenTicketFill(),
//       warning: Warning(),
//     }[type] ?? (
//       <img
//         src="/images/morflaxstudio81@2x.png"
//         className="scale-125"
//         width={26}
//         height={26}
//         alt=""
//       />
//     )
//   );
// };

//7days lastmonth 3months lastyear alltime
const Index = () => {
  const [cadooz, setCadooz] = useState([]);
  const [loading, setLoading] = useState(true);
  const [notification, setNotifications] = useState([]);
  const navigate = useNavigate();
  const [supportTickets, setSupportTickets] = useState([]);
  const [affiliateClaims, setAffiliateClaims] = useState([]);
  const [notificationsCancellations, setNotificationsCancellations] = useState({
    messages: [],
  });

  const [resultFilter, setResultFilter] = useState("alltime");
  const [stats, setStats] = useState({
    neueEmpfehlungen: 0,
    offeneVorgange: 0,
    empfehlungen: 0,
    aufrufe: 0,
    aufrufeProPromoter: 0,
    promoter: 0,
    eingelostePramien: 0,
    promoterOhneAufrufe: 0,
    promoterOhneEinwilligung: 0,
    kundenOhnePromoter: 0,
    allPartners: 0,
    newPartners: 0,
    partnerDemo: 0,
    partnerTest: 0,
  });
  const cadoozExport = async (calendarYear, calendarWeek) => {
    const { data } = await AdminService.exportCadooz({
      calendarWeek,
      calendarYear,
    });
    console.log(data);
    exportToExcel(
      [...data].map((e) => ({
        Anrede: e?.promoter?.anrede || "",
        Vorname: e?.promoter?.firstname || "",
        Nachname: e?.promoter?.lastname || "",
        Firma: "",
        Abteilung: "",
        Strasse: e?.user?.street || "",
        Adresszusatz: e?.user?.street || "",
        PLZ: e?.user?.postCode || "",
        Ort: e?.user?.city || "",
        Land: "",
        Anzahl: 1,
        Wert: e?.valueInEUR,
        Währung: "EUR",
        "externe Produktbezeichnung": "BestChoice Premium",
        Versand: "eCard",
        Email: e?.promoter?.email || "",
        "Externe Belegnummer": "",
        Debitor: `${e?.user?.firstname} ${e?.user?.lastname}`,
      })),
      "CadoozExport" + calendarYear + "W" + calendarWeek
    );
  };
  useEffect(() => {
    try {
      const getStats = async () => {
        const { data: cadoozAvailableExports } =
          await AdminService.getAvailableCadoozExports();

        const { data: dashboardStats } = await AdminService.getDashboardStats(
          resultFilter
        );
        setStats(dashboardStats);
        setCadooz(cadoozAvailableExports);
      };
      getStats();
    } catch (error) {
      console.log(error);
    }
  }, [resultFilter]);

  const getNotification = async () => {
    setLoading(true);
    const { data: notification } = await NotificationService.getInboundMessages(
      { pages: 0, limit: 10 }
    );
    setNotifications(notification);
    setLoading(false);
  };
  const getNotificationCancellations = async () => {
    const { data: notification } = await NotificationService.getInboundMessages(
      { pages: 0, limit: 10, type: "canceled_subscription" }
    );
    if (notification) setNotificationsCancellations(notification);
  };

  const getSupportTickets = async () => {
    const { data } = await AdminService.getSupportTickets(false);

    if (data)
      setSupportTickets(
        data.map((ticket) => ({
          ...ticket,
          id: ticket._id,
          name: `${ticket?.user_id?.firstname ?? ""} ${
            ticket?.user_id?.lastname ?? ""
          }`,
          time: moment(data?.createdAt).format("D.M.YYYY"),
          theme: ticket?.theme ?? "",
        }))
      );
  };

  const getAffiliateClaims = async () => {
    const { data } = await AdminService.listAffiliateClaims(false);

    if (data) setAffiliateClaims(data);
  };

  useEffect(() => {
    getNotification();
    getNotificationCancellations();
    getSupportTickets();
    getAffiliateClaims();
  }, []);

  const getNotificationLink = (e) => {
    const partner_id = e?.targetId ?? null;

    if (partner_id) return `/admindashboard/partner?partner=${partner_id}`;
    return null;
  };

  return (
    <div>
      <div className="overflow-auto">
        <Segmented
          className="mx-0 xs:mx-5 my-6  font-medium text-[14px] rounded-[10px]"
          value={resultFilter}
          onChange={(e) => setResultFilter(e)}
          options={[
            {
              label: (
                <div className=" px-[10px] rounded-me rounded-[6px] py-[7px]">
                  Alle
                </div>
              ),
              value: "alltime",
            },
            {
              label: (
                <div className="px-[10px] rounded-me rounded-[6px] py-[7px]">
                  7 Tage
                </div>
              ),
              value: "7days",
            },
            {
              label: (
                <div className="px-[10px] rounded-me rounded-[6px] py-[7px]">
                  Letzter Monat
                </div>
              ),
              value: "lastmonth",
            },
            {
              label: (
                <div className="px-[10px] rounded-me rounded-[6px] py-[7px]">
                  3 Monate
                </div>
              ),
              value: "3months",
            },
            {
              label: (
                <div className="px-[10px] rounded-me rounded-[6px] py-[7px]">
                  Letztes Jahr
                </div>
              ),
              value: "lastyear",
            },
          ]}
        />
      </div>
      <div className="flex flex-col gap-[35px]">
        <div
          className="flex secondary:h-[400px] h-full secondary:flex-nowrap flex-wrap justify-between gap-10 "
          style={{ width: "auto" }}
        >
          <div className="secondary:w-[calc(100%-500px)] min-h-[400px] w-full sm:p-5 p-2 bg-[#fafafa] rounded-[30px] ">
            <h1 className="font-semibold sm:text-9xl text-xl">
              Deine Zahlen im Überblick.
            </h1>
            <div className="overflow-auto flex h-[80%]  items-center   sm:gap-4 gap-1 ">
              <div className="text-center grid grid-rows-3 flex-1  justify-center items-center sm:max-w-[165px] sm:min-w-[165px] max-w-[110px] min-w-[110px] h-[195px] cursor-pointer p-5 gap-3  rounded-[15px] border border-[#d8d8d8]">
                <span className="green-mark-upper text-xl w-fit m-auto font-semibold ">
                  {stats?.allPartners}
                </span>
                <p className="font-[500] text-[14px] text-[#868686]">
                  Alle <br /> Partner
                </p>
                <p className="flex justify-center items-center">
                  <New />
                </p>
              </div>

              <div className="text-center grid grid-rows-3 flex-1  justify-center items-center sm:max-w-[165px] sm:min-w-[165px] max-w-[110px] min-w-[110px] h-[195px] cursor-pointer p-5 gap-3  rounded-[15px] border border-[#d8d8d8]">
                <span className="red-mark-upper text-xl justify-center  font-semibold flex items-center">
                  {stats?.partnerDemo}
                </span>
                <p className="font-[500] text-[14px] text-[#868686]">
                  Partner in <br /> Startphase
                </p>
                <p className="flex justify-center items-center">
                  <People />
                </p>
              </div>
              <div className="text-center grid grid-rows-3 flex-1  justify-center items-center sm:max-w-[165px] sm:min-w-[165px] max-w-[110px] min-w-[110px] h-[195px] cursor-pointer p-5 gap-3  rounded-[15px] border border-[#d8d8d8]">
                <span className="green-mark-upper text-xl justify-center  font-semibold flex items-center">
                  {stats?.testPhase}
                </span>
                <p className="font-[500] text-[14px] text-[#868686]">
                  Partner in <br /> Testphase
                </p>
                <p className="flex justify-center items-center">
                  <Heart />
                </p>
              </div>
              <div className="text-center grid grid-rows-3 flex-1  justify-center items-center sm:max-w-[165px] sm:min-w-[165px] max-w-[110px] min-w-[110px] h-[195px] cursor-pointer p-5 gap-3  rounded-[15px] border border-[#d8d8d8]">
                <span className="green-mark-upper text-xl w-fit m-auto font-semibold ">
                  {stats?.promoter}
                </span>
                <p className="font-[500] text-[14px] text-[#868686]">
                  Promoter
                </p>
                <p className="flex justify-center items-center">
                  <New />
                </p>
              </div>
              <div className="text-center grid grid-rows-3 flex-1  justify-center items-center sm:max-w-[165px] sm:min-w-[165px] max-w-[110px] min-w-[110px] h-[195px] cursor-pointer p-5 gap-3  rounded-[15px] border border-[#d8d8d8]">
                <span className="green-mark-upper text-xl w-fit m-auto font-semibold ">
                  {stats?.promoterOhneEinwilligung}
                </span>
                <p className="font-[500] text-[14px] text-[#868686]">
                  Eingelöste Prämien
                </p>
                <p className="flex justify-center items-center">
                  <New />
                </p>
              </div>
            </div>
          </div>
          <div
            className="secondary:w-[400px] h-[400px] w-full overflow-auto  rounded-[30px] p-[10px] bg-[#fafafa] flex flex-col items-center  gap-2"
            style={{ width: "auto", flexGrow: "100" }}
          >
            {loading ? (
              <Skeleton active />
            ) : notification?.messages?.length > 0 ? (
              notification?.messages
                .filter((e) => e.type !== "pramien")
                .map((e, i) => (
                  <Link
                    to={getNotificationLink(e)}
                    className="w-full flex bg-white rounded-[20px] items-center p-1 pl-3"
                    key={i}
                  >
                    <div>
                      <ICONSELECTOR type={e.type} />
                    </div>
                    <div className="p-[10px]">
                      <h1 className="text-[12px] text-[#000000]">{e.text}</h1>
                      <p className="text-[#868686]">
                        {moment(e.createdAt).fromNow()}
                      </p>
                    </div>
                  </Link>
                ))
            ) : (
              <div className="text-[#000000] text-xl">
                Leg los um Benachrichtigen zu erhalten.
              </div>
            )}
          </div>
        </div>
        <div
          className="flex secondary:h-[400px] h-full secondary:flex-nowrap flex-wrap justify-between gap-10 "
          style={{ width: "auto" }}
        >
          <div className="secondary:w-[calc(100%-500px)] w-full p-5 bg-[#fafafa] rounded-[30px] ">
            <div className="rounded-11xl bg-[#fafafa] p-5">
              <h1 className="text-9xl font-semibold my-2">
                Offene Supportanfragen
              </h1>
              <div
                className=" rounded-[20px] w-[99%] bg-white border-[#D8D8D8] border-[1px]  !overflow-auto"
                id="drag-div-1"
              >
                <Table
                  className=" bg-transparent relative overflow-auto  max-h-[220px]"
                  bordered={false}
                  dataSource={supportTickets}
                  prefixCls="admindashboardhometickettable"
                  pagination={false}
                  rowKey={"uid"}
                >
                  <Column
                    title="Partner"
                    dataIndex={"name"}
                    className="font-bold text-[14px]"
                  ></Column>
                  <Column
                    title="Thema"
                    dataIndex={"theme"}
                    className="font-bold text-[14px]"
                  ></Column>
                  <Column
                    title="Erstellt am"
                    className="font-bold text-[14px]"
                    dataIndex={"createdAt"}
                    render={(_) => (
                      <div className="whitespace-nowrap overflow-hidden text-ellipsis">
                        <Tooltip title={moment(_).format("D.M.YYYY HH:mm")}>
                          {moment(_).format("D.M.YYYY HH:mm")}
                        </Tooltip>
                      </div>
                    )}
                  ></Column>
                  <Column
                    title="Detail"
                    dataIndex={"views"}
                    className="font-bold text-[14px]"
                    render={() => {
                      return (
                        <Button
                          className="sm:w-auto w-full flex-grow"
                          type="primary"
                          onClick={() =>
                            navigate("/admindashboard/supporttickets")
                          }
                        >
                          Detail
                        </Button>
                      );
                    }}
                  ></Column>
                </Table>
              </div>
              <div className="my-2  flex items-center gap-3 text-[#868686]">
                <Drag />
                <p>Nach oben/unten ziehen um weitere Einträge anzuzeigen</p>
              </div>
            </div>
          </div>
          <div
            className="secondary:w-[400px] w-full   rounded-[30px] p-[10px] bg-[#fafafa] flex flex-col items-center  gap-2"
            style={{ width: "auto", flexGrow: "100" }}
          >
            <h1 className="text-9xl font-semibold">Partner in Kündigung</h1>
            <div className="overflow-auto w-full   rounded-[30px] gap-2 flex flex-col items-center  ">
              {notificationsCancellations?.messages?.map?.((e, i) => (
                <Link
                  to={getNotificationLink(e)}
                  className="w-full flex bg-white rounded-[20px] items-center p-1 pl-3"
                  key={i}
                >
                  <div>
                    <ICONSELECTOR type={e.type} />
                  </div>
                  <div className="p-[10px]">
                    <h1 className="text-[12px] text-[#000000]">{e.text}</h1>
                    <p className="text-[#868686]">
                      {moment(e.createdAt).fromNow()}
                    </p>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
        <div
          className="flex secondary:h-[400px] h-full secondary:flex-nowrap flex-wrap justify-between gap-10 "
          style={{ width: "auto" }}
        >
          <div className="rounded-11xl bg-[#fafafa] p-5 max-w-[490px]">
            <h1 className="text-9xl font-semibold my-2">Cadooz-Exports</h1>
            <div
              className=" rounded-[20px] w-[99%] bg-white border-[#D8D8D8] border-[1px]  !overflow-auto"
              id="drag-div-1"
            >
              <Table
                className=" bg-transparent relative overflow-auto  max-h-[220px]"
                bordered={false}
                dataSource={cadooz}
                pagination={false}
                tableLayout="auto"
                rowKey={"uid"}
              >
                <Column
                  title="Kalenderwoche"
                  render={(_, __) => {
                    const paddedWeek =
                      __.calendarWeek < 10
                        ? `0${__.calendarWeek}`
                        : __.calendarWeek;
                    const paddedWeek2 =
                      __.calendarWeek + 1 < 10
                        ? `0${__.calendarWeek + 1}`
                        : __.calendarWeek + 1;
                    const startDate = moment(
                      __.calendarYear + "W" + paddedWeek
                    ).format("DD.MM");
                    const endDate = moment(
                      __.calendarYear + "W" + paddedWeek2
                    ).format("DD.MM");

                    return `${__.calendarWeek} (${startDate}-${endDate})`;
                  }}
                  className="font-bold text-[14px]"
                ></Column>
                <Column
                  title="Export"
                  dataIndex={"views"}
                  className="font-bold text-[14px]"
                  render={(_, __) => {
                    return (
                      <Button
                        type="primary"
                        onClick={() =>
                          cadoozExport(__.calendarYear, __.calendarWeek)
                        }
                      >
                        Herunterladen
                      </Button>
                    );
                  }}
                ></Column>
              </Table>
            </div>
            <div className="my-2  flex items-center gap-3 text-[#868686]">
              <Drag />
              <p>Nach oben/unten ziehen um weitere Einträge anzuzeigen</p>
            </div>
          </div>
          <div className="secondary:w-[calc(100%-500px)] w-full p-5 bg-[#fafafa] rounded-[30px] ">
            <div className="rounded-11xl bg-[#fafafa] p-5">
              <h1 className="text-9xl font-semibold my-2">Affilate-Prämien</h1>
              <div
                className=" rounded-[20px] w-[99%] bg-white border-[#D8D8D8] border-[1px]  !overflow-auto"
                id="drag-div-1"
              >
                <Table
                  className=" bg-transparent relative overflow-auto  max-h-[220px]"
                  bordered={false}
                  dataSource={affiliateClaims}
                  prefixCls="admindashboardhometickettable"
                  pagination={false}
                  rowKey={"uid"}
                >
                  <Column
                    title="Datum"
                    dataIndex={"createdAt"}
                    className="font-bold text-[14px]"
                    render={(_) => (
                      <div className="whitespace-nowrap overflow-hidden text-ellipsis">
                        <Tooltip title={moment(_).format("D.M.YYYY HH:mm")}>
                          {moment(_).format("D.M.YYYY HH:mm")}
                        </Tooltip>
                      </div>
                    )}
                  ></Column>
                  <Column
                    title="Partner"
                    dataIndex={"partner"}
                    className="font-bold text-[14px]"
                    render={(_, record) => (
                      <div className="whitespace-nowrap overflow-hidden text-ellipsis">
                        {record?.user_id?.firstname} {record?.user_id?.lastname}
                      </div>
                    )}
                  ></Column>
                  <Column
                    title="Anzahl Empfehlungen"
                    dataIndex={"anzahl"}
                    className="font-bold text-[14px]"
                    render={(_, record) => (
                      <div className="whitespace-nowrap overflow-hidden text-ellipsis">
                        {record?.bonus_id?.anzahlEmpfehlungen}
                      </div>
                    )}
                  ></Column>
                  <Column
                    title="Geschenk"
                    dataIndex={"geschenk"}
                    className="font-bold text-[14px]"
                    render={(_, record) => (
                      <div className="whitespace-nowrap overflow-hidden text-ellipsis">
                        {record?.bonus_id?.name}
                      </div>
                    )}
                  ></Column>
                  <Column
                    title="Status"
                    dataIndex={"status"}
                    className="font-bold text-[14px]"
                    render={(_, record) => (
                      <div
                        className={`whitespace-nowrap overflow-hidden text-ellipsis text-${
                          record?.accepted
                            ? "green-500"
                            : record?.rejected
                            ? "red-500"
                            : "blue-500"
                        }`}
                      >
                        <div className="flex gap-1">
                          <div>
                            {record?.accepted
                              ? "Eingelöst"
                              : record?.rejected
                              ? "Abgelehnt"
                              : "Offen"}
                          </div>

                          {!record?.accepted && !record?.rejected && (
                            <>
                              <Popconfirm
                                title="Sicher?"
                                onConfirm={async () => {
                                  await AdminService.answerRequestBonus(
                                    record._id,
                                    { accepted: true }
                                  );
                                  await getAffiliateClaims();
                                }}
                              >
                                <Button className="ml-4" size="small">
                                  Genehmigen
                                </Button>
                              </Popconfirm>
                              <Popconfirm
                                title="Sicher?"
                                onConfirm={async () => {
                                  await AdminService.answerRequestBonus(
                                    record._id,
                                    { rejected: true }
                                  );
                                  await getAffiliateClaims();
                                }}
                              >
                                <Button size="small" danger>
                                  Ablehnen
                                </Button>
                              </Popconfirm>
                            </>
                          )}
                        </div>
                      </div>
                    )}
                  ></Column>
                </Table>
              </div>
              <div className="my-2  flex items-center gap-3 text-[#868686]">
                <Drag />
                <p>Nach oben/unten ziehen um weitere Einträge anzuzeigen</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
