import React from "react";
import { motion } from "framer-motion";
import { Collapse, Button } from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import EditableText from "../../shared/EditableField";
import { useLandingPage } from "../../../../context/LandingPageContext";
import { fontMapping } from "../../shared/fontMapping";
import "./XYZ.css";

const { Panel } = Collapse;

const DEFAULT_FAQ = { question: "Neue Frage", answer: "Neue Antwort" };
const faqOrder = [
  "one",
  "two",
  "three",
  "four",
  "five",
  "six",
  "seven",
  "eight",
  "nine",
  "ten",
];

const XYZFAQSection = () => {
  const { templateData, setTemplateData } = useLandingPage();

  // Sicherstellen, dass templateData.faq initial existiert (z.B. mit 3 vorgegebenen FAQs)
  const faqData = templateData.faq || {
    one: {
      question: "Was ist in meinem Abonnement enthalten?",
      answer:
        "Unser Standardabonnement umfasst Zugang zu allen Kernfunktionen der Plattform, unbegrenzte Projekte und E-Mail-Support.",
    },
    two: {
      question: "Wie kann ich mein Abonnement kündigen?",
      answer:
        "Sie können Ihr Abonnement jederzeit in Ihren Kontoeinstellungen kündigen. Ihr Zugang bleibt bis zum Ende des Abrechnungszeitraums bestehen.",
    },
    three: {
      question: "Gibt es eine kostenlose Testversion?",
      answer:
        "Ja, wir bieten eine 14-tägige kostenlose Testversion an – keine Kreditkarte erforderlich.",
    },
  };

  // Nur die aktiven FAQ-Schlüssel in der gewünschten Reihenfolge anzeigen
  const activeFaqKeys = faqOrder.filter(
    (key) => faqData[key] && (faqData[key].question || faqData[key].answer)
  );

  const handleAddFaq = () => {
    const nextKey = faqOrder.find(
      (key) => !faqData[key] || (!faqData[key].question && !faqData[key].answer)
    );
    if (nextKey) {
      setTemplateData((prev) => ({
        ...prev,
        faq: {
          ...faqData,
          [nextKey]: { ...DEFAULT_FAQ },
        },
      }));
    }
  };

  const handleRemoveFaq = (key) => {
    // Nur FAQ 4 und 5 (bzw. weitere dynamische FAQs) können entfernt werden
    if (!["one", "two", "three"].includes(key)) {
      setTemplateData((prev) => {
        const updatedFaq = { ...faqData };
        delete updatedFaq[key];
        return { ...prev, faq: updatedFaq };
      });
    }
  };

  const updateFaqField = (key, field, value) => {
    setTemplateData((prev) => ({
      ...prev,
      faq: {
        ...faqData,
        [key]: {
          ...faqData[key],
          [field]: value,
        },
      },
    }));
  };

  return (
    <motion.section
      className="w-full py-8 px-8 !bg-[#E0E9F1] dark:!bg-slate-800 override-font flex flex-col items-center justify-center"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8 }}
      style={{
        "--font-family": fontMapping[templateData.font] || fontMapping.modern,
      }}
    >
      {/* Überschrift */}
      <EditableText
        text={templateData.faq?.header || "Häufig gestellte Fragen"}
        onSave={(newValue) =>
          setTemplateData((prev) => ({
            ...prev,
            faq: {
              ...faqData,
              header: newValue,
            },
          }))
        }
        element="h2"
        className="text-3xl font-bold text-center mb-6 text-black dark:text-white"
        maxLines={10}
        maxChars={1000}
      />
      {/* Accordion */}
      <Collapse
        accordion
        className="faq-collapse !w-[90%] md:!w-[70%]"
        bordered={false}
      >
        {activeFaqKeys.map((key) => {
          const item = faqData[key];
          if (!item) return null;

          return (
            <Panel
              style={{
                backgroundColor: !templateData.darkMode ? "#E0E9F1" : "#ffffff",
              }}
              header={
                <div className="flex items-center justify-between w-full">
                  <EditableText
                    text={item.question}
                    onSave={(newValue) =>
                      updateFaqField(key, "question", newValue)
                    }
                    element="span"
                    className="text-xl font-bold text-black dark:text-white"
                    maxLines={3}
                    maxChars={400}
                  />
                  {/* Lösch-Button nur bei dynamisch hinzugefügten FAQs */}
                  {!["one", "two", "three"].includes(key) && (
                    <Button
                      type="text"
                      icon={<MinusOutlined style={{ color: "red" }} />}
                      onClick={(e) => {
                        e.stopPropagation(); // Verhindert das Auf-/Zuklappen beim Klicken
                        handleRemoveFaq(key);
                      }}
                    />
                  )}
                </div>
              }
              key={key}
            >
              <EditableText
                text={item.answer}
                onSave={(newValue) => updateFaqField(key, "answer", newValue)}
                element="p"
                className="text-muted-foreground text-black dark:text-white"
                maxLines={10}
                maxChars={1000}
              />
            </Panel>
          );
        })}
      </Collapse>

      {/* Hinzufügen-Button anzeigen, wenn weniger als 5 FAQs aktiv sind */}
      {activeFaqKeys.length < 10 && (
        <div className="mt-4 flex justify-center">
          <Button type="primary" icon={<PlusOutlined />} onClick={handleAddFaq}>
            FAQ hinzufügen
          </Button>
        </div>
      )}
    </motion.section>
  );
};

export default XYZFAQSection;
