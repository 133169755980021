import { useRef, useEffect, useState } from "react";
import { Drawer } from "antd";
import LandingPageConfig from "../../../../pages/LandingPageConfigNew";
import IconButton from "../../../_NEW/LandingPageConfig/shared/IconButton";

const ResourceSidebar = ({
  open,
  onClose,
  side = "left",
  localReviewsData,
  questionData,
  loading,
}) => {
  const SIDEBAR_WIDTH_MOBILE = 400;
  const landingPageRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (landingPageRef.current) {
      setIsLoading(false);
      console.log("✅ landingPageRef wurde gesetzt:", landingPageRef.current);
    }
  }, [landingPageRef.current]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (landingPageRef.current) {
        setIsSaving(landingPageRef.current.isSaving);
      }
    }, 500); // Alle 500ms prüfen, ob sich der Status geändert hat

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div className="hidden">
        <LandingPageConfig
          ref={landingPageRef}
          open={open}
          onClose={onClose}
          localReviewsData={localReviewsData}
          questionData={questionData}
          isLoading={loading || isLoading}
        />
      </div>

      <Drawer
        open={open}
        onClose={onClose}
        placement={side}
        width={SIDEBAR_WIDTH_MOBILE}
        closable={false}
        className="custom-sidebar"
      >
        <div className="h-full w-full bg-white py-6 overflow-y-scroll overflow-x-hidden">
          <LandingPageConfig
            ref={landingPageRef}
            open={open}
            onClose={onClose}
            localReviewsData={localReviewsData}
            questionData={questionData}
            isLoading={loading || isLoading}
          />
        </div>
      </Drawer>

      <IconButton
        onClick={() => {
          console.log("🔍 landingPageRef:", landingPageRef.current);
          if (landingPageRef.current) {
            landingPageRef.current.saveAll();
          } else {
            console.warn("❌ landingPageRef ist noch nicht gesetzt!");
          }
        }}
        className="fixed primary:block bottom-4 right-2 tertiary:right-10 !z-20"
        loading={isSaving}
        disabled={isSaving}
      />
    </>
  );
};

export default ResourceSidebar;
