import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useRef,
  useEffect,
} from "react";
import { Button, Tooltip } from "antd";
import { useScreenWidth } from "../../../components/LandingPageConfig/useScreenWidth";
import CollapseItem from "../shared/CollapseItem";
import HTMLTextEditor from "../../../components/Einstellungen/HtmlEditor";
import ClipboardButtonsBar from "../../../components/Dashboard/kampaign/ClipboardButtonsBar";
import UserService from "../../../service/UserService";
import { AiOutlineInfoCircle } from "react-icons/ai";

const BegleitTextConfig = forwardRef(
  ({ landingPageData: { begleitTextTemplate } }, ref) => {
    const [versandTemplate, setVersandTemplate] = useState(
      begleitTextTemplate || ""
    );
    const quillRef = useRef();
    const { width } = useScreenWidth();

    const isEmptyHtml = (html) => {
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = html;
      return tempDiv.innerText.trim() === "";
    };

    useEffect(() => {
      const synchBegleitText = async () => {
        const { data } = await UserService.me();
        const isEmpty = isEmptyHtml(begleitTextTemplate);
        if (begleitTextTemplate === "" || isEmpty) {
          setVersandTemplate(data.user.promotionWhatsApp);
        } else {
          setVersandTemplate(begleitTextTemplate || "");
        }
      };
      synchBegleitText();
    }, [begleitTextTemplate]);

    useImperativeHandle(ref, () => ({
      async save() {
        console.log("BegleitTextConfig.save()");
        return {
          landingPageData: {
            begleitTextTemplate: versandTemplate,
          },
        };
      },
    }));

    return (
      <div className="flex w-full text-left mr-16">
        <CollapseItem
          style="p-8"
          reduceRandMobile
          title={
            <div className="flex items-center gap-3 trkisswitch">
              <div className="font-semibold text-left text-md">
                Link Begleittext
              </div>
            </div>
          }
          icon={
            <Tooltip title="Kopierst du den Link in der Registerkarte des Promotors, wird er in diese Nachricht eingebettet um ihn deinem Promoter per Whatsapp zu senden. Die Formulierung ist aus der Sicht des Promoters gewählt, damit er Deine Nachricht nur kopieren und ohne Aufwand direkt weiterleiten kann. Im Empfehlungsportal dient es der Nachrichtenvorlage und dein Promoter kann diese selbst anpassen.">
              <span className="border-[1px] border-[#d8d8d8] p-2 rounded-[10px] mx-2">
                <AiOutlineInfoCircle />
              </span>
            </Tooltip>
          }
        >
          <div className="flex flex-col items-start justify-start gap-[1.88rem] py-4">
            <ClipboardButtonsBar
              names={[
                "Promoter Vorname",
                "Promoter Nachname",
                "Partner Vorname",
                "Partner Nachname",
                "Promoterseite",
                "Promolink",
              ]}
            />
            <div className="relative w-full text-[1rem]">
              {typeof versandTemplate === "string" && (
                <HTMLTextEditor
                  label={"Nachricht WhatsApp"}
                  value={versandTemplate}
                  onChange={(e) => {
                    setVersandTemplate(e);
                  }}
                  quillattributes={{
                    className: "w-full",
                  }}
                  options={{ ref: quillRef }}
                  variables={[
                    "{Promoter Vorname}",
                    "{Promoter Nachname}",
                    "{Partner Vorname}",
                    "{Partner Nachname}",
                    "{Promoterseite}",
                    "{Promolink}",
                  ]}
                />
              )}
            </div>
          </div>
        </CollapseItem>
      </div>
    );
  }
);

export default BegleitTextConfig;
