import React from "react";
import { message } from "antd";

const ClipboardButton = ({ name, classes }) => {
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    message.info("Kopiert");
  };

  return (
    <div
      className={`text-trkis cursor-pointer text-[1rem] ${classes}`}
      onClick={() => copyToClipboard(`{${name}}`)}
    >{`{${name}}`}</div>
  );
};

export default ClipboardButton;
