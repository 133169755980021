import { Button } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { HiOutlineFolder } from "react-icons/hi";
import AdminService from "../../../service/AdminService";
import { XLSICON } from "../partner/Svgs";
import { ExpandedCardVersionTwo as ExpandedCard } from "./Cards";
import ImportModule from "./ImportModule";
import { useSelector } from "react-redux";
import { selectLoading } from "../../../redux/auth/selectors";

const Index = () => {
  const [expanded, setexpanded] = useState(null);
  const [topValue, setTopValue] = useState(330);
  const [highlightedCard, setHighlightedCard] = useState("");
  const [list, setList] = useState([]);
  const loading = useSelector(selectLoading);

  const getPartners = async () => {
    try {
      const { data } = await AdminService.getLeads();

      setList(data?.leads);
    } catch (error) {}
  };
  useEffect(() => {
    getPartners();
  }, []);

  const query = window.location.href;
  React.useEffect(() => {
    if (query.split("?")[1]) {
      const queryRep = query.split("?")[1].split("=");
      if (queryRep[0] === "leads") {
        const partnerId = queryRep[1];
        setHighlightedCard(partnerId);

        if (list.length > 0) {
          const newIndex = list.findIndex((item) => item._id === partnerId);
          console.log(newIndex);
          if (newIndex >= 0 && expanded !== newIndex) {
            setexpanded(newIndex);
          }
        }
      }
    }
  }, [query, list]);

  function getActualColor(user) {
    return "#0084F7"; // turquoise
  }
  const cardsCollectionRef = useRef();
  return (
    <div>
      <div className="border-[1px] my-10 border-[#d8d8d8] bg-[#fafafa]   rounded-[30px]">
        <div className=" p-5 mt-5">
          <h1 className="text-[28px]  font-semibold">Alle Leads</h1>
          <div className="flex items-center gap-5 text-[#868686]">
            <HiOutlineFolder />
            <p>
              Finde mehr Informationen zu dem jeweiligen Lead, durchs klicken
              auf die Karte
            </p>

            <ImportModule
              ImportButton={({ onClick }) => (
                <Button
                  onClick={onClick}
                  type="primary"
                  className="bg-[#001D47]  flex items-center gap-3 rounded-[10px] text-white"
                >
                  <XLSICON /> Excel Import{" "}
                </Button>
              )}
              onImportEnd={async () => {
                getPartners();
              }}
            />
          </div>
        </div>
        <div className=" h-full max-w-screen overflow-hidden pt-[100px]">
          <div
            className="relative    transition-all secondary:mt-0 mt-[200px]  "
            ref={cardsCollectionRef}
            style={{
              height: cardsCollectionRef?.current?.scrollHeight,
            }}
          >
            {list
              .sort((a, b) => {
                if (a._id === highlightedCard) return -1;

                return 1;
              })
              .map((_, i) => {
                return (
                  <ExpandedCard
                    expanded={expanded === i}
                    data={_}
                    manipulateTop={setTopValue}
                    top={topValue}
                    className={"absolute transition-all !duration-1000 "}
                    bg={getActualColor(_)}
                    getPartners={getPartners}
                    setList={async ({ key, value }) => {
                      try {
                        setList((prev) =>
                          [...prev].map((e) => {
                            if (e._id !== _._id) {
                              return e;
                            } else {
                              return { ...e, [key]: value };
                            }
                          })
                        );
                        await AdminService.updateLeadDetails({
                          id: _._id,
                          data: {
                            ..._,
                            [key]: value,
                          },
                        }).then(async () => {
                          await getPartners();
                        });
                      } catch (error) {
                        console.log(error);
                      }
                    }}
                    props={{
                      style: {
                        zIndex: i * 100,
                        top:
                          expanded === null || i <= expanded
                            ? i * 100
                            : i * 100 + topValue,
                      },
                      onClick: (callback) => {
                        if (loading) return;
                        if (expanded !== i) {
                          setexpanded(i);
                          if (callback) callback();
                        }
                      },
                    }}
                  />
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
