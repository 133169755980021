import React, { useState } from "react";
import {
  Award,
  BadgeCheck,
  BatteryMedium,
  Bell,
  BicepsFlexed,
  ChartColumnIncreasing,
  ChartPie,
  CircleAlert,
  CircleCheckBig,
  Cog,
  Crown,
  Database,
  Fingerprint,
  Flag,
  Flame,
  Gem,
  Handshake,
  Heart,
  House,
  Lightbulb,
  Link,
  MessageCircleMore,
  MessageSquare,
  ShieldIcon,
  StarIcon,
  HeartIcon,
  ActivityIcon,
  AlertCircleIcon,
  CoffeeIcon,
  AirplayIcon,
  ArchiveIcon,
  AwardIcon,
  CameraIcon,
  CheckCircleIcon,
  CloudIcon,
  CodeIcon,
  CpuIcon,
  DiscIcon,
  DownloadIcon,
  EditIcon,
  FileIcon,
  GitBranchIcon,
  HomeIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
  ImagePlus,
} from "lucide-react";
import useThemeColor from "../../../hooks/useThemeColor";

const iconsList = [
  { name: "Shield", Icon: ShieldIcon },
  { name: "Star", Icon: StarIcon },
  { name: "Heart", Icon: HeartIcon },
  { name: "Activity", Icon: ActivityIcon },
  { name: "Alert", Icon: AlertCircleIcon },
  { name: "Coffee", Icon: CoffeeIcon },
  { name: "Airplay", Icon: AirplayIcon },
  { name: "Archive", Icon: ArchiveIcon },
  { name: "Award", Icon: AwardIcon },
  { name: "Camera", Icon: CameraIcon },
  { name: "Check", Icon: CheckCircleIcon },
  { name: "Cloud", Icon: CloudIcon },
  { name: "Code", Icon: CodeIcon },
  { name: "CPU", Icon: CpuIcon },
  { name: "Disc", Icon: DiscIcon },
  { name: "Download", Icon: DownloadIcon },
  { name: "Edit", Icon: EditIcon },
  { name: "File", Icon: FileIcon },
  { name: "Git", Icon: GitBranchIcon },
  { name: "Home", Icon: HomeIcon },
  // Neue Icons
  { name: "BadgeCheck", Icon: BadgeCheck },
  { name: "BatteryMedium", Icon: BatteryMedium },
  { name: "Bell", Icon: Bell },
  { name: "BicepsFlexed", Icon: BicepsFlexed },
  { name: "ChartColumnIncreasing", Icon: ChartColumnIncreasing },
  { name: "ChartPie", Icon: ChartPie },
  { name: "CircleAlert", Icon: CircleAlert },
  { name: "CircleCheckBig", Icon: CircleCheckBig },
  { name: "Cog", Icon: Cog },
  { name: "Crown", Icon: Crown },
  { name: "Database", Icon: Database },
  { name: "Fingerprint", Icon: Fingerprint },
  { name: "Flag", Icon: Flag },
  { name: "Flame", Icon: Flame },

  { name: "Gem", Icon: Gem },
  { name: "Handshake", Icon: Handshake },
  { name: "House", Icon: House },
  { name: "Lightbulb", Icon: Lightbulb },
  { name: "Link", Icon: Link },
  { name: "MessageCircleMore", Icon: MessageCircleMore },
  { name: "MessageSquare", Icon: MessageSquare },
];

function SimpleIconPicker({ selectedIcon, onChange }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const [color] = useThemeColor(); // z.B. "rgb(156, 33, 33)"
  const ITEMS_PER_PAGE = 6;
  const totalPages = Math.ceil(iconsList.length / ITEMS_PER_PAGE);
  const startIndex = pageIndex * ITEMS_PER_PAGE;
  const currentIcons = iconsList.slice(startIndex, startIndex + ITEMS_PER_PAGE);

  const handleNextPage = () => setPageIndex((prev) => (prev + 1) % totalPages);
  const handlePrevPage = () =>
    setPageIndex((prev) => (prev - 1 + totalPages) % totalPages);

  return (
    <div>
      <div className="relative w-fit">
        <button
          onClick={() => setIsModalOpen(true)}
          className="bg-transparent focus:outline-none"
        >
          {selectedIcon ? (
            React.createElement(
              iconsList.find((icon) => icon.name === selectedIcon)?.Icon,
              { size: 64, style: { color } }
            )
          ) : (
            <div className="w-12 h-12 flex items-center justify-center bg-gray-200 text-gray-500 rounded-lg">
              Kein Icon
            </div>
          )}
        </button>

        {/* Overlay-Icon oben rechts */}
        <div
          onClick={() => setIsModalOpen(true)}
          className="absolute top-1 right-1 bg-white dark:bg-slate-700 p-1 rounded-full shadow cursor-pointer"
          title="Icon bearbeiten"
        >
          <ImagePlus className="w-4 h-4 text-gray-700 dark:text-gray-300" />
        </div>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          {/* Hintergrund-Overlay */}
          <div
            className="absolute inset-0 bg-black opacity-50"
            onClick={() => setIsModalOpen(false)}
          />
          {/* Modal-Fenster */}
          <div className="bg-white dark:bg-slate-800 p-4 rounded shadow-lg z-10 max-w-md w-full">
            <h2 className="text-xl mb-4">Wähle ein Icon</h2>
            <div className="grid grid-cols-3 grid-rows-2 gap-4">
              {currentIcons.map(({ name, Icon }) => (
                <button
                  key={name}
                  onClick={() => {
                    onChange(name);
                    setIsModalOpen(false);
                  }}
                  className="flex flex-col items-center justify-center p-2 border rounded hover:bg-gray-100 dark:hover:bg-slate-700 bg-transparent"
                >
                  <Icon size={32} style={{ color }} />
                  <span className="text-sm mt-1">{name}</span>
                </button>
              ))}
            </div>
            <div className="flex justify-between items-center mt-4">
              <button
                onClick={handlePrevPage}
                className="flex items-center space-x-1 text-blue-600 hover:text-blue-800 transition"
              >
                <ChevronLeftIcon className="w-5 h-5" />
                <span>Zurück</span>
              </button>
              <button
                onClick={handleNextPage}
                className="flex items-center space-x-1 text-blue-600 hover:text-blue-800 transition"
              >
                <span>Weiter</span>
                <ChevronRightIcon className="w-5 h-5" />
              </button>
            </div>
            <div className="flex justify-end mt-4">
              <button
                onClick={() => {
                  onChange(null);
                  setIsModalOpen(false);
                }}
                className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 mr-4"
              >
                Kein Icon
              </button>
              <button
                onClick={() => setIsModalOpen(false)}
                className="text-blue-600 hover:underline"
              >
                Schließen
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SimpleIconPicker;
