import React, { useState, useEffect } from "react";
import { message, Dropdown, Menu, Tooltip } from "antd";
import { Editor, EditorState, RichUtils, ContentState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { convertFromHTML as convertFromHTML_draft } from "draft-convert";
import { CompositeDecorator, Modifier } from "draft-js";
import ClipboardButtonsBar from "../../../../Dashboard/kampaign/ClipboardButtonsBar";
import { PencilIcon, Bold, Italic, Underline, Info } from "lucide-react";
import useThemeColor from "../../../hooks/useThemeColor";
import "draft-js/dist/Draft.css";

const getBrushStyle = (svgContent, color) => {
  const strokeWidth = 10;

  let coloredSVG = svgContent
    .replace(/fill=(["'])(.*?)\1/gi, (match, quote) => {
      return `fill=${quote}${color}${quote}`;
    })
    .replace(/#fa6809/gi, color)
    .replace(/(fill=)['"]?#000['"]?/gi, `$1"${color}"`)
    .replace(/stroke-width=["']?\d+["']?/gi, `stroke-width="${strokeWidth}"`);

  return {
    backgroundImage: `url("data:image/svg+xml;utf8,${encodeURIComponent(
      coloredSVG
    )}")`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center 0.2em",
    backgroundSize: "150% 150%",
    lineHeight: "1.2",
    paddingBottom: "0.3em",
  };
};

const createBrushDecorator = (loadedBrushes, color) => {
  return new CompositeDecorator(
    Object.entries(loadedBrushes).map(([brushKey, svg]) => ({
      strategy: (contentBlock, callback, contentState) => {
        contentBlock.findStyleRanges(
          (character) => character.hasStyle(brushKey),
          callback
        );
      },
      component: (props) => {
        const style = getBrushStyle(svg, color);
        return (
          <span
            style={{
              ...style,
              display: "inline-block",
              lineHeight: "1.2",
              verticalAlign: "baseline",
            }}
            data-brush={brushKey}
          >
            {props.children}
          </span>
        );
      },
    }))
  );
};

const htmlToContent = (html) => {
  return convertFromHTML_draft({
    htmlToStyle: (nodeName, node, currentStyle) => {
      if (nodeName.toLowerCase() === "span") {
        const styleAttr = node.getAttribute("style") || "";
        if (styleAttr.includes("data:image/svg+xml")) {
          const brush = node.getAttribute("data-brush") || "BRUSH_2";
          return currentStyle.add(brush);
        }
      }
      return currentStyle;
    },
  })(html);
};

const EditableText = ({
  text,
  onSave,
  element: Element = "div",
  className = "",
  maxLines = 2,
  maxChars = 100,
}) => {
  const [color] = useThemeColor();
  const [loadedBrushes, setLoadedBrushes] = useState({});
  const [renderedHTML, setRenderedHTML] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [charCount, setCharCount] = useState(0);
  const [lineCount, setLineCount] = useState(0);

  const initialContent = text
    ? htmlToContent(text)
    : ContentState.createFromText("");

  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(initialContent)
  );

  // Load brushes once
  useEffect(() => {
    Promise.all([
      fetch("/brushes/Brush1.svg").then((res) => res.text()),
      fetch("/brushes/Brush2.svg").then((res) => res.text()),
      fetch("/brushes/Brush3.svg").then((res) => res.text()),
      fetch("/brushes/Brush4.svg").then((res) => res.text()),
      fetch("/brushes/Brush5.svg").then((res) => res.text()),
      fetch("/brushes/Brush6.svg").then((res) => res.text()),
      fetch("/brushes/Brush7.svg").then((res) => res.text()),
      fetch("/brushes/Brush8.svg").then((res) => res.text()),
      fetch("/brushes/Brush9.svg").then((res) => res.text()),
    ]).then(([svg2, svg69, svg95, svg5, svg17, svg19, svg23, svg37, svg50]) => {
      setLoadedBrushes({
        BRUSH_2: svg2,
        BRUSH_69: svg69,
        BRUSH_95: svg95,
        BRUSH_5: svg5,
        BRUSH_17: svg17,
        BRUSH_19: svg19,
        BRUSH_23: svg23,
        BRUSH_37: svg37,
        BRUSH_50: svg50,
      });
    });
  }, []);

  // Update rendered HTML when color or editorState changes
  useEffect(() => {
    const contentState = editorState.getCurrentContent();
    const html = stateToHTML(contentState, {
      inlineStyles: Object.fromEntries(
        Object.entries(loadedBrushes).map(([key, svg]) => [
          key,
          { style: getBrushStyle(svg, color) },
        ])
      ),
    });
    setRenderedHTML(html);
  }, [color, editorState, loadedBrushes]);

  useEffect(() => {
    if (Object.keys(loadedBrushes).length === 0) return;

    const decorator = createBrushDecorator(loadedBrushes, color);
    const content = editorState.getCurrentContent();

    const newEditorState = EditorState.createWithContent(content, decorator);
    setEditorState(
      EditorState.set(newEditorState, {
        selection: editorState.getSelection(), // Cursor behalten
      })
    );
  }, [color, loadedBrushes]);

  const toggleInlineStyle = (style) => {
    const selection = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const isBrush = style.startsWith("BRUSH_");

    let newContentState = contentState;

    if (isBrush) {
      // Alle Brushes entfernen
      Object.keys(loadedBrushes).forEach((brushKey) => {
        newContentState = Modifier.removeInlineStyle(
          newContentState,
          selection,
          brushKey
        );
      });
    }

    const newEditorStateBase = EditorState.push(
      editorState,
      newContentState,
      "change-inline-style"
    );

    // Jetzt den Style aus dem aktualisierten State prüfen!
    const currentStyles = newEditorStateBase.getCurrentInlineStyle();

    const toggledState = RichUtils.toggleInlineStyle(newEditorStateBase, style);

    setEditorState(toggledState);
  };

  const removeBrushStyles = () => {
    const selection = editorState.getSelection();
    if (selection.isCollapsed()) return;

    let newContentState = editorState.getCurrentContent();
    const blockMap = newContentState.getBlockMap();

    const newBlockMap = blockMap.map((block) => {
      const blockKey = block.getKey();
      let blockSelection = selection;

      // Wenn der aktuelle Block nicht in der Selektion ist, überspringen
      if (
        selection.getStartKey() > blockKey ||
        selection.getEndKey() < blockKey
      ) {
        return block;
      }

      // Erzeuge ein CharacterList mit allen Styles außer den Brushes
      const newCharacterList = block
        .getCharacterList()
        .map((charMeta, index) => {
          if (!charMeta) return charMeta;

          const style = charMeta.getStyle();
          const isInSelection = selection.isCollapsed()
            ? false
            : selection.getStartOffset() <= index &&
              selection.getEndOffset() >= index;

          if (isInSelection) {
            const filteredStyle = style.filter((s) => !loadedBrushes[s]);
            return charMeta.set("style", filteredStyle);
          }
          return charMeta;
        });

      return block.set("characterList", newCharacterList);
    });

    newContentState = newContentState.set("blockMap", newBlockMap);

    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      "change-inline-style"
    );

    setEditorState(EditorState.forceSelection(newEditorState, selection));
  };

  const handleEditorChange = (newEditorState) => {
    const content = newEditorState.getCurrentContent();
    const plainText = content.getPlainText();
    const linesCount = content.getBlockMap().size;

    setCharCount(plainText.length);
    setLineCount(linesCount);

    setEditorState(newEditorState);
  };

  const customStyleMap = {
    BOLD: { fontWeight: "bold" },
    ITALIC: { fontStyle: "italic" },
    UNDERLINE: { textDecoration: "underline" },
  };

  const handleSave = () => {
    const contentState = editorState.getCurrentContent();
    if (charCount > maxChars || lineCount > maxLines) {
      message.warning("Zeichen- oder Zeilenlimit überschritten!", 2);
      return;
    }
    const html = stateToHTML(contentState, {
      inlineStyles: Object.fromEntries(
        Object.entries(loadedBrushes).map(([key, svg]) => [
          key,
          {
            element: "span",
            attributes: { "data-brush": key },
            style: getBrushStyle(svg, color),
          },
        ])
      ),
    });
    onSave(html);
    setIsEditing(false);
  };

  const handleCancel = () => {
    const content = text
      ? htmlToContent(text)
      : ContentState.createFromText("");
    setEditorState(EditorState.createWithContent(content));
    setIsEditing(false);
  };

  const handleBrushChange = (val) => {
    if (val === "__clear__") {
      removeBrushStyles();
    } else {
      toggleInlineStyle(val);
    }
  };

  const menu = (
    <Menu onClick={(e) => handleBrushChange(e.key)}>
      <Menu.Item key="__clear__">❌ Brush entfernen</Menu.Item>
      {Object.entries(loadedBrushes).map(([key, svg]) => (
        <Menu.Item
          key={key}
          label={
            <div
              className="w-full h-6 border rounded"
              style={{
                backgroundImage: `url("data:image/svg+xml;utf8,${encodeURIComponent(
                  svg
                )}")`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "100% 100%",
              }}
            />
          }
        >
          <div className="w-full h-6 border rounded">
            <div
              style={{
                backgroundImage: `url("data:image/svg+xml;utf8,${encodeURIComponent(
                  svg
                )}")`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "100% 100%",
                width: "100%",
                height: "100%",
              }}
            />
          </div>
        </Menu.Item>
      ))}
    </Menu>
  );

  if (isEditing) {
    return (
      <div
        className={`relative border border-gray-300 p-2 rounded bg-white ${className}`}
      >
        <div className="flex flex-wrap items-start gap-2 mb-2">
          <div className="flex gap-2 flex-shrink-0">
            <button
              onClick={() => toggleInlineStyle("BOLD")}
              className="px-1 py-0.5 text-xs bg-slate-200 rounded"
              title="Fett"
            >
              <Bold className="w-4 h-4" />
            </button>
            <button
              onClick={() => toggleInlineStyle("ITALIC")}
              className="px-1 py-0.5 text-xs bg-slate-200 rounded"
              title="Kursiv"
            >
              <Italic className="w-4 h-4" />
            </button>
            <button
              onClick={() => toggleInlineStyle("UNDERLINE")}
              className="px-1 py-0.5 text-xs bg-slate-200 rounded"
              title="Unterstrichen"
            >
              <Underline className="w-4 h-4" />
            </button>

            {/* Hier Dropdown mit Menu für Brushes */}
            <Dropdown overlay={menu} trigger={["click"]}>
              <button className="px-2 py-1 text-xs bg-blue-500 text-white rounded">
                Brush auswählen
              </button>
            </Dropdown>

            <Tooltip title="Bitte nur einzelne Wörter mit Brush verzieren – nicht ganze Sätze oder Absätze.">
              <Info className="w-4 h-4 text-gray-500 hover:text-gray-700 cursor-help" />
            </Tooltip>
          </div>
          <div className="flex flex-col items-center gap-4 flex-shrink-0">
            <ClipboardButtonsBar
              full={true}
              names={["Promoter Vorname", "Promoter Nachname"]}
              classes="!flex-col flex-wrap !text-[0.75rem] md:!text-[1rem]"
            />
          </div>
        </div>

        <div className="min-h-[100px] max-h-[300px] w-full overflow-y-auto overflow-x-hidden break-words whitespace-pre-wrap !text-slate-950 p-2 border rounded">
          <Editor
            editorState={editorState}
            onChange={handleEditorChange}
            customStyleMap={customStyleMap}
          />
        </div>

        <div className="flex gap-2 mt-2">
          <button
            onClick={handleSave}
            className="px-2 py-1 text-xs bg-blue-500 text-white rounded"
          >
            Bestätigen
          </button>
          <button
            onClick={handleCancel}
            className="px-2 py-1 text-xs bg-gray-300 rounded"
          >
            Abbrechen
          </button>
          <div className="text-xs text-gray-500 mt-1">
            {charCount}/{maxChars} Zeichen · {lineCount}/{maxLines} Zeilen
            {(charCount > maxChars || lineCount > maxLines) && (
              <span className="text-red-500 ml-2">Limit überschritten!</span>
            )}
          </div>
        </div>

        <button
          onClick={handleCancel}
          className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
          title="Bearbeitung abbrechen"
        >
          <PencilIcon className="w-4 h-4" />
        </button>
      </div>
    );
  }

  return (
    <div className="relative" style={{ maxWidth: "100%" }}>
      <Element
        key={color}
        className={className}
        style={{ overflowWrap: "break-word" }}
        dangerouslySetInnerHTML={{ __html: renderedHTML }}
      />
      <button
        onClick={() => setIsEditing(true)}
        className="absolute top-0 -right-4 text-gray-600 hover:text-gray-800"
        title="Bearbeiten"
      >
        <PencilIcon className="w-4 h-4" />
      </button>
    </div>
  );
};

export default EditableText;
