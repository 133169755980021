import axios from "axios";
import { getBackendUrl } from "./getBackendUrl";
import { middleField } from "./middlefield";

class CustomThemeService {
  constructor(baseURL) {
    this.baseURL = baseURL;
    this.api = axios.create({
      baseURL,
    });
    middleField(this.api);
  }

  createCustomTheme(themeData) {
    return this.api.post("/", themeData);
  }
  getCustomThemes({ isEGTBonus = false } = {}) {
    return this.api.get(`/?isEGTBonus=${isEGTBonus}`);
  }
  deleteCustomTheme(id) {
    return this.api.delete(`/?id=${id}`);
  }
}

export default new CustomThemeService(`${getBackendUrl()}/customTheme`);
