export default function FAQModal() {
  return (
    <div className="relative  max-w-[95vw] bg-white rounded-[32px]">
      <div className="flex justify-center w-full mb-6">
        <img src="/images/faq.png" style={{ height: 100, marginBottom: -30 }} />
      </div>
      {/* FAQ Content */}
      <div className="space-y-4">
        {/* Prämie erhalten */}
        <div className="bg-[#eeeeee] rounded-2xl p-6">
          <div className="flex flex-col items-center text-center gap-3">
            <div className="flex flex-col gap-2 items-center">
              <div className="w-[50px] h-[50px] bg-[#ffffff] rounded-full flex items-center justify-center">
                <span className="text-cyan-500 text-2xl">
                  <img src="/images/Icon 6.png" className="rounded-full" />
                </span>
              </div>
              <h3 className="font-semibold text-blue-900 text-lg ">
                Prämie erhalten
              </h3>
            </div>
            <div>
              <p className="text-gray-600">
                Deinen Fortschritt kannst Du oben in der Prämienübersicht
                verfolgen. Sobald Du einen Meilenstein erreicht hast, erscheint
                ein Button über den du die Prämie einlösen kannst.
              </p>
            </div>
          </div>
        </div>

        {/* Doppelte Empfehlung */}
        <div className="bg-[#eeeeee] rounded-2xl p-6">
          <div className="flex flex-col items-center text-center gap-3">
            <div className="flex flex-col gap-2 items-center">
              <div className="w-[50px] h-[50px] bg-[#ffffff] rounded-full flex items-center justify-center">
                <span className="text-cyan-500 text-2xl">
                  <img src="/images/Icon 6.png" className="rounded-full" />
                </span>
              </div>
              <h3 className="font-semibold text-blue-900 text-lg ">
                doppelte Empfehlung
              </h3>
            </div>
            <div>
              <p className="text-gray-600">
                Sofern 2 Nutzer die selbe Empfehlung aussprechen, wird diese
                demjenigen zugeordnet, der die letzten Empfehlung ausgesprochen
                hat.
              </p>
            </div>
          </div>
        </div>

        {/* Persönlicher Link */}
        <div className="bg-[#eeeeee] rounded-2xl p-6">
          <div className="flex flex-col items-center text-center gap-3">
            <div className="flex flex-col gap-2 items-center">
              <div className="w-[50px] h-[50px] bg-[#ffffff] rounded-full flex items-center justify-center">
                <span className="text-cyan-500 text-2xl">
                  <img src="/images/Icon 6.png" className="rounded-full" />
                </span>
              </div>
              <h3 className="font-semibold text-blue-900 text-lg ">
                Persönlicher Link
              </h3>
            </div>
            <div>
              <p className="text-gray-600">
                Der Link ist dir persönlich auf Dich zugeordnet. Somit ist
                sicher gestellt, dass das System immer genau weiss wer von wem
                empfohlen wurde.
              </p>
            </div>
          </div>
        </div>

        <div className="bg-[#eeeeee] rounded-2xl p-6">
          <div className="flex flex-col items-center text-center gap-3">
            <div className="flex flex-col gap-2 items-center">
              <div className="w-[50px] h-[50px] bg-[#ffffff] rounded-full flex items-center justify-center">
                <span className="text-cyan-500 text-2xl">
                  <img src="/images/Icon 6.png" className="rounded-full" />
                </span>
              </div>
              <h3 className="font-semibold text-blue-900 text-lg ">
                Teamempfehlung
              </h3>
            </div>
            <div>
              <p className="text-gray-600">
                Wenn Du uns erfolgreich ein Team empfiehlst, gilt dies als eine
                Empfehlung. Wir beteiligen Dich alternativ am gesamten,
                planbaren Auftragswert. Buche dazu gerne einen Gesprächstermin.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
