// hooks/useThemeColor.js
import { useLandingPage } from "../context/LandingPageContext";

const useThemeColor = () => {
  const { templateData, setTemplateData } = useLandingPage();
  const color = templateData?.templateColor || "";

  const updateColor = (newColor) => {
    setTemplateData((prev) => ({
      ...prev,
      templateColor: newColor,
    }));
  };

  return [color, updateColor];
};

export default useThemeColor;
