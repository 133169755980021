import { useState, useEffect, useCallback } from "react";
import ColorPicker from "react-best-gradient-color-picker";
import { BgColorsOutlined } from "@ant-design/icons";
import { Button, Input, Modal } from "antd";
import { useIsMobileView } from "./ViewToggle";
import useThemeColor from "../../hooks/useThemeColor";
import { useLandingPage } from "../../context/LandingPageContext";

export const useSelectedColor = () => {
  const [color] = useThemeColor();
  return color;
};

const ColorPickerToggle = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [color, updateColor] = useThemeColor();
  const isMobileView = useIsMobileView();
  const { setTemplateData } = useLandingPage();

  useEffect(() => {
    // Update CSS Variable when color changes
    document.documentElement.style.setProperty("--custom-color", color);
  }, [color]);

  const handleColorChange = useCallback(
    (newColor) => {
      updateColor(newColor);
      // Speichere das templateColor-Feld in deinem globalen Context
      setTemplateData((prev) => ({ ...prev, templateColor: newColor }));
    },
    [updateColor, setTemplateData]
  );

  return (
    <>
      <Button
        onClick={() => setIsOpen(true)}
        shape="circle"
        className="fixed top-16 right-4 z-50 transition-colors"
        style={{
          backgroundColor: color,
          fontSize: isMobileView ? "0.875rem" : "1rem",
        }}
        aria-label="Farbe auswählen"
        icon={<BgColorsOutlined style={{ color: "black" }} />}
      />
      <Modal
        open={isOpen}
        onCancel={() => setIsOpen(false)}
        footer={null}
        centered
        className="text-center"
      >
        <ColorPicker color={color} onChange={handleColorChange} />
        <div className="mt-4 flex justify-between items-center">
          <Input
            value={color}
            onChange={(e) => handleColorChange(e.target.value)}
            className="w-24"
          />
          <Button type="primary" onClick={() => setIsOpen(false)}>
            Auswählen
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default ColorPickerToggle;
