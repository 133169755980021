import {
    ShieldIcon,
    StarIcon,
    HeartIcon,
    ActivityIcon,
    AlertCircleIcon,
    CoffeeIcon,
    AirplayIcon,
    ArchiveIcon,
    AwardIcon,
    CameraIcon,
    CheckCircleIcon,
    CloudIcon,
    CodeIcon,
    CpuIcon,
    DiscIcon,
    DownloadIcon,
    EditIcon,
    FileIcon,
    GitBranchIcon,
    HomeIcon,
    ChevronRightIcon,
    ChevronLeftIcon,
  } from "lucide-react";

  export const iconsList = [
    { name: "Shield", Icon: ShieldIcon },
    { name: "Star", Icon: StarIcon },
    { name: "Heart", Icon: HeartIcon },
    { name: "Activity", Icon: ActivityIcon },
    { name: "Alert", Icon: AlertCircleIcon },
    { name: "Coffee", Icon: CoffeeIcon },
    { name: "Airplay", Icon: AirplayIcon },
    { name: "Archive", Icon: ArchiveIcon },
    { name: "Award", Icon: AwardIcon },
    { name: "Camera", Icon: CameraIcon },
    { name: "Check", Icon: CheckCircleIcon },
    { name: "Cloud", Icon: CloudIcon },
    { name: "Code", Icon: CodeIcon },
    { name: "CPU", Icon: CpuIcon },
    { name: "Disc", Icon: DiscIcon },
    { name: "Download", Icon: DownloadIcon },
    { name: "Edit", Icon: EditIcon },
    { name: "File", Icon: FileIcon },
    { name: "Git", Icon: GitBranchIcon },
    { name: "Home", Icon: HomeIcon },
  ];