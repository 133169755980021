import React, { useCallback, useEffect, useRef, useState } from "react";
import { RxHamburgerMenu } from "react-icons/rx";
import {
  BellIcon,
  items,
  itemsSecond,
  LogoutDoor,
  notTimeFilter,
  ProfileSettings,
  Recycle,
  ShareIcon,
  TicketDropdown,
  VideoCam,
} from "./Navitems";

import { MdOutlineClose } from "react-icons/md";

import {
  Badge,
  Button,
  Dropdown,
  Layout,
  Menu,
  message,
  Segmented,
  theme,
} from "antd";

import moment from "moment";
import { AiOutlineSearch } from "react-icons/ai";

import { CiUser } from "react-icons/ci";
import { FaBookOpen } from "react-icons/fa";
import { FiSettings } from "react-icons/fi";
import { IoMdCall } from "react-icons/io";
import { useSelector } from "react-redux";
import {
  Link,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { MainLogo } from "../../assets/Svgs/GUTERTIPP";
import { useCredentials } from "../../hooks/useCredentails";
import { login, logout } from "../../redux/auth/actions";
import { selectUser } from "../../redux/auth/selectors";
import { store } from "../../redux/store";
import NotificationService from "../../service/NotificationService";
import PublicService from "../../service/PublicService";
import UserService from "../../service/UserService";
import { aboTarif } from "../../utils/divers";
import Einstellungen from "../Einstellungen/Einstellungen";
import EmailConfirmInfo from "../EmailConfirmInfo";
import Footer from "../Footer/Index";
import Messages from "../Messages/Messages";
import Notifications from "../Notification";
import Profile from "../Profile/Profile";
import VorschauEmpfehlungsportal from "../PromoterSite/VorschauEmpfehlungsportal";
import Support from "../Support/Support";
import Chapter from "../Tutorials/Chapter";
import Course from "../Tutorials/Course";
import Lessons from "../Tutorials/Lessons";
import Tutorials from "../Tutorials/Tutorials";
import Upgrade from "../Upgrade/Upgrade";
import Welcome from "../Upgrade/Welcome";
import Empfehlungen from "./Empfehlungen";
import Home from "./Home";
import LandingPage from "./LandingPage";
import LeaderBoard from "./Leaderboard";
import Pramein from "./Pramien";
import Promoter from "./Promoter";
import SearchSelect from "./SearchSelect/SearchSelect";
import Kampenen from "./kampaign";
import Empfehlen from "../Empfehlen";

const { Header, Sider, Content } = Layout;

function isValidMongoDBId(id) {
  // Check if the input is a string and matches the pattern of a MongoDB ObjectId
  const objectIdRegex = /^[a-f\d]{24}$/i; // 24 hexadecimal characters

  return typeof id === "string" && objectIdRegex.test(id);
}
const getTitle = (title) => {
  if (isValidMongoDBId(title)) return "Tutorials";
  if (title === "Course") return "Videokurs";

  return title;
};

const App = () => {
  const [badge, setBadgeCount] = useState(0);
  const [badgeTwo, setBadgeTwoCount] = useState(0);
  const navigate = useNavigate();
  const sideMenu = useRef();
  const location = useLocation();
  const { user } = useSelector(selectUser);
  const [notifications, setNotifications] = useState({ messages: [] });
  const [outNotifications, setOutNotifications] = useState({ messages: [] });
  const [notificationInboundPage, setNotificationInboundPage] = useState(1);
  const [adminTestPhase, setAdminTestPhase] = useState(null);
  const [notificationOutboundPage, setNotificationOutboundPage] = useState(1);

  useEffect(() => {
    const getNotifications = async () => {
      const { data: notification } =
        await NotificationService.getInboundMessages({
          pages: notificationInboundPage,
          limit: 10,
        });
      setBadgeCount(notification.unread);
      setNotifications((prev) => ({
        ...notification,
        messages: [...prev?.messages, ...notification?.messages],
      }));
    };
    getNotifications();
  }, [notificationInboundPage]);

  useEffect(() => {
    const getOutNotifications = async () => {
      const { data: outNotifications } =
        await NotificationService.getOutboundMessages({
          pages: notificationOutboundPage,
          limit: 10,
        });
      setBadgeTwoCount(outNotifications.unread);
      setOutNotifications((prev) => ({
        ...outNotifications,
        messages: [...prev?.messages, ...outNotifications?.messages],
      }));
    };
    getOutNotifications();
  }, [notificationOutboundPage]);

  const getOutNotifications = useCallback(async () => {
    const { data: outNotifications } =
      await NotificationService.getOutboundMessages({
        pages: 0,
        limit: 10,
      });
    setBadgeTwoCount(outNotifications.unread);
    setOutNotifications(() => ({
      ...outNotifications,
      messages: [...outNotifications?.messages],
    }));
  }, []);
  const getNotifications = useCallback(async () => {
    const { data: notification } = await NotificationService.getInboundMessages(
      {
        pages: 0,
        limit: 10,
      }
    );
    setBadgeCount(notification.unread);
    setNotifications(() => ({
      ...notification,
      messages: [...notification?.messages],
    }));
  }, []);
  useEffect(() => {
    getOutNotifications();
    getNotifications();
  }, [location, getOutNotifications, getNotifications]);

  const [hamburger, setHamburger] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [openSearch, setOpenSearch] = useState(false);
  const [showAffiliate, setShowAffiliate] = useState(false);
  const [userselect, setUserselect] = useState();
  const [select, setSelected] = useState({
    key: "dashboard",
    item: { props: { title: "Dashboard" } },
  });

  useEffect(() => {
    PublicService.getAffiliatePage().then(async ({ data }) => {
      if (data?.landingPage) setShowAffiliate(true);
    });
  }, []);

  const { admin } = useCredentials();
  const itemsDrop = [
    {
      key: "11",
      onClick: () => {
        navigate("/dashboard/einstellungen");
        toggleSidebar();
      },
      label: (
        <div className="flex items-center text-center justify-between  w-full ">
          <FiSettings fontSize={16} />
          <p className="font-semibold mx-2">Einstellungen</p>
        </div>
      ),
    },

    {
      key: "12123",
      onClick: () => {
        navigate("/dashboard/support");
        toggleSidebar();
      },
      label: (
        <div className="flex items-center text-center justify-between  w-full ">
          <TicketDropdown />
          <p className="font-semibold mx-2">Support</p>
        </div>
      ),
    },
    {
      key: "12123123",
      label: (
        <div
          className="flex items-center text-center justify-between  w-full "
          onClick={() => {
            navigate("/dashboard/profile");
            toggleSidebar();
          }}
        >
          <ProfileSettings />
          <p className="font-semibold mx-2">Konto</p>
        </div>
      ),
    },
    {
      key: "12112",
      label: (
        <div
          className={`flex items-center text-center justify-between  w-full ${
            !["Premium", "Standard"].includes(user?.subscription?.tier)
              ? "!text-[#ababab] cursor-default"
              : ""
          }`}
          title={
            !["Premium", "Standard"].includes(user?.subscription?.tier)
              ? "Bitte abonniere den Standardtarif"
              : ""
          }
          onClick={() => {
            if (!["Premium", "Standard"].includes(user?.subscription?.tier))
              return;
            navigate("/dashboard/course");
            toggleSidebar();
          }}
        >
          <VideoCam />
          <p className="font-semibold mx-2">Videokurs</p>
        </div>
      ),
    },
    {
      key: "12113",
      label: (
        <div
          className="flex items-center text-center justify-between  w-full "
          onClick={() => {
            navigate("/dashboard/tutorials");
            toggleSidebar();
          }}
        >
          <FaBookOpen />
          <p className="font-semibold mx-2">Tutorials</p>
        </div>
      ),
    },
    {
      key: "12115",
      label: (
        <div
          className={`flex items-center text-center justify-between  w-full ${
            user?.subscription?.tier !== "Premium"
              ? "!text-[#ababab] cursor-default"
              : ""
          }`}
          title={
            user?.subscription?.tier !== "Premium"
              ? "Bitte abonniere den Premiumtarif"
              : ""
          }
          onClick={() => {
            if (user?.subscription?.tier !== "Premium") return;
            window.open("https://us02web.zoom.us/j/87288872627", "_blank");
          }}
        >
          <IoMdCall />
          <p className="font-semibold mx-2">LiveCall</p>
        </div>
      ),
    },

    {
      key: "13",
      onClick: () => {
        store.dispatch(logout());
        window.location.href = "/";
      },
      label: (
        <div className="flex items-center text-center justify-between  w-full ">
          <LogoutDoor />
          <p className="font-semibold mx-2">Abmelden</p>
        </div>
      ),
    },
  ].filter((x) => !x?.hide);

  const {
    token: { colorBgContainer },
  } = theme.useToken();
  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(() => {
        if (window.innerWidth > 500 && window.innerWidth < 520) {
          setOpenSearch(false);
        }
        return window.innerWidth;
      });
    });

    return () => {
      window.removeEventListener("resize", () => {});
    };
  }, []);
  useEffect(() => {
    const pathKey = location.pathname.split("?")[0]
      ? location.pathname.split("?")[0].split("/").pop()
      : location.pathname.split("/").pop();

    const capitalizeFirstLetter = (string) => {
      return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const specialRoutes = {
      "pr%C3%A4mien": "Prämien",
      landingpage: "Landing Page",
      "": "Dashboard",
    };

    if (pathKey === "notifications" || pathKey === "messages") {
      setSelected({ key: "" });
      setTitle(capitalizeFirstLetter(pathKey));
    } else if (specialRoutes.hasOwnProperty(pathKey)) {
      setSelected({ key: specialRoutes[pathKey] });
      setTitle(specialRoutes[pathKey]);
    } else {
      const capitalizedKey = capitalizeFirstLetter(pathKey);
      setSelected({ key: capitalizedKey });
      setTitle(capitalizedKey);
    }
  }, [location.pathname]);

  const [resultFilter, setResultFilter] = useState("alltime");
  const [title, setTitle] = useState("Dashboard");

  const locationN = useLocation();
  useEffect(() => {
    const state = store.getState();
    if (!state?.auth?.user?.user) return;
    const getUserData = async () => {
      const response = await UserService.me();
      store.dispatch(login(response.data));
    };
    const getAdminConfigure = async () => {
      const response = await PublicService.getAdminConfigure();
      setAdminTestPhase(response?.data?.config?.testPhaseDurationDays);
    };
    if (locationN.pathname.includes?.("/dashboard/upgrade")) return;
    getUserData();
    getAdminConfigure();
  }, [locationN]);
  useEffect(() => {
    if (!user) return;
    if (!adminTestPhase) return;

    const subscribed = !!user?.subscription?.paid;
    const hasBeenWelcomed = !!user?.hasBeenWelcomed;
    const isTestPhase = (
      user?.firstAccessAt ? moment(user.firstAccessAt) : moment()
    )
      .add(adminTestPhase, "days")
      .isAfter(moment());

    if (
      !user?.emailConfirmed &&
      !window.location.href.includes("/dashboard/emailConfirmInfo")
    ) {
      navigate("/dashboard/emailConfirmInfo");
    } else if (
      !hasBeenWelcomed &&
      isTestPhase &&
      !window.location.href.includes("/dashboard/welcome") &&
      !window.location.href.includes("/dashboard/emailConfirmInfo")
    ) {
      navigate("/dashboard/welcome");
    } else if (
      !window.location.href.includes("/dashboard/emailConfirmInfo") &&
      !window.location.href.includes("/dashboard/upgrade") &&
      !window.location.href.includes("/dashboard/welcome") &&
      !window.location.href.includes("/dashboard/support")
    ) {
      if (isTestPhase) return;
      else if (!subscribed) navigate("/dashboard/upgrade");
    }
  }, [user, navigate, adminTestPhase]);
  const toggleSidebar = () => {
    const sider = sideMenu?.current;

    if (!hamburger) {
      sider.classList.remove("!w-[0px]", "!min-w-[0px]", "!max-w-[0px]");

      setHamburger(!hamburger);
    } else {
      sider.classList.add("!w-[0px]", "!min-w-[0px]", "!max-w-[0px]");
      setHamburger(!hamburger);
    }
  };
  return (
    <Layout
      className="max-w-[2000px] m-auto bg-white"
      style={{ overflowX: "auto" }}
    >
      <Sider
        trigger={null}
        className={`border-r-[#D8D8D8] xs:!w-[80px] !w-[0px] !min-w-[0px] !max-w-[0px] md:!w-[200px] md:!min-w-[200px] md:!max-w-[200px] border-r-[1px] transition-all animate-fade-right overflow-hidden`}
        collapsible
        theme="light"
        ref={sideMenu}
        collapsed={width < 768}
      >
        {width > 768 && (
          <Link
            to={"/dashboard/"}
            onClick={() => setTitle("Dashboard")}
            className="overflow-hidden  my-5 flex items-center justify-center"
          >
            <MainLogo />
          </Link>
        )}
        {width < 768 && (
          <div className="xs:mt-7 flex mt-5">
            <Link
              to={"/dashboard/"}
              onClick={() => setTitle("Dashboard")}
              className="m-auto xs:inline hidden"
            >
              <img
                src="/images/logo.png"
                className=" m-auto !w-[50px] "
                alt=""
                width="50"
              />
            </Link>
            <Dropdown
              prefixCls="profile-dropdown-custom"
              className="xs:hidden m-auto"
              menu={{
                items: itemsDrop.map((e) => {
                  return { ...e, title: null };
                }),
              }}
              trigger={["click"]}
            >
              <div>
                <img
                  onMouseDown={(e) => {
                    e.target.classList.add("scale-90", "shadow-2xl");
                  }}
                  onMouseUp={(e) => {
                    e.target.classList.remove("scale-90", "shadow-2xl");
                  }}
                  src={user?.avatar ?? "/images/postausgang-btn.png"}
                  alt=""
                  className="w-[40px] cursor-pointer  transition-all rounded-lg m-auto"
                />
              </div>
            </Dropdown>
          </div>
        )}
        {select.key !== "empfehlungen" ? (
          <Menu
            hidden={!hamburger && width < 500}
            onSelect={(e) => {
              toggleSidebar();
              setSelected({ key: e.key });
              setTitle(e?.key);

              if (e.key.toLowerCase() === "dashboard") {
                return navigate("/dashboard/");
              }
              navigate(`/dashboard/${e.key.split(" ").join("").toLowerCase()}`);
            }}
            items={itemsSecond.map((e) => ({
              ...e,
              key: e.label,
              title: null,
            }))}
            selectedKeys={[select.key]}
            className="!font-[500] px-3 !text-[14px] my-12 xs:my-[72px] md:my-20"
          />
        ) : (
          <Menu
            hidden={!hamburger && width < 500}
            selectable
            onSelect={(e) => {
              toggleSidebar();
              setSelected(e);
              setTitle(e?.key);

              if (e.key.toLowerCase() === "dashboard") {
                return navigate("/dashboard/");
              }
              navigate(`/dashboard/${e.key.split(" ").join("").toLowerCase()}`);
            }}
            items={items.map((e) => ({
              ...e,
              key: e.label,
              title: null,
            }))}
            className="!font-[500] px-3 !text-[14px] my-12 xs:my-[72px] md:my-20"
          />
        )}

        {admin && (
          <Button
            hidden={!hamburger && width < 500}
            className="!bg-[#000000] hover:!bg-[#000000] hover:!shadow-none relative hover:!text-white h-[50px] text-white  mx-auto rounded-[10px]  items-center mt-[300px]"
            style={{
              width: width < 768 ? "45px" : "180px",
              display: width < 768 ? "block" : "flex",
              paddnig: width < 768 ? "0px" : "12px 20px 12px 20px",
            }}
            onClick={() => navigate("/admindashboard")}
          >
            <span className="ms-[26px]">
              <Recycle />
              {width < 768 ? "" : "Ansicht wechseln"}
            </span>
          </Button>
        )}
      </Sider>
      <Layout className="overflow-x-hidden">
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
            height: "auto",
            position: "relative",
            zIndex: 91,
          }}
        >
          <div>
            <nav className=" flex items-center justify-between px-1 xs:px-5 py-5 xs:py-1 transition-all ">
              <div className=" gap-3 xs:hidden flex items-center">
                {width < 500 && (
                  <div className="cursor-pointer" onMouseDown={toggleSidebar}>
                    {!hamburger ? (
                      <RxHamburgerMenu className="transition-all   text-[28px] animate-fade" />
                    ) : (
                      <MdOutlineClose className="transition-all  text-[28px] animate-fade mx-5 xss:mx-auto " />
                    )}
                  </div>
                )}
                {!hamburger && (
                  <Link
                    to={"/dashboard/"}
                    onClick={() => setTitle("Dashboard")}
                    className="text-[20px] primary:text-[28px] w-[35px] h-[35px] font-semibold sm:hidden flex "
                  >
                    <img
                      src="/images/logo.png"
                      className="m-auto"
                      alt=""
                      width="50"
                    />
                  </Link>
                )}
              </div>
              <h1 className="text-[20px] primary:text-[28px] font-semibold xs:flex hidden ">
                {getTitle(title)
                  ?.replace?.(/%20/g, " ")
                  ?.replace?.("Support", " ")}
              </h1>
              <div className="black-search-input sm:!flex !hidden">
                <SearchSelect
                  value={userselect}
                  setValue={setUserselect}
                  prefixCls={"search-select-dashboard"}
                  setTitle={setTitle}
                />
              </div>
              <div className="flex items-center justify-between gap-1 relative">
                {!openSearch &&
                  showAffiliate &&
                  !location.pathname.includes("/dashboard/empfehlen") && (
                    <Link
                      to="/dashboard/empfehlen"
                      className="flex gap-1 items-center xl:h-[41px] h-[24px] xl:text-[14px] text-[12px] bg-[#001D47] text-[#00FFF5] p-2 xl:px-2 px-1 w-max rounded-md font-semibold"
                    >
                      <img
                        src="/images/herz 00FFF5.png"
                        className="xl:!h-[18px] !h-[14px]"
                      />
                      empfehlen
                    </Link>
                  )}
                {location.pathname.includes("/dashboard/empfehlen") && (
                  <div
                    style={{
                      width: 112,
                      height: 32,
                    }}
                  ></div>
                )}
                {user?.subscription?.tier !== "Premium" && (
                  <Link
                    to="/dashboard/upgrade"
                    className="flex gap-1 items-center xl:h-[41px] h-[24px] xl:text-[14px] text-[12px] bg-[#001D47] text-[#ffffff] p-2 xl:px-2 px-1 w-max rounded-md font-semibold"
                  >
                    Upgrade
                  </Link>
                )}
                <div className="xl:flex hidden">
                  <Badge
                    count={Math.max(0, badge)}
                    className="xl:block hidden z-50"
                  >
                    <Link to={"/dashboard/notifications"}>
                      <Button
                        onClick={() => {
                          setSelected({
                            key: "",
                          });
                          setTitle("Benachrichtigungen");
                        }}
                        className={`relative h-[45px]  scale-90 w-[45px]  xl:block hidden  ${
                          window.location.pathname ===
                            "/dashboard/notifications" &&
                          "!bg-blue-main !text-white"
                        }`}
                      >
                        <BellIcon className="top-[0px] relative left-[-8px]" />
                      </Button>
                    </Link>
                  </Badge>
                  <Badge
                    count={Math.max(0, badgeTwo)}
                    className="xl:block hidden "
                  >
                    {" "}
                    <Link to={"/dashboard/messages"}>
                      <Button
                        onClick={() => {
                          setSelected({
                            key: "",
                          });
                          setTitle("Benachrichtigungen");
                        }}
                        className={`relative h-[45px]  scale-90 w-[45px]  xl:block hidden  ${
                          window.location.pathname === "/dashboard/messages" &&
                          "!bg-blue-main !text-white"
                        }`}
                      >
                        <ShareIcon className="top-[0px] relative left-[-8px]" />
                      </Button>
                    </Link>
                  </Badge>
                </div>
                <div className="ml-2">
                  {openSearch && (
                    <div className="xss:static absolute flex top-[30px] animate-fade-up left-[-100%] overflow-hidden  sm:hidden">
                      <SearchSelect
                        value={userselect}
                        setValue={setUserselect}
                        usesimple={true}
                        prefixCls={"sm"}
                        setTitle={setTitle}
                      />
                    </div>
                  )}
                </div>
                <div className="xl:hidden gap-2 flex ">
                  {!openSearch ? (
                    <AiOutlineSearch
                      className="sm:hidden text-responsiveHeading cursor-pointer"
                      onClick={() => setOpenSearch((prev) => !prev)}
                    />
                  ) : (
                    <MdOutlineClose
                      className="sm:hidden text-responsiveHeading cursor-pointer"
                      onClick={() => setOpenSearch((prev) => !prev)}
                    />
                  )}
                  <Badge count={Math.max(0, badge)} className="z-10">
                    <Link to={"/dashboard/notifications"}>
                      <BellIcon className="" />
                    </Link>
                  </Badge>
                  <Badge count={Math.max(0, badgeTwo)}>
                    <Link to={"/dashboard/messages"}>
                      <ShareIcon className="" />
                    </Link>
                  </Badge>
                </div>
                <div className="w-[10px] xs:hidden block"></div>
                <div className="xs:block hidden">
                  <Dropdown
                    prefixCls="profile-dropdown-custom"
                    menu={{
                      items: itemsDrop,
                    }}
                    trigger={["click"]}
                  >
                    <div className="flex items-center h-[60px]">
                      {user?.avatar ? (
                        <img
                          onMouseDown={(e) => {
                            e.target.classList.add("scale-90", "shadow-2xl");
                          }}
                          onMouseUp={(e) => {
                            e.target.classList.remove("scale-90", "shadow-2xl");
                          }}
                          src={user?.avatar ?? "/images/postausgang-btn.png"}
                          alt=""
                          className="w-[40px] h-[40px] rounded-full cursor-pointer ml-5 transition-all rounded-lg  object-cover"
                        />
                      ) : (
                        <CiUser size={40} className="cursor-pointer" />
                      )}
                    </div>
                  </Dropdown>
                </div>
              </div>
            </nav>
          </div>
        </Header>
        {/* {loading && <Loader />} */}

        <Content
          className="xs:p-[24px] p-2"
          style={{
            minHeight: 1500,
            background: colorBgContainer,
            overflowY: "auto",
            overflowX: "hidden",
            paddingBottom: "200px",
            position: "static",
            minWidth: 250,
          }}
        >
          <div className="overflow-auto">
            {!notTimeFilter.some((e) => {
              return e === location.pathname || location.pathname.match(e);
            }) &&
              location.pathname.indexOf("chapter") < 0 && (
                <Segmented
                  className="mx-0 xs:mx-5 my-6  font-medium text-[12px] sm:text-[14px] rounded-[10px]"
                  value={resultFilter}
                  onChange={(e) => {
                    if (aboTarif(user) < 2 && e !== "alltime") {
                      return message.info(
                        "Um die Daten nach spezifischen Zeiträumen zu filtern, musst du auf eine höhere Abonnementstufe upgraden."
                      );
                    }

                    setResultFilter(e);
                  }}
                  options={[
                    {
                      label: (
                        <div className=" px-[10px] rounded-me rounded-[6px] py-[7px]">
                          Alle
                        </div>
                      ),
                      value: "alltime",
                    },
                    {
                      label: (
                        <div className="px-[10px] rounded-me rounded-[6px] py-[7px]">
                          7 Tage
                        </div>
                      ),
                      value: "7days",
                    },
                    {
                      label: (
                        <div className="px-[10px] rounded-me rounded-[6px] py-[7px]">
                          Letzter Monat
                        </div>
                      ),
                      value: "lastmonth",
                    },
                    {
                      label: (
                        <div className="px-[10px] rounded-me rounded-[6px] py-[7px]">
                          3 Monate
                        </div>
                      ),
                      value: "3months",
                    },
                    {
                      label: (
                        <div className="px-[10px] rounded-me rounded-[6px] py-[7px]">
                          Letztes Jahr
                        </div>
                      ),
                      value: "lastyear",
                    },
                  ]}
                />
              )}
          </div>
          <Routes>
            {" "}
            <Route
              path="/"
              element={
                <Home resultFilter={resultFilter} setSelected={setSelected} />
              }
            />
            <Route
              path="/empfehlungen"
              element={
                <Empfehlungen title={setTitle} resultFilter={resultFilter} />
              }
            />
            <Route
              path="/einstellungen"
              element={<Einstellungen title={setTitle} />}
            />
            <Route
              path="/landingpage"
              element={
                <LandingPage title={setTitle} resultFilter={resultFilter} />
              }
            />
            <Route
              path="leaderboard"
              element={
                <LeaderBoard title={setTitle} resultFilter={resultFilter} />
              }
            />
            <Route
              path="/prämien"
              element={<Pramein title={setTitle} resultFilter={resultFilter} />}
            />
            <Route
              path="/promoter"
              element={
                <Promoter title={setTitle} resultFilter={resultFilter} />
              }
            />
            <Route
              path="/notifications"
              element={
                <Notifications
                  getNotifications={getNotifications}
                  isInbound={true}
                  data={notifications?.messages}
                  setNotificationInboundPage={setNotificationInboundPage}
                  notificationInboundPage={notificationInboundPage}
                  totalPages={notifications?.totalPages}
                  title={setTitle}
                  read={async (e) => {
                    await NotificationService.readInboundMessage(e._id);
                    setNotifications((prev) => ({
                      ...prev,
                      messages: [...prev.messages].map((me) => {
                        if (me._id !== e._id) {
                          return me;
                        } else {
                          return { ...me, hasRead: true };
                        }
                      }),
                    }));
                    setBadgeCount((prev) => prev - 1);
                  }}
                  resultFilter={resultFilter}
                />
              }
            />
            <Route
              path="/kampagnen"
              element={<Kampenen resultFilter={resultFilter} />}
            />
            <Route
              path="/tutorials"
              element={
                <Tutorials title={setTitle} resultFilter={resultFilter} />
              }
            />
            {aboTarif(user) >= 2 && (
              <Route path="/course" element={<Course title={setTitle} />} />
            )}
            <Route path="/upgrade" element={<Upgrade title={setTitle} />} />
            <Route path="/welcome" element={<Welcome title={setTitle} />} />
            <Route
              path="/emailConfirmInfo"
              element={<EmailConfirmInfo title={setTitle} />}
            />
            <Route
              path="/profile"
              element={<Profile resultFilter={resultFilter} title={setTitle} />}
            />
            <Route path="/chapter/:id" element={<Chapter />} />
            <Route path="/video/:chapterId/:id" element={<Lessons />} />
            <Route path="/support" element={<Support title={setTitle} />} />
            <Route
              path="/messages"
              element={
                <Messages
                  getNotifications={getOutNotifications}
                  isInbound={false}
                  resultFilter={resultFilter}
                  data={outNotifications?.messages}
                  setNotificationOutboundPage={setNotificationOutboundPage}
                  notificationOutboundPage={notificationOutboundPage}
                  totalPages={outNotifications?.totalPages}
                  read={async (e) => {
                    await NotificationService.readInboundMessage(e._id);
                    setOutNotifications((prev) => ({
                      ...prev,
                      messages: [...prev.messages].map((me) => {
                        if (me._id !== e._id) {
                          return me;
                        } else {
                          return { ...me, hasRead: true };
                        }
                      }),
                    }));
                    setBadgeTwoCount((prev) => prev - 1);
                  }}
                />
              }
            />
            <Route
              path="/pramienportal/Vorschau Empfehlungsportal"
              element={<VorschauEmpfehlungsportal />}
            />
            <Route path="/empfehlen" element={<Empfehlen />} />
          </Routes>
          <div className="w-full px-3 tertiary:max-w-[1600px]  bg-white border-t-[1px] border-[#d8d8d8]   mt-10">
            <Footer isDashboard={true} />
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default App;
