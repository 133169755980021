import { Alert } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LandingPageService from "../../../service/LandingPageService";
import { checkIsOldPage } from "../../../utils/checkIsOldPage";
import LandingPageCard from "./LandingPageCard";

const Index = () => {
  const navigate = useNavigate();
  const [landingPages, setLandingPages] = useState([]);
  const [loadedPages, setLoadedPages] = useState(new Set());
  const [isLoading, setIsLoading] = useState(true);
  const [isOldPage, setIsOldPage] = useState({});
  const [loadingStandard, setLodingStandard] = useState(false);

  const getLandingPages = async (silent = false) => {
    if (!silent) setIsLoading(true);
    try {
      let { data } = await LandingPageService.list();

      const exceptions = [
        "6710bba04cfbf684414763b5",
        "6654366ea05c7cb55bebfec8",
      ];
      const oldPageChecks = await Promise.all(
        data.map(async (page) => {
          const detailLP = await LandingPageService.detail(page._id);
          const relevantKeys = [
            "accompanyingTextOne",
            "introVideoHeader",
            "introductoryText",
            "accompanyingTextTwo",
            "accompanyingTextThree",
            "questionaireIntroductionText",
          ];

          const hasOldPage =
            detailLP.data["theme"] === "xyz"
              ? true
              : relevantKeys.some(
                  (key) =>
                    detailLP.data[key] && checkIsOldPage(detailLP.data[key])
                );

          const isNotFinance =
            detailLP.data["theme"] !== "finance" &&
            detailLP.data["theme"] !== "property" &&
            detailLP.data["theme"] !== "xyz" &&
            detailLP.data["theme"] !== "stormgas" &&
            detailLP.data["theme"] !== "basic";

          const isException = exceptions.includes(page._id);
          const finalIsNotFinance = isException ? false : isNotFinance;

          return [page._id, finalIsNotFinance ? false : hasOldPage];
        })
      );

      const oldPageMap = Object.fromEntries(oldPageChecks);
      setIsOldPage(oldPageMap);
      setLandingPages(data.reverse());
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getLandingPages();
  }, []);

  const handlePageLoad = (pageId) => {
    setLoadedPages((prev) => new Set(prev).add(pageId));
  };

  return (
    <div className="bg-[#fafafa] rounded-mini p-4 sm:p-8 lg:!text-left !text-center">
      <div className="text-[28px] font-semibold my-10">Deine Landingpages</div>
      <div
        className="w-full flex justify-center items-center h-[200px] rounded-mini bg-white box-border overflow-hidden border-[1px] border-solid border-grey-accent mb-5 cursor-pointer"
        onClick={() => navigate("/landingpagenew")}
      >
        <div className="flex flex-row items-start justify-start gap-[0.63rem]">
          <img
            className="w-[1.69rem] h-[1.69rem] overflow-hidden shrink-0"
            alt=""
            src="/images/icons8plusmathe-1.png"
          />
          <div className="font-semibold">Landingpage erstellen</div>
        </div>
      </div>

      <div className="flex flex-col items-start justify-start gap-[1.88rem] text-[1.25rem]">
        {!isLoading &&
          landingPages?.map((page, i) => (
            <LandingPageCard
              key={i}
              data={page}
              getLandingPages={getLandingPages}
              onLoad={() => handlePageLoad(page._id)}
              isVisible={loadedPages.has(page._id)}
              IsOldPage={isOldPage[page._id]}
              landingPages={landingPages}
              loadingStandard={loadingStandard}
              setLodingStandard={setLodingStandard}
            />
          ))}
        {isLoading && (
          <div className="w-full p-5 text-center">
            <div className="w-8 h-8 border-4 border-blue-main border-t-transparent rounded-full animate-spin mx-auto"></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Index;
