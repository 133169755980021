import React from "react";
import PropTypes from "prop-types";

const shapes = {
  round: "rounded-[10px]",
};
const variants = {
  fill: {
    blue_main: "bg-[#0084f7] text-[#ffffff]",
  },
};
const sizes = {
  xxs: "h-[38px] px-[34px] text-[16px]",
  xs: "h-[58px] px-[34px] text-[16px]",
};

const Button = ({
  children,
  className = "",
  leftIcon,
  rightIcon,
  shape,
  variant = "fill",
  size = "xs",
  color = "blue_main",
  ...restProps
}) => {
  return (
    <button
      className={`${className} flex flex-row items-center justify-center self-stretch text-center cursor-pointer whitespace-nowrap text-[#ffffff] text-[16px] font-semibold bg-[#0084f7] rounded-[10px] ${
        shape && shapes[shape]
      } ${size && sizes[size]} ${variant && variants[variant]?.[color]}`}
      {...restProps}
    >
      {!!leftIcon && leftIcon}
      {children}
      {!!rightIcon && rightIcon}
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  leftIcon: PropTypes.node,
  rightIcon: PropTypes.node,
  shape: PropTypes.oneOf(["round"]),
  size: PropTypes.oneOf(["xs"]),
  variant: PropTypes.oneOf(["fill"]),
  color: PropTypes.oneOf(["blue_main"]),
};

export { Button };
