import React from "react";
import { Select } from "antd";
import { useLandingPage } from "../../../components/New_LP_Configuration/context/LandingPageContext";

const { Option } = Select;

const FontSelector = () => {
  const { templateData, setTemplateData } = useLandingPage();

  // Definierte Schriftarten, die zur Auswahl stehen
  const fonts = [
    { label: "Modern", value: "modern" },
    { label: "Elegant", value: "elegant" },
    { label: "Classic", value: "classic" },
    { label: "Sans Serif", value: "sans-serif" },
    { label: "Serif", value: "serif" },
  ];

  // Handler für die Änderung der Schriftart
  const handleChange = (value) => {
    setTemplateData((prev) => ({
      ...prev,
      font: value,
    }));
  };

  return (
    <div className="font-selector w-full">
      <h3>Schriftart auswählen</h3>
      <Select
        value={templateData.font}
        onChange={handleChange}
        style={{ width: "100%" }}
        placeholder="Wähle eine Schriftart"
      >
        {fonts.map((font) => (
          <Option key={font.value} value={font.value}>
            {font.label}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default FontSelector;
