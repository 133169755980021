import { Button, Checkbox, message, Modal, Space } from "antd";
import { ExternalLink } from "lucide-react";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectLoading } from "../../../redux/auth/selectors";
import AdminService from "../../../service/AdminService";
import CrudService from "../../../service/CrudService";
import PromotionsLinkService from "../../../service/PromotionsLinkService";
import { LoadingDiv } from "../../Dashboard/Promoter/Cards";
import { AddBonusPramieModal } from "../../Einstellungen/AddBonusPramieModal";
import { AddLandingPageModal } from "../../Einstellungen/AddLandingPageModal";
import { Heading } from "../../Einstellungen/Heading";
import FloatLabel from "../../FloatingLabel";
import ClipboardButtonsBar from "../../Dashboard/kampaign/ClipboardButtonsBar";
export default function AffiliateConfig() {
  const [openAddNewModal, setOpenAddNewModal] = useState(false);
  const [openAddBonusPramieModal, setOpenAddBonusPramieModal] = useState(false);
  const [landingPages, setLandingPages] = useState([]);
  const [bonuses, setBonuses] = useState([]);
  const [editingLP, setEditingLP] = useState(null);
  const [editingBonus, setEditingBonus] = useState(null);
  const [confirmation, setConfirmation] = useState(false);
  const loading = useSelector(selectLoading);
  const [checkboxValue, setCheckboxValue] = useState(false);
  const [begleittext, setBegleittext] = useState("");
  const [loadingBegleittext, setLoadingBegleittext] = useState(false);

  const getAdminSetting = async () => {
    const { data } = await AdminService.getAdminSetting();
    setBegleittext(data?.CMS?.affiliateBegleittext);
  };

  const fetchLandingPages = useCallback(async () => {
    const res = await CrudService.search("LinkKonfig", 100000, 1, {
      filters: { isEGTBonus: true },
      populate: "landingPage",
    });
    setLandingPages(res.data.items);
  }, []);

  useEffect(() => {
    fetchLandingPages();
  }, [fetchLandingPages]);

  useEffect(() => {
    getAdminSetting();
  }, []);

  const fetchBonuses = useCallback(async () => {
    const res = await CrudService.search("BonusPramie", 100000, 1, {
      filters: { isEGTBonus: true },
    });
    setBonuses(res.data.items);
  }, []);

  useEffect(() => {
    fetchBonuses();
  }, [fetchBonuses]);

  const onBegleittextSave = useCallback(async () => {
    setLoadingBegleittext(true);
    try {
      await AdminService.setAdminConfiguration({
        key: "CMS.affiliateBegleittext",
        value: begleittext,
      });
    } catch (e) {
    } finally {
      setLoadingBegleittext(false);
    }
  }, [begleittext]);

  return (
    <div>
      <div className=" flex flex-col gap-[30px] self-stretch">
        <div className="flex flex-col items-start gap-5">
          <Heading
            size="desktop_label_bold"
            as="h5"
            className="text-[20px] smx:text-[18px] font-semibold text-[#000000]"
          >
            Begleittext konfigurieren
          </Heading>
          <ClipboardButtonsBar
            names={["PPartner Vorname", "PPartner Nachname"]}
          />
          <div className="flex flex-col gap-1 w-full">
            <FloatLabel
              className={"border border-[#d8d8d8] rounded-3xs w-full  p-2"}
              keepitfocused
              label={"Begleittext"}
            >
              <textarea
                rows={4}
                type="text"
                value={begleittext}
                onChange={(e) => setBegleittext(e.target.value)}
                className="outline-none border-none appearance-none h-full w-full bg-transparent"
              />
            </FloatLabel>
            <Button
              type="primary"
              onClick={onBegleittextSave}
              loading={loadingBegleittext}
            >
              Speichern
            </Button>
          </div>

          <Heading
            size="desktop_label_bold"
            as="h5"
            className="text-[20px] smx:text-[18px] font-semibold text-[#000000]"
          >
            Links konfigurieren
          </Heading>
          <div className="self-stretch rounded-[16px] bg-[#ffffff] p-[30px] smx:p-6 overflow-auto">
            <div className="flex flex-col gap-[30px] ">
              {landingPages?.map?.((x) => (
                <div
                  key={x._id}
                  className="flex flex-1 items-center justify-between gap-5 mdx:flex-col-reverse mdx:items-start mdx:border-[#dedede] mdx:border-b mdx:pb-[30px] smx:gap-2"
                >
                  <div className="flex justify-start  gap-10 mdx:gap-2">
                    <div className="flex flex-col items-start w-[200px] mdx:w-[150px] justify-center">
                      <Heading
                        as="p"
                        className="text-[14px] font-medium text-[#868686]"
                      >
                        Thema
                      </Heading>

                      <div className="flex items-center gap-2.5">
                        <img
                          src={x.theme.icon}
                          alt=" "
                          className="w-[20px] h-[20px] object-contain"
                        />
                        <Heading
                          size="desktop_label_semibold"
                          as="h6"
                          className="text-[16px] font-semibold text-[#000000] max-w-[150px] truncate overflow-hidden"
                        >
                          {x?.theme?.name ?? x?.theme?.label}
                        </Heading>
                      </div>
                    </div>
                    <div className="flex flex-col items-left justify-center">
                      <Heading
                        as="p"
                        className="text-[14px] font-medium text-[#868686]"
                      >
                        Landingpage
                      </Heading>
                      <Heading
                        size="desktop_label_semibold"
                        as="h6"
                        className="text-[16px] font-semibold text-[#000000] max-w-[300px] mdx:max-w-[150px] truncate"
                      >
                        {x?.landingPage?.name}
                      </Heading>
                    </div>
                  </div>
                  <div className="flex gap-2.5">
                    <LoadingDiv loading={loading}>
                      {x?.disabled ? (
                        <img
                          src="/images/img_info_red.svg"
                          alt="Info Icon"
                          className="h-[38px] mdx:h-[28px] rounded-lg cursor-pointer bg-[#ff0000]"
                          onClick={async () => {
                            const existing = await CrudService.search(
                              "LinkKonfig",
                              2,
                              1,
                              {
                                filters: {
                                  isEGTBonus: true,
                                  disabled: false,
                                },
                              }
                            );

                            if (existing.data.items.length > 0) {
                              return message.error(
                                `Du hast bereits eine aktive Landingpage für Affiliates`
                              );
                            }

                            await CrudService.update("LinkKonfig", x._id, {
                              disabled: false,
                            });
                            fetchLandingPages();
                          }}
                        />
                      ) : (
                        <img
                          src="/images/img_info_green.svg"
                          alt="Info Icon"
                          className="h-[38px] mdx:h-[28px] rounded-lg cursor-pointer"
                          onClick={() =>
                            setConfirmation({
                              action: "disable",
                              id: x._id,
                              text: "Mit der Deaktivierung dieser Landingpage wird Affiliate-System kurzzeitig nicht verfügbar. Denke daran, gleich ein anderes Landingpage zu aktivieren.",
                            })
                          }
                        />
                      )}
                    </LoadingDiv>

                    <img
                      onClick={() => {
                        setEditingLP(x);
                        setOpenAddNewModal(true);
                      }}
                      className="h-[38px] mdx:h-[28px] w-[38px] rounded-lg cursor-pointer"
                      src="/images/img_edit.svg"
                    />

                    <img
                      onClick={() =>
                        setConfirmation({
                          action: "delete",
                          id: x._id,
                          text: "Diese Aktion ist nicht rückgängig.",
                        })
                      }
                      src="/images/img_info_white_0.svg"
                      alt="White Info Icon"
                      className="h-[38px] mdx:h-[28px] rounded-lg cursor-pointer"
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <Button
          onClick={() => {
            setOpenAddNewModal(true);
            setEditingLP(null);
          }}
          className="flex h-[70px] flex-row items-center justify-center self-stretch rounded-[10px] bg-[#0084f7] px-[34px] text-center text-[20px] smx:text-[18px] font-semibold text-[#ffffff] sm:px-5 mdx:text-[14px]"
        >
          Neue Landingpage
        </Button>
      </div>

      <div className="mt-5 flex flex-col items-start justify-center gap-[26px] self-stretch">
        <div className="w-full flex justify-between smx:flex-col gap-4">
          <Heading
            size="desktop_label_bold"
            as="h5"
            className="text-[20px] smx:text-[18px] font-semibold text-[#000000]"
          >
            Bonusprämien konfigurieren
          </Heading>

          <Link to={"/dashboard/empfehlen"} target="_blank">
            <Space>
              <Heading
                size="desktop_label_bold"
                as="h5"
                className="text-[20px] smx:text-[18px] font-semibold text-[#000000]"
              >
                Testseite ansehen
              </Heading>
              <ExternalLink size={18} className="cursor-pointer" />
            </Space>
          </Link>
        </div>

        <div className="self-stretch rounded-[16px] bg-[#ffffff] p-[30px] smx:p-6 overflow-auto">
          <div className="flex flex-col gap-[30px]">
            {bonuses?.map?.((x) => (
              <div
                key={x._id}
                className="flex flex-1 items-center justify-between gap-5 mdx:flex-col-reverse mdx:items-start smx:gap-2 mdx:border-[#dedede] mdx:border-b mdx:pb-[30px]"
              >
                <div className="flex justify-start  gap-10 mdx:gap-2">
                  <div className="flex flex-col items-start w-[200px] mdx:w-[150px] justify-center">
                    <Heading
                      as="p"
                      className="text-[14px] font-medium text-[#868686]"
                    >
                      Prämienname
                    </Heading>

                    <div className="flex items-center gap-2.5">
                      {x.icon && (
                        <img
                          src={x.icon}
                          alt=" "
                          className="w-[20px] h-[20px] object-contain"
                        />
                      )}
                      <Heading
                        size="desktop_label_semibold"
                        as="h6"
                        className="text-[16px] font-semibold text-[#000000] max-w-[150px] truncate overflow-hidden"
                      >
                        {x?.name}
                      </Heading>
                    </div>
                  </div>
                  <div className="flex flex-col items-left justify-center">
                    <Heading
                      as="p"
                      className="text-[14px] font-medium text-[#868686]"
                    >
                      Anzahl Empfehlungen
                    </Heading>
                    <Heading
                      size="desktop_label_semibold"
                      as="h6"
                      className="text-[16px] font-semibold text-[#000000] max-w-[300px] truncate"
                    >
                      {x?.anzahlEmpfehlungen}
                    </Heading>
                  </div>
                </div>
                <div className="flex gap-2.5">
                  <LoadingDiv loading={loading}>
                    {x?.disabled ? (
                      <img
                        src="/images/img_info_red.svg"
                        alt="Info Icon"
                        className="h-[38px] mdx:h-[28px] rounded-lg cursor-pointer bg-[#ff0000]"
                        onClick={async () => {
                          const existing = await CrudService.search(
                            "BonusPramie",
                            2,
                            1,
                            {
                              filters: {
                                isEGTBonus: true,
                                anzahlEmpfehlungen: x.anzahlEmpfehlungen,
                              },
                            }
                          );

                          if (
                            existing.data.items.find(
                              (b) => b._id !== x._id && !b.disabled
                            )
                          ) {
                            return message.error(
                              `Du hast bereits eine aktive Bonusprämie für ${x.anzahlEmpfehlungen} Empfehlungen`
                            );
                          }

                          await CrudService.update("BonusPramie", x._id, {
                            disabled: false,
                          });
                          fetchBonuses();
                        }}
                      />
                    ) : (
                      <img
                        src="/images/img_info_green.svg"
                        alt="Info Icon"
                        className="h-[38px] mdx:h-[28px] rounded-lg cursor-pointer"
                        onClick={async () => {
                          setConfirmation({
                            action: "pramie-disable",
                            id: x._id,
                            text: "Mit der Deaktivierung dieser Prämie im Affiliate-Portal, werden die Nutzer sie dort nicht mehr sehen. ",
                          });
                        }}
                      />
                    )}
                  </LoadingDiv>

                  <img
                    onClick={() => {
                      setEditingBonus(x);
                      setOpenAddBonusPramieModal(true);
                    }}
                    className="h-[38px] mdx:h-[28px] w-[38px] rounded-lg cursor-pointer"
                    src="/images/img_edit.svg"
                  />

                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      setConfirmation({
                        action: "pramie-delete",
                        id: x._id,
                        text: "Die Löschung dieser Prämie im Affiliate-Portal ist nicht rückgängig ",
                      });
                    }}
                  >
                    <img
                      src="/images/img_info_white_0.svg"
                      alt="White Info Icon"
                      className="h-[38px] mdx:h-[28px] rounded-lg cursor-pointer"
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col items-start gap-1 justify-center self-stretch">
          <Button
            onClick={() => {
              setOpenAddBonusPramieModal(true);
              setEditingBonus(null);
            }}
            className="flex h-[70px] flex-row items-center justify-center self-stretch rounded-[10px] bg-[#0084f7] px-[34px] text-center text-[20px] smx:text-[18px] font-semibold text-[#ffffff] sm:px-5 mdx:text-[14px]"
          >
            Neue Bonusprämie
          </Button>
        </div>
      </div>

      <AddLandingPageModal
        open={openAddNewModal}
        onOpenChange={setOpenAddNewModal}
        handleRefreshPages={fetchLandingPages}
        editingLP={editingLP}
        setEditingLP={setEditingLP}
        landingPageThemes={landingPages}
        isEGTBonus
      />

      <AddBonusPramieModal
        open={openAddBonusPramieModal}
        onOpenChange={setOpenAddBonusPramieModal}
        handleRefresh={fetchBonuses}
        editingBonus={editingBonus}
        setEditingBonus={setEditingBonus}
        isEGTBonus
      />

      <Modal
        open={confirmation}
        onCancel={() => {
          setConfirmation(false);
          setCheckboxValue(false);
        }}
        title="ACHTUNG!"
        footer={[]}
      >
        <div>
          <p className="my-1">{confirmation?.text}</p>
          <div className="flex items-start justify-start tertiary:gap-4 gap-1 mt-4 mb-4">
            <Checkbox
              checked={checkboxValue}
              onChange={(e) => setCheckboxValue(e.target.checked)}
            />
            <p className="text-ellipsis text-start hyphens-auto ">
              {confirmation?.action === "new-bonus"
                ? "Ich habe verstanden"
                : "Ich bestätige, dass ich den Hinweis zur Kenntnis genommen habe und mir der Folgen bewusst bin"}
            </p>
          </div>
        </div>
        <div className="flex flex-col items-center justify-between w-full gap-1 sm:flex-row sm:flex-nowrap">
          <Button
            className="bg-redattendtion rounded-[10px] text-white h-[46px] w-[250px]"
            onClick={async () => {
              if (!checkboxValue)
                return message.info(
                  "Bitte bestätige, dass du den Hinweis zur Kenntnis genommen hast"
                );
              setConfirmation(false);
              setCheckboxValue(false);

              if (confirmation.action === "delete") {
                await PromotionsLinkService.disableLandingPage(confirmation.id);
                await CrudService.delete("LinkKonfig", confirmation.id);
                fetchLandingPages();
              } else if (confirmation.action === "disable") {
                await PromotionsLinkService.disableLandingPage(confirmation.id);
                await CrudService.update("LinkKonfig", confirmation.id, {
                  disabled: true,
                });
                fetchLandingPages();
              } else if (confirmation.action === "pramie-disable") {
                await CrudService.update("BonusPramie", confirmation.id, {
                  disabled: true,
                });
                fetchBonuses();
              } else if (confirmation.action === "pramie-delete") {
                await CrudService.delete("BonusPramie", confirmation.id);
                fetchBonuses();
              } else if (confirmation.action === "new-bonus") {
                setOpenAddBonusPramieModal(true);
                setEditingBonus(null);
              }
            }}
          >
            {confirmation.action === "new-bonus"
              ? "Weiter"
              : confirmation.action === "delete"
              ? "Löschen"
              : "Deaktivieren"}
          </Button>
          <Button
            type="primary"
            className="h-[46px] w-[250px] rounded-[10px]"
            onClick={(e) => {
              setConfirmation(false);
              setCheckboxValue(false);
            }}
          >
            Abbrechen
          </Button>
        </div>
      </Modal>
    </div>
  );
}
