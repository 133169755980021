import { Spin, Tooltip } from "antd";
import { motion } from "framer-motion";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import CrudService from "../../service/CrudService";
import PromoterPublicService from "../../service/PromoterPublicService";
import PublicService from "../../service/PublicService";
import { useScreenWidth } from "../LandingPageConfig/useScreenWidth";
import { Button, Heading, Img, Text } from "./components";
import UserService from "../../service/UserService";

export default function WelcomeSection({
  promoter,
  refreshPromoter,
  user_id,
  alternativeHeading,
  alternativeSubheading,
  isEGTBonus,
  upperHeading,
  motionMXClass = "mx-2",
}) {
  const { token } = useParams();
  const [bonuses, setBonuses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [emptyBonus, setEmptyBonus] = useState(false);
  const { width } = useScreenWidth();
  const WIDTH_PER_BONUS = width < 767 ? 180 : 220;

  const claimedBonuses = promoter?.claims ?? []; // Array of indices of claimed bonuses (using index for now, will use _id later)

  const fetchBonuses = useCallback(async () => {
    if (user_id) {
      const result = await CrudService.search("BonusPramie", 10000000, 1, {
        filters: {
          [isEGTBonus ? "isEGTBonus" : "user_id"]: isEGTBonus ? true : user_id,
          $or: isEGTBonus
            ? undefined
            : [{ isEGTBonus: false }, { isEGTBonus: { $exists: false } }],
        },
      });
      const activeBonuses = result?.data?.items
        ?.filter((x) => !x.disabled)
        ?.sort((a, b) => a.anzahlEmpfehlungen - b.anzahlEmpfehlungen);
      setBonuses(activeBonuses || []);
      if (activeBonuses.length === 0) setEmptyBonus(true);

      return;
    }
    if (!promoter) return;
    const result = await PublicService.getPartnerBonuses(promoter?._id);

    const activeBonuses = result?.data?.items
      ?.filter((x) => !x.disabled)
      ?.sort((a, b) => a?.anzahlEmpfehlungen - b?.anzahlEmpfehlungen);
    setBonuses(activeBonuses || []);
    if (activeBonuses.length === 0) setEmptyBonus(true);
  }, [promoter, isEGTBonus]);
  useEffect(() => {
    fetchBonuses();
  }, [fetchBonuses]);

  useEffect(() => {
    if (bonuses?.length > 0) {
      const nextBonusIndex = bonuses.findIndex(
        (x) => x.anzahlEmpfehlungen > promoter?.anzahlEmpfehlungen
      );

      const scrollContainer = document.querySelector(
        ".document-scroll-container"
      );
      if (nextBonusIndex >= 0 && scrollContainer) {
        console.log(WIDTH_PER_BONUS, nextBonusIndex);
        scrollContainer.scrollBy({
          left: (nextBonusIndex - 1) * WIDTH_PER_BONUS + WIDTH_PER_BONUS * 0.1,
          behavior: "smooth",
        });
      }
    }
  }, [bonuses, promoter?.anzahlEmpfehlungen, WIDTH_PER_BONUS]);

  return (
    <div className="mt-1.5 flex flex-col items-center self-stretch">
      <div className="flex flex-col gap-6 container-xs mdx:px-2">
        {/* Header section */}
        <div className="flex flex-col items-start">
          {alternativeHeading ? (
            alternativeHeading
          ) : (
            <Heading
              size="headings"
              as="h1"
              className="!text-[20px] font-semibold text-[#000000]-900 px-2.5"
            >
              <span className="text-[#000000]-900">Hallo&nbsp;</span>
              <span className="text-cyan-a400">
                {promoter?.firstname} {promoter?.lastname}
              </span>
              , willkommen in deinem Empfehlungsportal
            </Heading>
          )}
          {alternativeSubheading ? (
            alternativeSubheading
          ) : (
            <Heading
              size="headingxs"
              as="h2"
              className="!text-[16px] font-medium text-light_blue-a700 px-2.5"
            >
              <span>
                Hier kannst Du {promoter?.user_id?.firstname}{" "}
                {promoter?.user_id?.lastname} weiterempfehlen und gleichzeitig
                profitieren!
              </span>
            </Heading>
          )}
        </div>

        {/* Bonus section */}
        {!emptyBonus && (
          <motion.div
            className={`relative p-1 ${motionMXClass} rounded-[15px]`}
            style={{
              background: "linear-gradient(135deg, #0084F7, #00DEF7)",
              backgroundSize: "100% 100%",
            }}
            animate={{
              background: [
                "linear-gradient(135deg, #0084F7, #00DEF7)",
                "linear-gradient(315deg, #0084F7, #00DEF7)",
                "linear-gradient(135deg, #0084F7, #00DEF7)",
              ],
            }}
            transition={{
              duration: 4,
              repeat: Infinity,
              ease: "linear",
            }}
          >
            <div className="bonusrow_border rounded-[14px] bg-[#fafafa] p-2 py-1 mdx:p-1 overflow-hidden">
              {upperHeading ?? (
                <Heading
                  size="headings"
                  as="h3"
                  className="!text-[16px] smx:!text-[15px] font-semibold text-[#000000]-900 mt-4 mdx:text-center"
                >
                  <span className="text-[#000000]-900">
                    Sichere dir einen zusätzlichen&nbsp;
                  </span>
                  <span className="text-cyan-a400 break-words">
                    Extra Bonus
                  </span>{" "}
                  nach Anzahl erfolgreicher Empfehlungen
                </Heading>
              )}

              {bonuses.find(
                (b) => b?.anzahlEmpfehlungen > promoter?.anzahlEmpfehlungen
              ) && (
                <Text
                  as="p"
                  className="text-[14px] smx:!text-[12px] left-[20px] smx:left-[10px] !font-bold text-black-900 absolute whitespace-nowrap max-w-[71vw] truncate "
                  style={{
                    bottom: 20,
                  }}
                >
                  <span className="text-black-900">Nur noch&nbsp;</span>
                  <span className="text-light_blue-a700">
                    {bonuses.find(
                      (b) =>
                        b?.anzahlEmpfehlungen > promoter?.anzahlEmpfehlungen
                    )?.anzahlEmpfehlungen - promoter?.anzahlEmpfehlungen}{" "}
                    Empfehlungen
                  </span>
                  <span className="text-black-900 w-[150px] truncate">
                    &nbsp;bis “
                    {
                      bonuses.find(
                        (b) =>
                          b?.anzahlEmpfehlungen > promoter?.anzahlEmpfehlungen
                      )?.name
                    }
                    ”
                  </span>
                </Text>
              )}

              <div className="overflow-x-auto mt-2 document-scroll-container">
                <div
                  className={`pb-32 relative `}
                  style={{
                    width: bonuses.length * WIDTH_PER_BONUS,
                    height: width < 767 ? 190 : 220,
                  }}
                >
                  {/* Progress bar */}
                  <div className="h-6 w-full bg-light_blue-a700_19 rounded-full mt-5 relative">
                    <div
                      className="h-full bg-light_blue-a700 rounded-full transition-all duration-500"
                      style={{
                        width: `${Math.min(
                          100,
                          Math.max(
                            3,
                            ((bonuses.findIndex(
                              (x) =>
                                x.anzahlEmpfehlungen >
                                promoter?.anzahlEmpfehlungen
                            ) >= 0
                              ? bonuses.findIndex(
                                  (x) =>
                                    x.anzahlEmpfehlungen >
                                    promoter?.anzahlEmpfehlungen
                                )
                              : bonuses.length) /
                              (bonuses.length ?? 1)) *
                              100 +
                              (bonuses?.[
                                bonuses.findIndex(
                                  (x) =>
                                    x.anzahlEmpfehlungen >
                                    promoter?.anzahlEmpfehlungen
                                )
                              ] &&
                              bonuses?.[
                                bonuses.findIndex(
                                  (x) =>
                                    x.anzahlEmpfehlungen >
                                    promoter?.anzahlEmpfehlungen
                                ) - 1
                              ]
                                ? Math.max(
                                    0,
                                    (((promoter?.anzahlEmpfehlungen -
                                      bonuses?.[
                                        bonuses.findIndex(
                                          (x) =>
                                            x.anzahlEmpfehlungen >
                                            promoter?.anzahlEmpfehlungen
                                        ) - 1
                                      ]?.anzahlEmpfehlungen) /
                                      (bonuses?.[
                                        bonuses.findIndex(
                                          (x) =>
                                            x.anzahlEmpfehlungen >
                                            promoter?.anzahlEmpfehlungen
                                        )
                                      ]?.anzahlEmpfehlungen -
                                        bonuses?.[
                                          bonuses.findIndex(
                                            (x) =>
                                              x.anzahlEmpfehlungen >
                                              promoter?.anzahlEmpfehlungen
                                          ) - 1
                                        ]?.anzahlEmpfehlungen)) *
                                      100) /
                                      (bonuses?.length ?? 1)
                                  )
                                : 0) +
                              (!bonuses.find(
                                (x) =>
                                  promoter?.anzahlEmpfehlungen >=
                                  x.anzahlEmpfehlungen
                              )
                                ? Math.max(
                                    0,
                                    ((promoter?.anzahlEmpfehlungen /
                                      bonuses?.[0]?.anzahlEmpfehlungen) *
                                      100) /
                                      (bonuses?.length ?? 1)
                                  )
                                : 0)
                          )
                        )}%`,
                      }}
                    />
                  </div>

                  {/* Bonus markers */}
                  <div className="relative">
                    {bonuses.map((bonus, index) => (
                      <div
                        key={bonus._id}
                        className="absolute -top-8 "
                        style={{
                          left: `${
                            ((bonuses.findIndex((x) => x._id === bonus?._id) +
                              1) /
                              (bonuses.length ?? 1)) *
                            100
                          }%`,
                          transform: "translateX(-50%)",
                        }}
                      >
                        <div className="flex flex-col items-center w-[180px]">
                          <div
                            className={`w-10 h-10 mb-2 rounded-full ${
                              promoter?.anzahlEmpfehlungen >=
                                bonus?.anzahlEmpfehlungen ||
                              claimedBonuses?.some?.(
                                (b) => b.bonus_id === bonus._id
                              )
                                ? "bg-cyan-a400"
                                : "bg-light_blue-a700"
                            } shadow-xs border-white flex items-center justify-center text-white font-semibold`}
                            style={{
                              borderWidth:
                                promoter?.anzahlEmpfehlungen >=
                                  bonus?.anzahlEmpfehlungen ||
                                claimedBonuses?.some?.(
                                  (b) => b.bonus_id === bonus._id
                                )
                                  ? 2
                                  : 6,
                            }}
                          >
                            {promoter?.anzahlEmpfehlungen >=
                              bonus?.anzahlEmpfehlungen ||
                            claimedBonuses?.some?.(
                              (b) => b.bonus_id === bonus._id
                            ) ? (
                              <Img src="/images3/img_settings.svg" />
                            ) : (
                              <>{bonus?.anzahlEmpfehlungen}</>
                            )}
                          </div>
                          {bonus.icon && (
                            <div className="relative top-4">
                              <Img
                                src={bonus.icon}
                                alt={bonus.name}
                                style={{
                                  zIndex: -1,
                                  // width: width < 767 ? 90 : 130,
                                  height: width < 767 ? 80 : 120,
                                }}
                                className={`object-contain -mb-6 -top-6 relative`}
                              />

                              <div
                                className={`absolute  -top-8 h-40 w-40 content-center mdx:h-auto smx:w-full`}
                                style={{ zIndex: -2, left: "-2rem" }}
                              >
                                <div className="flex-1 mx-auto">
                                  <div className="flex flex-col items-end">
                                    <Img
                                      src="/images3/img_morflax_studio_5_28x30.png"
                                      alt="Studio Image Small"
                                      className="mr-4 h-[28px] object-cover mdx:mr-0"
                                    />
                                    <div className="relative mt-[-18px] flex items-center justify-end self-stretch">
                                      <div className="flex items-center justify-end flex-1">
                                        <Img
                                          src="/images3/img_morflax_studio_7.png"
                                          alt="Studio Image 7 Small"
                                          className="h-[62px] w-[34%] self-end object-contain"
                                        />
                                      </div>
                                      <Img
                                        src="/images3/img_morflax_studio_5.png"
                                        alt="Studio Image Small 2"
                                        className="relative mb-1 ml-[-22px] h-[46px] w-[22%] self-end object-contain"
                                      />
                                    </div>
                                  </div>
                                  <div className="relative mx-2 mt-[-22px] flex flex-col items-end mdx:mx-0">
                                    <Img
                                      src="/images3/img_morflax_studio_5_58x66.png"
                                      alt="Iphone Image Button"
                                      className="mr-9 h-[36px] object-cover mdx:mr-0"
                                    />
                                  </div>
                                </div>
                                <Img
                                  src="/images3/img_morflax_studio_6.png"
                                  alt="Studio Image 6"
                                  className="absolute left-[36%] top-[7px] m-auto h-[34px] object-cover"
                                />
                              </div>
                            </div>
                          )}

                          {claimedBonuses?.some?.(
                            (b) => b.bonus_id === bonus._id
                          ) ? (
                            <Button
                              color="cyan_A400"
                              shape="round"
                              className={`text-[${
                                width < 767 ? 12 : 16
                              }px] text-white gap-1.5 mb-2  rounded-[5px] px-2 py-2 !font-bold`}
                              size={width < 767 ? "smx" : null}
                            >
                              Bereits beantragt
                            </Button>
                          ) : promoter?.anzahlEmpfehlungen >=
                            bonus?.anzahlEmpfehlungen ? (
                            <Button
                              color="light_blue_A700"
                              shape="round"
                              className="relative z-20  rounded-md bg-[#0080ff] px-2 py-2 text-sm font-normal text-white transition-all hover:bg-[#0066cc] hover:shadow-lg before:absolute before:inset-0 before:-z-10 before:block before:rounded-md before:bg-[#0080ff]/50 before:blur-lg"
                              disabled={loading}
                              size={width < 767 ? "smx" : null}
                              style={{ zIndex: 50 }}
                              onClick={async () => {
                                if (!refreshPromoter) return;
                                if (loading) return;
                                setLoading(true);
                                try {
                                  if (isEGTBonus) {
                                    await UserService.bonusClaim({
                                      token,
                                      bonus: bonus._id,
                                    });
                                  } else {
                                    await PromoterPublicService.bonusClaim({
                                      token,
                                      bonus: bonus._id,
                                    });
                                  }
                                  await refreshPromoter();
                                } catch (e) {
                                } finally {
                                  setLoading(false);
                                }
                              }}
                            >
                              {loading ? (
                                <Spin>Jetzt Bonus sichern</Spin>
                              ) : (
                                "Jetzt Bonus sichern"
                              )}
                            </Button>
                          ) : (
                            <>
                              {bonus.description ? (
                                <Tooltip
                                  color="white"
                                  overlayInnerStyle={{ color: "black" }}
                                  title={bonus.description}
                                  className="flex items-center justify-center"
                                  overlayStyle={{
                                    border: "1px solid gray",
                                    borderRadius: 5,
                                  }}
                                >
                                  <Button
                                    color="blue_gray_100"
                                    shape="round"
                                    size={width < 767 ? "smx" : null}
                                    style={{ zIndex: 999 }}
                                    rightIcon={
                                      <Img
                                        src="/images3/img_inbox.svg"
                                        alt="Inbox"
                                        className="h-[14px] w-[14px]"
                                      />
                                    }
                                    className={`text-[${
                                      width < 767 ? 12 : 16
                                    }px] gap-1.5 mb-2  rounded-[5px] px-2 font-semibold`}
                                  >
                                    {bonus.name}
                                  </Button>
                                </Tooltip>
                              ) : (
                                <Button
                                  color="blue_gray_100"
                                  shape="round"
                                  size={width < 767 ? "smx" : null}
                                  className={`text-[${
                                    width < 767 ? 12 : 16
                                  }px] gap-1.5 mb-2  rounded-[5px] px-2 font-semibold`}
                                >
                                  {bonus.name}
                                </Button>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </div>
    </div>
  );
}
