import { useEffect, useState } from "react";
import { Button } from "antd";
import { MobileOutlined, DesktopOutlined } from "@ant-design/icons";

// Globaler Zustand für den Ansichtsmodus
let globalIsMobileView = false;
let listeners = [];

const setGlobalIsMobileView = (value) => {
  globalIsMobileView = value;
  listeners.forEach((listener) => listener(value));
  document.documentElement.classList.toggle("mobile-view", value);
};

const useIsDesktopDevice = () => {
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 1024px)");
    setIsDesktop(mediaQuery.matches);

    const handleChange = (e) => {
      setIsDesktop(e.matches);
    };

    mediaQuery.addEventListener("change", handleChange);
    return () => mediaQuery.removeEventListener("change", handleChange);
  }, []);

  return isDesktop;
};

export const useIsMobileView = () => {
  const [isMobileView, setIsMobileView] = useState(globalIsMobileView);

  useEffect(() => {
    const handleChange = (newValue) => setIsMobileView(newValue);
    listeners.push(handleChange);
    return () => {
      listeners = listeners.filter((listener) => listener !== handleChange);
    };
  }, []);

  return isMobileView;
};

const ViewToggle = () => {
  const [isMobileView, setIsMobileView] = useState(globalIsMobileView);
  const isDesktopDevice = useIsDesktopDevice();

  const toggleView = () => {
    if (!isDesktopDevice) return;
    const newValue = !isMobileView;
    setIsMobileView(newValue);
    setGlobalIsMobileView(newValue);
  };

  return (
    <Button
      onClick={toggleView}
      disabled={!isDesktopDevice}
      shape="circle"
      className={`fixed top-32 right-4 z-50 transition-colors 
    ${
      !isDesktopDevice
        ? "bg-red-100 dark:bg-red-900"
        : "bg-gray-100 dark:bg-gray-800"
    } 
    hover:bg-gray-200 dark:hover:bg-gray-700`}
      style={{
        fontSize: isMobileView ? "0.875rem" : "1rem",
        color: !isDesktopDevice ? "#7f1d1d" : undefined,
        cursor: !isDesktopDevice ? "not-allowed" : "pointer",
      }}
      aria-label="Ansichtsmodus umschalten"
      icon={
        isMobileView ? (
          <DesktopOutlined
            style={{ color: !isDesktopDevice ? "#fff" : undefined }}
          />
        ) : (
          <MobileOutlined
            style={{ color: !isDesktopDevice ? "#fff" : undefined }}
          />
        )
      }
    />
  );
};

export default ViewToggle;
