import React, { useMemo, useEffect } from "react";
import { Avatar, Rate } from "antd";
import { motion } from "framer-motion";
import EditableText from "../../shared/EditableField";
import VideoWithThumbnail from "../../../Shared/VideoThumbnail";
import { useLandingPage } from "../../../../context/LandingPageContext";
import { useIsMobileView } from "../../../Shared/ViewToggle";
import useThemeColor from "../../../../hooks/useThemeColor";
import XYZBannerSection from "./XYZBannerSection";
import EditableButton from "../../shared/EditableButton";
import { fontMapping } from "../../shared/fontMapping";

const XYZHeader = () => {
  const { templateData, setTemplateData, landingPageData } = useLandingPage();
  const { headerSection } = templateData;
  const [color] = useThemeColor();
  const isMobile = useIsMobileView();

  const sectionVariants = {
    hidden: { opacity: 0, y: 30 },
    visible: { opacity: 1, y: 0 },
  };

  const avatarUrls = useMemo(() => {
    return Array(5)
      .fill(0)
      .map((_, index) => `/ReviewPeople/${index + 1}.jfif`);
  }, []);

  return (
    <motion.section
      className="w-full bg-[#E0E9F1] pt-8 md:py-12 md:px-8 px-4 override-font"
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.3 }}
      transition={{ duration: 0.8 }}
      variants={sectionVariants}
      style={{
        "--font-family": fontMapping[templateData.font] || fontMapping.modern,
      }}
    >
      <div className="text-center flex flex-col items-center gap-4">
        <img src={landingPageData.logoSrc} alt="logo" className="h-[60px]" />
        <div
          className={`bg-white dark:bg-slate-800 rounded-2xl py-8 ${
            isMobile ? "px-6" : "px-6 md:py-12 md:px-32 lg:px-56"
          } flex flex-col items-center gap-8 max-w-full`}
        >
          <EditableText
            text={headerSection.header}
            maxLines={2}
            maxChars={200}
            onSave={(newValue) =>
              setTemplateData((prev) => ({
                ...prev,
                headerSection: {
                  ...prev.headerSection,
                  header: newValue,
                },
              }))
            }
            element="h1"
            className={`font-bold ${
              isMobile ? "text-2xl px-4" : "text-4xl md:text-6xl md:px-0"
            } group relative`}
          />

          <EditableText
            text={headerSection.subheader}
            maxLines={3}
            maxChars={200}
            onSave={(newValue) =>
              setTemplateData((prev) => ({
                ...prev,
                headerSection: {
                  ...prev.headerSection,
                  subheader: newValue,
                },
              }))
            }
            element="p"
            className="text-slate-600 dark:text-slate-300 max-w-3xl mx-auto group relative text-sm md:text-xl"
          />

          <EditableButton
            text={templateData.buttonOne}
            onSave={(newValue) =>
              setTemplateData((prev) => ({
                ...prev,
                buttonOne: newValue,
              }))
            }
            className={`text-white ${
              isMobile
                ? "px-8 py-4 md:px-8 md:py-4"
                : "px-8 py-4 md:px-8 md:py-4 md:text-xl shadow-2xl"
            } rounded-full hover:opacity-90 transition-opacity text-xl relative`}
            style={{
              background: color,
              boxShadow: `inset 2px 2px 5px rgba(255, 255, 255, 0.4), 
                inset -2px -2px 5px rgba(0, 0, 0, 0.2),
                4px 4px 10px rgba(0, 0, 0, 0.3)`,
            }}
          />

          <div className="flex flex-row items-center">
            <div className="flex">
              <Avatar.Group>
                {avatarUrls.map((url, index) => (
                  <Avatar
                    key={index}
                    src={url}
                    style={{
                      width: "2.8rem", // 5rem entspricht z. B. 80px bei 16px Root-Fontgröße
                      height: "2.8rem",
                      fontSize: "2.8rem", // Falls Initialen genutzt werden
                      backgroundColor: "#f0f0f0",
                      marginLeft: index === 0 ? "0" : "-15px",
                    }}
                  />
                ))}
              </Avatar.Group>
            </div>
            <div className="flex items-start flex-col gap-2">
              <div className="flex">
                <Rate
                  allowHalf
                  count={5}
                  style={{ fontSize: "1.2rem" }}
                  value={templateData.rating?.stars || 0}
                  onChange={(value) =>
                    setTemplateData((prev) => ({
                      ...prev,
                      rating: {
                        ...prev.rating,
                        stars: value,
                      },
                    }))
                  }
                />
              </div>
              {/* Hier EditableText für rating.text */}
              <span className="ml-2 text-md inline-flex justify-start">
                <EditableText
                  text={templateData.rating?.text}
                  maxLines={1}
                  maxChars={50}
                  onSave={(newValue) =>
                    setTemplateData((prev) => ({
                      ...prev,
                      rating: {
                        ...prev.rating,
                        text: newValue,
                      },
                    }))
                  }
                  element="span"
                />
              </span>
            </div>
          </div>

          <VideoWithThumbnail
            thumbnailSrc={landingPageData.thumbnail}
            videoSrc={landingPageData.presentationVideo}
          />
          <XYZBannerSection />
        </div>
      </div>
    </motion.section>
  );
};

export default XYZHeader;
