import axios from "axios";
import { getBackendUrl } from "./getBackendUrl";
import { middleField } from "./middlefield";

class AdminService {
  constructor() {
    this.baseURL = `${getBackendUrl()}/admin`;
    this.api = axios.create({
      baseURL: this.baseURL,
    });
    middleField(this.api);
  }

  getAdminNotificationConfig() {
    return this.api.get("/getAdminNotificationConfig");
  }
  getTutorials() {
    return this.api.get("/getTutorials");
  }

  listUsers(data) {
    return this.api.post("/listUsers", data);
  }
  listRedeems({ user_id }) {
    return this.api.get(`/listRedeems?user_id=${user_id}`);
  }
  userSearch({ text }) {
    return this.api.post(`/userSearch`, { text });
  }
  userDetails({ user_id }) {
    return this.api.get(`/userDetails?user_id=${user_id}`);
  }
  updateUserDetails({ user_id, data }) {
    return this.api.put(`/updateUserDetails?user_id=${user_id}`, data);
  }

  getAccessLevelOfUser(user_id) {
    return this.api.get(`/getAccessLevelOfUser?user_id=${user_id}`);
  }

  updateAccessLevelOfUser(user_id, data) {
    return this.api.put(`/updateAccessLevelOfUser?user_id=${user_id}`, data);
  }

  listSupportRequestsOfUser(user_id) {
    return this.api.get(`/listSupportRequestsOfUser?user_id=${user_id}`);
  }

  getSupportTickets(show_old) {
    return this.api.get(`/getSupportTickets?show_old=${show_old}`);
  }

  searchSupportTickets(param) {
    return this.api.post(`/searchSupportTickets`, param);
  }

  changeSupportTicketStatus(param) {
    return this.api.post(`/changeSupportTicketStatus`, param);
  }

  updateSupportTicket(ticket_id, data) {
    return this.api.put(`/updateSupportTicket?ticket_id=${ticket_id}`, data);
  }

  getAdminSetting() {
    return this.api.get(`/getAdminSetting`);
  }

  setAdminSetting(data) {
    return this.api.post(`/setAdminSetting`, data);
  }

  listNotificationsOfUser(user_id) {
    return this.api.get(`/listNotificationsOfUser?user_id=${user_id}`);
  }

  getNotesOfUser(user_id) {
    return this.api.get(`/getNotesOfUser?user_id=${user_id}`);
  }

  updateNotesOfUser(user_id, data) {
    return this.api.put(`/updateNotesOfUser?user_id=${user_id}`, data);
  }

  deactivateUserAccount(user_id) {
    return this.api.delete(`/deactivateUserAccount?user_id=${user_id}`);
  }
  deleteUserAccount(user_id) {
    return this.api.delete(`/deleteUserAccount?user_id=${user_id}`);
  }

  activateUserAccount(user_id) {
    return this.api.post(`/activateUserAccount?user_id=${user_id}`);
  }

  manuallySendResetPasswordLink(user_id) {
    return this.api.post(`/manuallySendResetPasswordLink?user_id=${user_id}`);
  }

  getUserStats(user_id) {
    return this.api.get(`/getUserStats?user_id=${user_id}`);
  }

  sendManualNotification(user_id, type, text) {
    return this.api.post(`/sendManualNotification?user_id=${user_id}`, {
      type,
      text,
    });
  }
  sendMessageToPartner({ partners, subject, message }) {
    return this.api.post("/sendMessageToPartner", {
      partners,
      subject,
      message,
    });
  }

  getAdminConfiguration() {
    return this.api.get("/getAdminConfiguration");
  }

  setAdminConfiguration(data) {
    return this.api.post("/setAdminConfiguration", data);
  }

  listSubscriptionPackages() {
    return this.api.get("/listSubscriptionPackages");
  }

  createSubscription(data) {
    return this.api.post("/createSubscription", data);
  }

  updateSubscription(id, data) {
    return this.api.put(`/updateSubscription?id=${id}`, data);
  }

  /**
   * timeFilter: 7days lastmonth 3months lastyear alltime
   */
  getDashboardStats(timeFilter) {
    return this.api.get(`/getDashboardStats?timeFilter=${timeFilter}`);
  }

  createTutorial(data) {
    return this.api.post("/createTutorial", data);
  }

  updateTutorial(id, data) {
    return this.api.put(`/updateTutorial?id=${id}`, data);
  }

  deleteTutorial(id) {
    return this.api.delete(`/deleteTutorial?id=${id}`);
  }

  updatePrivacy({ privacyPolicy }) {
    return this.api.put("/updatePrivacy", { privacyPolicy });
  }

  updateImpressum({ impressum }) {
    return this.api.put("/updateImpressum", { impressum });
  }

  updateTerms({ terms }) {
    return this.api.put("/updateTerms", { terms });
  }

  updateAppNotificationSettings(data) {
    return this.api.put("/updateAppNotificationSettings", data);
  }

  getAppNotificationSettings() {
    return this.api.get("/getAppNotificationSettings");
  }

  sendNotification(data) {
    return this.api.post("/sendNotification", data);
  }

  /**
   * timeFilter: 7days lastmonth 3months lastyear alltime
   */
  getPartnerLeaderboard(timeFilter) {
    return this.api.get(`/getPartnerLeaderboard?timeFilter=${timeFilter}`);
  }

  getAvailableCadoozExports() {
    return this.api.get("/getAvailableCadoozExports");
  }
  getUserSubscriptionInfo(id) {
    return this.api.get(`/getUserSubscriptionInfo?id=${id}`);
  }

  exportCadooz({ calendarWeek, calendarYear }) {
    return this.api.post("/exportCadooz", { calendarWeek, calendarYear });
  }

  /**/
  changeRanking({ oldIdx, newIdx }) {
    return this.api.post("/changeranking", { oldIdx, newIdx });
  }

  getLeads() {
    return this.api.get("/getLeads");
  }
  listInteressenten() {
    return this.api.get("/listInteressenten");
  }
  importLead(leads) {
    return this.api.post("/importLead", { leads });
  }
  sendMessageToLead({ leads, subject, message, silent = false }) {
    return this.api.post("/sendMessageToLead", {
      leads,
      subject,
      message,
      silent,
    });
  }
  deleteLead(id) {
    return this.api.delete(`/deleteLead?id=${id}`);
  }

  updateLeadDetails({ id, data }) {
    return this.api.put(`/updateLeadDetails?id=${id}`, data);
  }

  interessentUpdate(id, data) {
    return this.api.put(`/interessentUpdate?id=${id}`, {
      ...data,
      baseUrl: window.location.origin,
    });
  }
  interessentUpdateNotes(id, data) {
    return this.api.put(`/interessentUpdateNotes?id=${id}`, data);
  }
  interessentDelete(id) {
    return this.api.delete(`/interessentDelete?id=${id}`);
  }
  listAffiliateClaims() {
    return this.api.get(`/listAffiliateClaims`);
  }
  answerRequestBonus(id, data) {
    return this.api.post(`/answerRequestBonus?id=${id}`, data);
  }
}

export default new AdminService();
