import React from "react";
import { useIsMobileView } from "../../Shared/ViewToggle";
import { useLandingPage } from "../../../context/LandingPageContext";
import "./TemplateXYZ.css";
import LoadingScreen from "../../../../_NEW/LandingPage/loadingScreen/LoadingScreen";
import XYZHeader from "./templateXyz/XYZHeader";
import XYZCardSection from "./templateXyz/XYZCardSection";
import XYZAboutSection from "./templateXyz/XYZAboutSection";
import XYZPictureSection from "./templateXyz/XYZPictureSection";
import LocalReviewSection from "./shared/LocalReviewSection";
import EditableButton from "../shared/EditableButton";
import useThemeColor from "../../../hooks/useThemeColor";
import XYZFAQSection from "./templateXyz/XYZFAQSection";
import XYZPromoSection from "./templateXyz/XYZPromoSection";

const TemplateXYZ = () => {
  const isMobile = useIsMobileView();
  const { landingPageData, templateData, setTemplateData } = useLandingPage();
  const [color] = useThemeColor();

  if (!landingPageData || !templateData) {
    return <LoadingScreen />;
  }

  return (
    <div className={`w-[100vw] ${isMobile ? "max-w-sm" : ""}`}>
      {/* Header Section */}
      <XYZHeader />
      {/* Statisitic Cards */}
      <XYZCardSection />
      {/* Features Section*/}
      <XYZAboutSection />
      {/* Sektion 4 - Schutz */}
      <XYZPictureSection />
      {/* Sektion 5 - Kundenstimmen */}
      <LocalReviewSection />
      <div className="flex items-center justify-center w-full py-4 dark:!bg-slate-600 !bg-[#E0E9F1]">
        <EditableButton
          text={templateData.buttonThree}
          onSave={(newValue) =>
            setTemplateData((prev) => ({
              ...prev,
              buttonThree: newValue,
            }))
          }
          className={`text-white ${
            isMobile
              ? "px-8 py-4 md:px-8 md:py-4"
              : "px-8 py-4 md:px-8 md:py-4 md:text-xl shadow-2xl"
          } rounded-full hover:opacity-90 transition-opacity text-xl relative`}
          style={{
            background: color,
            boxShadow: `inset 2px 2px 5px rgba(255, 255, 255, 0.4), 
      inset -2px -2px 5px rgba(0, 0, 0, 0.2),
      4px 4px 10px rgba(0, 0, 0, 0.3)`,
          }}
        />
      </div>
      {/* Sektion 6 - FAQ */}
      <XYZFAQSection />
      {/* Sektion 7 - PROMO */}
      <XYZPromoSection />
    </div>
  );
};

export default TemplateXYZ;
