import { useEffect, useState } from "react";
import { Button } from "antd";
import { QuestionCircleOutlined, CloseOutlined } from "@ant-design/icons";

// Globaler Zustand für den Frage-Modus
let globalIsQuestionMode = false;
let questionListeners = [];

const setGlobalIsQuestionMode = (value) => {
  globalIsQuestionMode = value;
  questionListeners.forEach((listener) => listener(value));
};

export const useIsQuestionMode = () => {
  const [isQuestionMode, setIsQuestionMode] = useState(globalIsQuestionMode);

  useEffect(() => {
    const handleChange = (newValue) => setIsQuestionMode(newValue);
    questionListeners.push(handleChange);
    return () => {
      questionListeners = questionListeners.filter(
        (listener) => listener !== handleChange
      );
    };
  }, []);

  return isQuestionMode;
};

const QuestionToggle = () => {
  const [isQuestionMode, setIsQuestionMode] = useState(globalIsQuestionMode);

  const toggleQuestionMode = () => {
    const newValue = !isQuestionMode;
    setIsQuestionMode(newValue);
    setGlobalIsQuestionMode(newValue);
  };

  return (
    <Button
      onClick={toggleQuestionMode}
      shape="circle"
      className="fixed top-44 right-4 z-50 transition-colors bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700"
      style={{ fontSize: "1rem" }}
      aria-label="Fragemodus umschalten"
      icon={isQuestionMode ? <CloseOutlined /> : <QuestionCircleOutlined />}
    />
  );
};

export default QuestionToggle;
