import { Button, Select, Skeleton, message } from "antd";
import classNames from "classnames";
import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { MainLogo } from "../../../assets/Svgs/GUTERTIPP";
import PromoterService from "../../../service/PromoterService";
import PublicService from "../../../service/PublicService";
import LoadingScreen from "../../_NEW/LandingPage/loadingScreen/LoadingScreen";
import FloatLabel from "../../FloatingLabel";
import Footer from "../../Footer/Index";
import FormComponent from "../../LandingPageConfig/FormComponent";
import LoadingButton from "../../LoadingButton/LoadingButton";
import MovingHearts from "../../MovingHearts/MovingHearts";

const styles = {
  modelBg: "rgba(250, 250, 250, 0.30)",
  formBgOne: "transparent",
  formBgTwo: "white",
  font: "Manrope",
  rounded: true,
  buttonbg: "#001D47",
};
const PromoterRegistration = () => {
  const { id } = useParams();
  const [user, setUser] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [acceptAGB, setAcceptAGB] = useState(false);
  const [form, setForm] = useState({
    anrede: "",
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
  });
  React.useEffect(() => {
    const getUserName = async () => {
      try {
        const { data } = await PublicService.getUserName(id);
        setUser(data);
      } catch (error) {
        console.log(error);
      }
    };
    getUserName();
  }, [id]);

  const handleChange = (e) => {
    const value = e.target ? e.target.value : e;
    const name = e.target ? e.target.name : "anrede";
    setForm((prev) => ({ ...prev, [name]: value }));
  };
  const [acceptPrivacy, setAcceptPrivacy] = useState(false);
  const [alreadyClicked, setAlreadyClicked] = useState(false);
  const handleSubmit = async () => {
    try {
      for (let i = 0; i < Object.keys(form).length; i++) {
        const key = Object.keys(form)[i];
        if (form[key].length === 0 && key !== "lastname") {
          message.error(
            `${key.replace(key[0], key[0].toUpperCase())} erforderlich`
          );
          return;
        }
      }
      setAlreadyClicked(true);
      await PromoterService.create({ ...form, userId: id });
      setSubmitted(true);
      setForm({
        anrede: "",
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
      });
      message.success(
        "Vielen Dank für Deine Registrierung! In Kürze erhältst Du eine E-Mail."
      );
    } catch (error) {}
    setAlreadyClicked(false);
  };
  const getGermanPlaceholder = (field, form) => {
    return {
      anrede: "Anrede",
      firstname: form?.anrede === "Firma" ? "Firmenname" : "Vorname",
      lastname: form?.anrede === "Firma" ? "Rechtsform" : "Nachname",
      email: "Email",
      phone: "Telefon",
    }[field];
  };

  if (!user) {
    return <LoadingScreen />;
  }
  return (
    <div className="overflow-auto py-5 w-full max-w-[2000px] m-auto">
      <div className="w-fit m-auto">
        <Link to={"/"} className="block">
          <div className="w-[200px] min-w-[200px] max-w-[200px]">
            <MainLogo className="brightness-125 mx-auto transition-all !w-[150px] !min-w-[157px]" />
            <p className="font-bold">
              <p className="whitespace-nowrap">…denn etwas gutes kann</p>
              <p className="whitespace-nowrap">man weiterempfehlen!</p>
            </p>
          </div>
        </Link>
      </div>
      <div className="flex items-center sm:w-8/12 m-auto">
        <div className="relative w-[200px] h-full">
          <img
            src="/images/promoregh1.png"
            alt=""
            className="w-[95px] absolute top-[-90px]"
          />
          <img
            src="/images/promoregh2.png"
            alt=""
            className="w-[95px] absolute top-[-50px] left-[-50px]"
          />
          <img
            src="/images/promoregh3.png"
            alt=""
            className="w-[95px] absolute left-[20px]"
          />
        </div>
        <div className="my-16  w-fit mx-auto text-[#000000] relative xl:text-primary text-responsiveHeading font-bold text-center">
          Du möchtest{" "}
          <span className="text-trkis whitespace-nowrap">
            {user ? (
              `${user?.firstname + " " + user?.lastname}`
            ) : (
              <Skeleton active />
            )}
          </span>{" "}
          <br />
          weiterempfehlen? So gehts:
        </div>
        <div className="relative w-[200px] h-full">
          <img
            src="/images/promoregh4.png"
            alt=""
            className="w-[95px] absolute top-[-90px]"
          />
          <img
            src="/images/promoregh5.png"
            alt=""
            className="w-[95px] absolute top-[-50px] right-[0px]"
          />
          <img
            src="/images/promoregh6.png"
            alt=""
            className="w-[95px] absolute left-[20px]"
          />
          {/* rightarrow.png, downArrow.png*/}
        </div>
      </div>
      <div className="flex xl:flex-row w-10/12 m-auto flex-col items-center xl:gap-6 gap-[45px]  justify-center ">
        <div className="promo-reg-cards promo-reg-cards-1">
          <div className="promo-reg-hooks">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="40"
              viewBox="0 0 30 40"
              fill="none"
            >
              <path
                d="M21.6667 0.5C22.843 0.903296 23.9134 1.56637 24.7983 2.44L27.5583 5.20333C28.433 6.08715 29.0967 7.1571 29.5 8.33333H21.6667V0.5ZM18.3333 20H11.6667C11.2246 20 10.8007 20.1756 10.4882 20.4882C10.1756 20.8007 10 21.2246 10 21.6667C10 22.1087 10.1756 22.5326 10.4882 22.8452C10.8007 23.1577 11.2246 23.3333 11.6667 23.3333H18.3333C18.7754 23.3333 19.1993 23.1577 19.5118 22.8452C19.8244 22.5326 20 22.1087 20 21.6667C20 21.2246 19.8244 20.8007 19.5118 20.4882C19.1993 20.1756 18.7754 20 18.3333 20ZM21.6667 11.6667C20.7826 11.6667 19.9348 11.3155 19.3096 10.6904C18.6845 10.0652 18.3333 9.21739 18.3333 8.33333V0H8.33333C6.12401 0.00264643 4.00593 0.88147 2.4437 2.4437C0.88147 4.00593 0.00264643 6.12401 0 8.33333V31.6667C0.00264643 33.876 0.88147 35.9941 2.4437 37.5563C4.00593 39.1185 6.12401 39.9974 8.33333 40H21.6667C23.876 39.9974 25.9941 39.1185 27.5563 37.5563C29.1185 35.9941 29.9974 33.876 30 31.6667V11.6667H21.6667ZM21.6667 33.3333H8.33333C7.89131 33.3333 7.46738 33.1577 7.15482 32.8452C6.84226 32.5326 6.66667 32.1087 6.66667 31.6667C6.66667 31.2246 6.84226 30.8007 7.15482 30.4882C7.46738 30.1756 7.89131 30 8.33333 30H21.6667C22.1087 30 22.5326 30.1756 22.8452 30.4882C23.1577 30.8007 23.3333 31.2246 23.3333 31.6667C23.3333 32.1087 23.1577 32.5326 22.8452 32.8452C22.5326 33.1577 22.1087 33.3333 21.6667 33.3333ZM18.3333 26.6667H11.6667C10.3406 26.6667 9.06881 26.1399 8.13113 25.2022C7.19345 24.2645 6.66667 22.9927 6.66667 21.6667C6.66667 20.3406 7.19345 19.0688 8.13113 18.1311C9.06881 17.1934 10.3406 16.6667 11.6667 16.6667H18.3333C19.6594 16.6667 20.9312 17.1934 21.8689 18.1311C22.8065 19.0688 23.3333 20.3406 23.3333 21.6667C23.3333 22.9927 22.8065 24.2645 21.8689 25.2022C20.9312 26.1399 19.6594 26.6667 18.3333 26.6667Z"
                fill="#0084F7"
              />
            </svg>
          </div>
          Im unten stehenden Formular kostenlos & unverbindlich registrieren
          <img
            src="/images/rightarrow.png"
            className="absolute xl:right-[-60px] xl:rotate-0 rotate-90 -bottom-9 z-10 xl:w-24 w-20 xl:bottom-9"
            alt=""
          />
        </div>
        <div className="promo-reg-cards promo-reg-cards-2">
          <div className="promo-reg-hooks">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
            >
              <g clip-path="url(#clip0_2345_434)">
                <path
                  d="M13.0572 26.95C12.6739 26.5667 12.3139 26.1467 11.9889 25.7033C11.4472 24.96 11.6106 23.9167 12.3556 23.375C13.0989 22.8333 14.1406 22.9967 14.6839 23.74C14.8989 24.0367 15.1406 24.3217 15.4122 24.5917C16.7506 25.93 18.5289 26.6667 20.4206 26.6667C22.3122 26.6667 24.0922 25.93 25.4289 24.5917L34.5956 15.425C37.3572 12.6633 37.3572 8.16833 34.5956 5.40667C31.8339 2.645 27.3389 2.645 24.5772 5.40667L22.8139 7.17C22.1622 7.82167 21.1089 7.82167 20.4572 7.17C19.8056 6.51834 19.8056 5.465 20.4572 4.81334L22.2206 3.05C26.2822 -1.01333 32.8906 -1.01333 36.9522 3.05C41.0139 7.11167 41.0139 13.72 36.9522 17.7817L27.7856 26.9483C25.8189 28.9167 23.2022 30 20.4206 30C17.6389 30 15.0222 28.9167 13.0572 26.95ZM10.4206 40C13.2039 40 15.8189 38.9167 17.7856 36.9483L19.5489 35.185C20.2006 34.535 20.2006 33.48 19.5489 32.8283C18.8989 32.1767 17.8439 32.1783 17.1922 32.8283L15.4272 34.5917C14.0889 35.93 12.3106 36.6667 10.4189 36.6667C8.52724 36.6667 6.74891 35.93 5.41057 34.5917C4.07224 33.2533 3.33557 31.475 3.33557 29.5833C3.33557 27.6917 4.07224 25.9117 5.41057 24.575L14.5772 15.4083C15.9156 14.07 17.6939 13.3333 19.5856 13.3333C21.4772 13.3333 23.2572 14.07 24.5939 15.4083C24.8606 15.6767 25.1039 15.9617 25.3206 16.2583C25.8606 17.0033 26.9022 17.17 27.6489 16.6267C28.3939 16.085 28.5589 15.0433 28.0172 14.2983C27.7006 13.8617 27.3422 13.4433 26.9522 13.0533C24.9839 11.0833 22.3672 10 19.5856 10C16.8039 10 14.1872 11.0833 12.2206 13.0517L3.05557 22.2183C1.08724 24.185 0.00390625 26.8017 0.00390625 29.5833C0.00390625 32.365 1.08724 34.9817 3.05557 36.9483C5.02224 38.9167 7.63724 40 10.4206 40Z"
                  fill="#0084F7"
                />
              </g>
              <defs>
                <clipPath id="clip0_2345_434">
                  <rect width="40" height="40" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          Du erhältst Deinen <br /> persönlichen Promotionlink <br /> von{" "}
          {user?.firstname}
          <img
            src="/images/rightarrow.png"
            className="absolute xl:right-[-60px] xl:rotate-0 rotate-90 -bottom-9 z-10 xl:w-24 w-20 xl:bottom-9"
            alt=""
          />
        </div>
        <div className="promo-reg-cards promo-reg-cards-3 ">
          <div className="promo-reg-hooks">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
            >
              <g clip-path="url(#clip0_2345_439)">
                <path
                  d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z"
                  fill="#0084F7"
                />
                <path
                  d="M28.817 11.1818C26.7362 9.08023 23.9728 7.79139 21.0254 7.54778C18.078 7.30417 15.1406 8.12183 12.7429 9.85328C10.3453 11.5847 8.64532 14.116 7.94961 16.9904C7.25391 19.8649 7.60829 22.8934 8.94883 25.5295L7.63292 31.9182C7.61927 31.9818 7.61888 32.0475 7.63178 32.1112C7.64469 32.175 7.67061 32.2354 7.70792 32.2886C7.76258 32.3695 7.8406 32.4317 7.93157 32.4671C8.02253 32.5024 8.12211 32.5091 8.21701 32.4864L14.4784 31.0023C17.1072 32.3089 20.1142 32.6405 22.9646 31.938C25.8149 31.2356 28.3235 29.5447 30.0442 27.1663C31.7648 24.7878 32.5858 21.876 32.3611 18.9491C32.1364 16.0221 30.8806 13.2698 28.817 11.1818ZM26.8647 26.7636C25.425 28.1993 23.5711 29.147 21.5642 29.4732C19.5573 29.7994 17.4987 29.4876 15.6784 28.5818L14.8056 28.15L10.967 29.0591L10.9784 29.0114L11.7738 25.1477L11.3466 24.3045C10.4165 22.4779 10.0884 20.4037 10.4093 18.3792C10.7301 16.3546 11.6836 14.4836 13.1329 13.0341C14.9541 11.2135 17.4237 10.1908 19.9988 10.1908C22.5739 10.1908 25.0436 11.2135 26.8647 13.0341C26.8802 13.0519 26.897 13.0686 26.9147 13.0841C28.7133 14.9094 29.7174 17.3717 29.708 19.9342C29.6986 22.4967 28.6766 24.9516 26.8647 26.7636Z"
                  fill="white"
                />
                <path
                  d="M26.522 23.9295C26.0515 24.6705 25.3084 25.5773 24.3743 25.8023C22.7379 26.1977 20.2265 25.8159 17.1015 22.9023L17.0629 22.8682C14.3152 20.3205 13.6015 18.2 13.7743 16.5182C13.8697 15.5636 14.6652 14.7 15.3356 14.1364C15.4416 14.0459 15.5673 13.9815 15.7027 13.9483C15.838 13.9151 15.9792 13.914 16.1151 13.9451C16.2509 13.9763 16.3776 14.0388 16.4849 14.1276C16.5923 14.2165 16.6774 14.3292 16.7334 14.4568L17.7447 16.7295C17.8104 16.8769 17.8348 17.0394 17.8152 17.1995C17.7956 17.3597 17.7327 17.5115 17.6334 17.6386L17.122 18.3023C17.0123 18.4393 16.9461 18.606 16.9319 18.781C16.9177 18.956 16.9562 19.1312 17.0425 19.2841C17.3288 19.7864 18.0152 20.525 18.7765 21.2091C19.6311 21.9818 20.5788 22.6886 21.1788 22.9295C21.3394 22.9951 21.5159 23.0111 21.6856 22.9755C21.8553 22.9398 22.0105 22.8542 22.1311 22.7295L22.7243 22.1318C22.8387 22.019 22.9811 21.9385 23.1368 21.8985C23.2925 21.8586 23.456 21.8607 23.6106 21.9045L26.0129 22.5864C26.1454 22.627 26.2669 22.6974 26.368 22.7922C26.4691 22.887 26.5473 23.0037 26.5964 23.1333C26.6455 23.2629 26.6643 23.402 26.6515 23.54C26.6386 23.678 26.5943 23.8113 26.522 23.9295Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_2345_439">
                  <rect width="40" height="40" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          Diesen Link versendest <br /> Du ganz einfach per WhatsApp <br /> an
          so viele Freunde & <br /> Bekannte wie Du magst
          <img
            src="/images/downArrow.png"
            className="absolute promodownarrow z-10   "
            alt=""
          />
        </div>
      </div>
      <div className="flex justify-center w-10/12 m-auto items-center xl:my-[180px] my-[100px]">
        <div className="promo-reg-spotlight relative ">
          <div className="-z-10 group-hover:">
            <img
              src="/images/promop1.png"
              className="absolute top-[-80px] left-[-50px]"
              alt=""
            />
            <img
              src="/images/promop2.png"
              className="absolute top-[-10px] left-[-200px]"
              alt=""
            />
            <img
              src="/images/promop3.png"
              className="absolute top-[80px] left-[-100px]"
              alt=""
            />
            <img
              src="/images/promop4.png"
              className="absolute top-[230px] left-[-50px]"
              alt=""
            />
            <img
              src="/images/promop5.png"
              className="absolute top-[200px] left-[80px] w-[134px]"
              alt=""
            />
            <img
              src="/images/promop6.png"
              className="absolute top-[220px] w-[134px] left-[200px]"
              alt=""
            />
            {/* Amazon */}
            <img
              src="/images/promop7.png"
              className="absolute top-[220px] w-[134px] right-[-50px]"
              alt=""
            />
            {/* IKEA */}
            <img
              src="/images/promop8.png"
              className="absolute top-[120px] w-[134px] right-[-20px]"
              alt=""
            />
            {/* Aral */}
            <img
              src="/images/promop9.png"
              className="absolute top-[50px]  right-[-50px]"
              alt=""
            />
            {/* MediaMarkt */}
            <img
              src="/images/promop10.png"
              className="absolute top-[-50px] w-[134px] right-[-80px]"
              alt=""
            />
            {/* Lieferando */}
            <img
              src="/images/promop11.png"
              className="absolute top-[-100px] left-[60px]"
              alt=""
            />
          </div>
          <div className="promo-reg-hooks xl:!right-[30%] xl:!left-auto">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="48"
              height="48"
              viewBox="0 0 48 48"
              fill="none"
            >
              <g clip-path="url(#clip0_2345_454)">
                <path
                  d="M8 22C3.58 22 0 25.58 0 30V40C0 44.42 3.58 48 8 48H16.52C22.12 48 27.48 45.64 31.26 41.5L46.66 24.68C48.56 22.56 48.38 19.26 46.28 17.36C45.26 16.42 43.9 15.94 42.52 16C41.12 16.06 39.84 16.66 38.94 17.66L31.86 25.14C31.92 25.56 31.98 25.98 31.98 26.42C31.98 30.58 28.88 34.18 24.74 34.76L16.24 35.96C15.14 36.12 14.14 35.36 13.98 34.26C13.82 33.16 14.58 32.16 15.68 32L24 30.84C25.88 30.58 27.5 29.22 27.88 27.38C28.48 24.52 26.3 22 23.56 22H8Z"
                  fill="#0084F7"
                />
                <path
                  d="M19.6216 9.58L13.0416 8.48C12.4416 8.38 12.0016 7.86 12.0016 7.24C12.0016 6.56 12.5616 6 13.2416 6H18.5216C19.2416 6 19.9016 6.38 20.2616 7C20.8216 7.96 22.0416 8.28 23.0016 7.72C23.9616 7.16 24.2816 5.94 23.7416 4.98C22.6616 3.14 20.6816 1.98 18.5416 1.98H18.0016C18.0016 0.879996 17.1016 -0.0200043 16.0016 -0.0200043C14.9016 -0.0200043 14.0016 0.879996 14.0016 1.98H13.2416C10.3416 1.98 8.00156 4.34 8.00156 7.22C8.00156 9.8 9.84156 11.98 12.3816 12.4L18.9616 13.5C19.5616 13.6 20.0016 14.12 20.0016 14.74C20.0016 15.42 19.4416 15.98 18.7616 15.98H13.4816C12.7616 15.98 12.1016 15.6 11.7416 14.98C11.1816 14.02 9.96156 13.7 9.00156 14.26C8.04156 14.82 7.72156 16.04 8.26156 17C9.34156 18.84 11.3216 20 13.4616 20H14.0016C14.0016 21.1 14.9016 22 16.0016 22C17.1016 22 18.0016 21.1 18.0016 20H18.7616C21.6616 20 24.0016 17.64 24.0016 14.76C24.0016 12.18 22.1616 10 19.6216 9.58Z"
                  fill="#0084F7"
                />
              </g>
              <defs>
                <clipPath id="clip0_2345_454">
                  <rect width="48" height="48" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          Für jede erfolgreiche Weiterempfehlung erhältst Du attraktives
          Prämienguthaben, welches Du für über 700 Gutscheine & Sachprämien
          nutzen kannst
        </div>
      </div>
      <div className="xl:mt-[50px] mt-[200px] text-center mb-[30px] font-semibold text-xl xl:text-[28px]">
        Jetzt als Promoter/in von {user?.firstname} registrieren
      </div>
      <div className="tertiary:w-6/12 sm:w-8/12 w-11/12 m-auto">
        <form
          className=" w-full  m-auto"
          onSubmit={(e) => {
            e.preventDefault();
            if (alreadyClicked) {
              return;
            }
            handleSubmit(e);
          }}
        >
          <div className="flex flex-wrap gap-3   m-auto ">
            {Object.keys(form).map((e, i) => (
              <FloatLabel
                label={getGermanPlaceholder(e, form)}
                unFocusClass="!top-[16px] !text-[16px]"
                focusClass="!bg-transparent !text-[10px]"
                value={form[e]}
                className={classNames(" flex-grow w-auto ", {
                  " xl:!w-[200px] !w-full !flex-grow-0": e === "anrede",
                  "": e !== "anrede",
                })}
              >
                {e === "anrede" ? (
                  <Select
                    required={true}
                    tabIndex={i + 1}
                    name={e}
                    value={form[e]}
                    onChange={handleChange}
                    prefixCls="promoterregselect"
                    className="border-[1px] xl:w-[200px] h-full w-full shadow-none   flex-grow  text-[20px] font-semibold border-[#d6d6d6] rounded-[10px] p-3 outline-none bg-transparent  "
                  >
                    <option value="Herr">Herr</option>
                    <option value="Frau">Frau</option>
                    <option value="Divers">Divers</option>
                    <option value="Firma">Firma</option>
                  </Select>
                ) : (
                  <input
                    required={e !== "lastname" || form.anrede !== "Firma"}
                    tabIndex={i + 1}
                    name={e}
                    type="text"
                    value={form[e]}
                    onChange={handleChange}
                    className="border-[1px]  w-full flex-grow  text-[20px] font-semibold border-[#d6d6d6] rounded-[10px] p-3 outline-none bg-transparent  "
                  />
                )}
              </FloatLabel>
            ))}
            <div className="flex flex-col justify-center  items-center md:items-end gap-3"></div>
          </div>
          <div className=" my-3" style={{ fontSize: 12 }}>
            Um mein Promoterkonto zu erstellen und mit {user?.firstname}{" "}
            {user?.lastname} zu interagieren, ist eine Verarbeitung meiner
            personenbezogenen Daten gemäß der{" "}
            <a
              href="/datenschutz"
              target="_blank"
              className="text-[#00def7] font-bold"
            >
              Datenschutzerklärung
            </a>{" "}
            nötig. Ich kann meine Einwilligung jederzeit unter Wirkung für die
            Zukunft widerrufen.
          </div>
          <div
            className="flex font-medium text-[14px] gap-3 items-center"
            style={{ fontSize: 12 }}
          >
            <input
              required={true}
              type="checkbox"
              checked={acceptPrivacy}
              onChange={(e) => setAcceptPrivacy(e.target.checked)}
              style={{ accentColor: styles.buttonbg, scale: "1.1" }}
            />
            <p>Ich stimme der Verarbeitung meiner Daten zu</p>
          </div>
          <div
            className="flex font-medium text-[14px] gap-3 items-center"
            style={{ fontSize: 12 }}
          >
            <input
              required={true}
              type="checkbox"
              checked={acceptAGB}
              onChange={(e) => setAcceptAGB(e.target.checked)}
              style={{ accentColor: styles.buttonbg, scale: "1.1" }}
            />
            <p>
              Ich stimme den{" "}
              <a
                href={`${window.location.origin}/teilnahmebedingungen/${user?._id}`}
                target="_blank"
                className="text-[#00def7] font-bold"
              >
                Teilnahmebedingungen
              </a>{" "}
              zu
            </p>
          </div>
          <LoadingButton
            text={"Abschicken"}
            loading={alreadyClicked}
            className="w-full cursor-pointer text-white text-xl font-semibold rounded-[10px] my-5 text-center"
          />
        </form>
      </div>
      <div className="sm:w-[95%] m-auto flex justify-center items-center">
        <div className="flex  justify-left sm:justify-between font-semibold text-[12px] sm:text-[16px]  gap-5 sm:gap-20 my-5 w-full px-5">
          <h1 className="flex-1 whitespace-wrap">©</h1>
          <div className="flex flex-1 items-center gap-2 sm:gap-10 justify-end">
            <Link
              to={`${window.location.origin}/impressum/${user?._id}`}
              target="_blank"
            >
              <span>Impressum</span>
            </Link>
            <Link to={"/datenschutz"} target="_blank">
              Datenschutz
            </Link>
            <Link
              to={`${window.location.origin}/teilnahmebedingungen/${user?._id}`}
              target="_blank"
            >
              Teilnahmebedingungen
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PromoterRegistration;
