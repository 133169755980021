import { Modal } from "antd";
import React, { useState } from "react";

const Footer = ({ landingPageData, ladingPageId, promoter_id, type, user }) => {
  const [impressum, setImpressum] = useState(false);

  return (
    <div
      className={`w-full bg-white border-t-[1px] border-[#d8d8d8] ${
        type !== "xyz" ? "px-3 mt-10" : "p-4"
      }`}
    >
      <div
        className={`flex secondary:w-[70%] m-auto justify-center sm:justify-between font-semibold text-[8px] sm:text-[16px] flex-wrap gap-20 w-full ${
          type !== "xyz" ? "my-5" : ""
        }`}
      >
        <h1>© Copyright</h1>
        <div className="flex items-center gap-10">
          <a
            className="cursor-pointer "
            rel="noreferrer"
            href="/datenschutz"
            target="_blank"
          >
            Datenschutzerklärung
          </a>
          {user?.defaultImpressumLink ? (
            <a
              className="cursor-pointer "
              rel="noreferrer"
              href={`${
                !user?.defaultImpressumLink?.includes?.("https://")
                  ? "https://"
                  : ""
              }${user?.defaultImpressumLink}`}
              target="_blank"
            >
              Impressum
            </a>
          ) : landingPageData?.specificImpressumText ? (
            <a
              className="cursor-pointer "
              rel="noreferrer"
              onClick={(e) => {
                e.preventDefault();
                setImpressum(landingPageData?.specificImpressumText);
              }}
            >
              Impressum
            </a>
          ) : (
            user?.defaultImpressumText?.length > 1 && (
              <a
                className="cursor-pointer "
                rel="noreferrer"
                onClick={(e) => {
                  e.preventDefault();
                  setImpressum(user?.defaultImpressumText);
                }}
              >
                Impressum
              </a>
            )
          )}
        </div>
      </div>

      <Modal
        title="Impressum"
        onCancel={() => setImpressum(false)}
        open={impressum}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: impressum?.replace?.(/\n/g, "<br>"),
          }}
        ></div>
      </Modal>
    </div>
  );
};

export default Footer;
