import React, { useEffect } from "react";
import { Button } from "antd";
import { MoonOutlined, SunOutlined } from "@ant-design/icons";
import { useIsMobileView } from "./ViewToggle";
import { useLandingPage } from "../../context/LandingPageContext";

const ThemeToggle = () => {
  const { templateData, setTemplateData } = useLandingPage();
  const darkMode = templateData?.darkMode || false;
  const isMobileView = useIsMobileView();

  useEffect(() => {
    // Bei Änderungen des Dark-Modes die document-Klasse anpassen
    if (darkMode) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [darkMode]);

  const toggleDark = () => {
    const newDarkMode = !darkMode;
    setTemplateData((prev) => ({
      ...prev,
      darkMode: newDarkMode,
    }));
  };

  return (
    <Button
      onClick={toggleDark}
      shape="circle"
      className="fixed top-4 right-4 z-50 transition-colors bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-slate-700"
      style={{ fontSize: isMobileView ? "0.875rem" : "1rem" }}
      aria-label="Toggle dark mode"
      icon={darkMode ? <SunOutlined /> : <MoonOutlined />}
    />
  );
};

export default ThemeToggle;
