import { MenuOutlined } from "@ant-design/icons";
import { Button } from "antd";

const ResourceToggle = ({ onClick }) => (
  <Button
    type="text"
    shape="circle"
    className="fixed top-4 left-4 z-50 h-8 w-8 md:h-10 md:w-10"
    onClick={onClick}
    aria-label="Toggle resource sidebar"
    icon={<MenuOutlined className="text-lg md:text-xl dark:!fill-white" />}
  />
);

export default ResourceToggle;
