import { useState, useEffect } from "react";
import { Avatar, Rate } from "antd";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

const PromoSection = ({
  templateData,
  avatarUrls,
  type,
  landingPageId,
  affiliate,
  clickid,
  promoter_id,
  containerVariants,
  itemVariants,
}) => {
  const navigate = useNavigate();
  // Initial countdown values
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 1,
    minutes: 50,
    seconds: 58,
  });

  // Countdown timer effect
  useEffect(() => {
    // Checke ob es bereits ein Ablaufdatum in sessionStorage gibt
    let savedEndTime = sessionStorage.getItem("promoCountdownEnd");

    if (!savedEndTime) {
      // Wenn nicht vorhanden, setze neues Enddatum (z. B. 5 Minuten von jetzt)
      const newEndTime = new Date().getTime() + 5 * 60 * 1000; // 5 Minuten
      sessionStorage.setItem("promoCountdownEnd", newEndTime);
      savedEndTime = newEndTime;
    }

    const endTime = parseInt(savedEndTime, 10);

    const timer = setInterval(() => {
      const now = new Date().getTime();
      const distance = endTime - now;

      if (distance <= 0) {
        clearInterval(timer);
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
        return;
      }

      const seconds = Math.floor((distance / 1000) % 60);
      const minutes = Math.floor((distance / 1000 / 60) % 60);
      const hours = Math.floor((distance / (1000 * 60 * 60)) % 24);
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));

      setTimeLeft({ days, hours, minutes, seconds });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  // Format time with leading zeros
  const formatTime = (time) => (time < 10 ? `0${time}` : time);

  // Verwende das Backgroundbild aus templateData, ansonsten Fallback
  const backgroundImage = templateData?.promoSection?.img
    ? `url(${templateData.promoSection.img})`
    : "url(/placeholder.svg?height=500&width=1000)";

  return (
    <div className="relative w-full md:min-h-[500px] min-h-[300px] flex items-center justify-center overflow-hidden">
      {/* Background image with overlay */}
      <div
        className="absolute inset-0 z-0"
        style={{
          backgroundImage,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundColor: "rgba(0, 0, 0, 0.7)",
          backgroundBlendMode: "overlay",
        }}
      />

      {/* Content container */}
      <motion.div
        className="relative z-10 w-full mx-auto px-4 py-16 text-center text-white text-wrap"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.1 }}
        variants={containerVariants}
      >
        {/* Main heading */}
        <motion.h1
          className="text-4xl md:text-5xl font-bold mb-6"
          dangerouslySetInnerHTML={{
            __html:
              templateData?.promoSection?.header ||
              "Es ist Zeit aktiv zu werden",
          }}
          variants={itemVariants}
        />

        {/* Subheading with strikethrough text */}
        <motion.p
          className="text-lg md:text-xl mb-8 break-words"
          dangerouslySetInnerHTML={{
            __html:
              templateData?.promoSection?.subheader ||
              'Verabschiede Dich von <span class="line-through">teuren Leads</span> und starte jetzt mit EinGuterTipp. Profitiere jetzt von unserer 7-tägigen Testphase und lerne EinGuterTipp mit all seinen Vorzügen <span class="font-bold">kostenfrei*</span> kennen.',
          }}
          variants={itemVariants}
        />

        {/* Countdown timer */}
        <div className="flex justify-center gap-4 mb-10">
          {[
            { value: formatTime(timeLeft.days), label: "Tage" },
            { value: formatTime(timeLeft.hours), label: "Stunden" },
            { value: formatTime(timeLeft.minutes), label: "Minuten" },
            { value: formatTime(timeLeft.seconds), label: "Sekunden" },
          ].map((item, index) => (
            <motion.div
              key={index}
              className="flex flex-col items-center"
              variants={itemVariants}
            >
              <div className="text-4xl font-bold">{item.value}</div>
              <div className="text-sm">{item.label}</div>
            </motion.div>
          ))}
        </div>

        {/* Custom CTA Button */}
        <motion.button
          className={`text-white px-8 py-4 md:px-8 md:py-4 md:text-xl shadow-2xl rounded-full hover:opacity-90 transition-opacity text-xl relative`}
          style={{
            background: templateData.templateColor,
            boxShadow: `inset 2px 2px 5px rgba(255, 255, 255, 0.4), 
                inset -2px -2px 5px rgba(0, 0, 0, 0.2),
                4px 4px 10px rgba(0, 0, 0, 0.3)`,
          }}
          onClick={() =>
            navigate(
              `/questionare/${type}/${landingPageId}/${promoter_id}/${clickid}`,
              { state: { affiliate } }
            )
          }
          variants={itemVariants}
        >
          {templateData.promoSection.button}
        </motion.button>

        {/* Testimonials section */}
        <motion.div
          className="mt-12 flex flex-col items-center"
          variants={itemVariants}
        >
          {/* Rating */}
          <div className="flex flex-row items-center">
            <div className="flex">
              <Avatar.Group>
                {avatarUrls.map((url, index) => (
                  <Avatar
                    key={index}
                    src={url}
                    style={{
                      width: "2.8rem",
                      height: "2.8rem",
                      fontSize: "2.8rem",
                      backgroundColor: "#f0f0f0",
                      marginLeft: index === 0 ? "0" : "-15px",
                    }}
                  />
                ))}
              </Avatar.Group>
            </div>
            <div className="flex flex-col gap-2 items-start">
              <div className="flex">
                <Rate
                  allowHalf
                  count={5}
                  value={templateData.rating.stars3}
                  disabled
                  style={{ fontSize: "1.2rem" }}
                />
              </div>
              <span className="ml-2 text-md inline-flex justify-center">
                <span
                  dangerouslySetInnerHTML={{
                    __html: templateData.rating.text3,
                  }}
                ></span>
              </span>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default PromoSection;
