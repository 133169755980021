import React, { useState } from "react";
import { PlayCircleOutlined } from "@ant-design/icons";

const VideoWithThumbnailView = ({
  videoSrc,
  thumbnailSrc,
  themeColor = "#ff0000",
}) => {
  const [isPlaying, setIsPlaying] = useState(false);

  return (
    <div className="relative w-full overflow-hidden bg-gray-100 dark:bg-gray-800 mb-8 md:mb-12">
      {isPlaying && videoSrc ? (
        <video
          src={videoSrc}
          controls
          autoPlay
          onEnded={() => setIsPlaying(false)}
          className="object-cover rounded-2xl w-full transition-opacity duration-2000"
        />
      ) : (
        <img
          src={thumbnailSrc}
          alt="Videothumbnail"
          className="object-cover rounded-2xl w-full h-full transition-opacity duration-500"
        />
      )}

      {videoSrc ? (
        !isPlaying && (
          <div className="absolute inset-0 flex items-center justify-center">
            <button
              onClick={() => setIsPlaying(true)}
              className="relative w-16 h-16 rounded-full flex items-center justify-center text-white hover:opacity-90 transition-colors"
              style={{ backgroundColor: themeColor }}
            >
              {/* Wellen-Effekt direkt im Button, zentriert hinter dem Icon */}
              <span
                className="absolute w-16 h-16 rounded-full opacity-75 animate-wave"
                style={{
                  backgroundColor: themeColor,
                  top: "0%",
                  left: "0%",
                  zIndex: 0,
                }}
              ></span>
              <span
                className="absolute w-16 h-16 rounded-full opacity-75 animate-wave delay-300"
                style={{
                  backgroundColor: themeColor,
                  top: "0%",
                  left: "0%",
                  zIndex: 0,
                }}
              ></span>
              <PlayCircleOutlined
                style={{
                  fontSize: "32px",
                  color: "#fff",
                  position: "relative",
                  zIndex: 10,
                }}
              />
            </button>
          </div>
        )
      ) : (
        <div className="absolute inset-0 flex items-center justify-center">
          <button
            onClick={() => setIsPlaying(true)}
            className="relative w-16 h-16 rounded-full flex items-center justify-center text-white hover:opacity-90 transition-colors"
            style={{ backgroundColor: themeColor }}
          >
            {/* Wellen-Effekt direkt im Button, zentriert hinter dem Icon */}
            <span
              className="absolute w-16 h-16 rounded-full opacity-75 animate-wave"
              style={{
                backgroundColor: themeColor,
                top: "0%",
                left: "0%",
                zIndex: 0,
              }}
            ></span>
            <span
              className="absolute w-16 h-16 rounded-full opacity-75 animate-wave delay-100"
              style={{
                backgroundColor: themeColor,
                top: "0%",
                left: "0%",
                zIndex: 0,
              }}
            ></span>
            <PlayCircleOutlined
              style={{
                fontSize: "32px",
                color: "#fff",
                position: "relative",
                zIndex: 10,
              }}
            />
          </button>
        </div>
      )}
    </div>
  );
};

export default VideoWithThumbnailView;
