import React, { useMemo } from "react";
import { Avatar, Rate } from "antd";
import { UserOutlined, AntDesignOutlined } from "@ant-design/icons";
import { motion } from "framer-motion";
import EditableText from "../../shared/EditableField";
import { useIsMobileView } from "../../../Shared/ViewToggle";
import useThemeColor from "../../../../hooks/useThemeColor";
import { useLandingPage } from "../../../../context/LandingPageContext";
import SimpleIconPicker from "../../shared/SimpleIconPicker";
import EditableButton from "../../shared/EditableButton";
import { fontMapping } from "../../shared/fontMapping";

const XYZAboutSection = () => {
  const { templateData, setTemplateData } = useLandingPage();
  const { aboutSection } = templateData;
  const isMobile = useIsMobileView();
  const [color] = useThemeColor();
  const sectionVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };

  const avatarUrls = useMemo(() => {
    return Array(5)
      .fill(0)
      .map((_, index) => `/ReviewPeople/${index + 1}.jfif`);
  }, []);

  return (
    <>
      {aboutSection?.activate && (
        <motion.section
          className="w-full py-8 px-8 md:px-24 md:py-12 bg-gray-50 dark:bg-slate-600 bg-[#E0E9F1] override-font"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.3 }}
          transition={{ duration: 0.8 }}
          variants={sectionVariants}
          style={{
            "--font-family":
              fontMapping[templateData.font] || fontMapping.modern,
          }}
        >
          <div className="flex flex-col items-center text-center w-full">
            {/* Haupt-Überschrift */}
            <EditableText
              maxLines={2}
              maxChars={200}
              text={aboutSection.header}
              onSave={(newValue) =>
                setTemplateData((prev) => ({
                  ...prev,
                  aboutSection: { ...prev.aboutSection, header: newValue },
                }))
              }
              element="h2"
              className={`font-bold mb-2 ${isMobile ? "text-xl" : "text-4xl"}`}
            />

            {/* Unter-Überschrift */}
            <EditableText
              maxLines={3}
              maxChars={200}
              text={aboutSection.subheader}
              onSave={(newValue) =>
                setTemplateData((prev) => ({
                  ...prev,
                  aboutSection: { ...prev.aboutSection, subheader: newValue },
                }))
              }
              element="h3"
              className={`font-bold mb-4 ${isMobile ? "text-lg" : "text-2xl"}`}
            />

            {/* Grid mit 3 Karten */}
            <div
              className={`grid ${
                isMobile ? "grid-cols-1" : "md:grid-cols-3"
              } gap-6 md:gap-8 bg-white dark:bg-slate-800 md:p-12 rounded-2xl`}
            >
              {/* Card 1 */}
              <div className="text-center">
                <div className="w-16 h-16 mx-auto mb-4">
                  <SimpleIconPicker
                    selectedIcon={aboutSection.icon1 || "Shield"}
                    onChange={(newIcon) =>
                      setTemplateData((prev) => ({
                        ...prev,
                        aboutSection: {
                          ...prev.aboutSection,
                          icon1: newIcon,
                        },
                      }))
                    }
                  />
                </div>
                <EditableText
                  maxLines={3}
                  maxChars={200}
                  text={aboutSection.card1header}
                  onSave={(newValue) =>
                    setTemplateData((prev) => ({
                      ...prev,
                      aboutSection: {
                        ...prev.aboutSection,
                        card1header: newValue,
                      },
                    }))
                  }
                  element="h4"
                  className="font-bold mb-2 text-xl"
                />
                <EditableText
                  maxLines={3}
                  maxChars={200}
                  text={aboutSection.card1text}
                  onSave={(newValue) =>
                    setTemplateData((prev) => ({
                      ...prev,
                      aboutSection: {
                        ...prev.aboutSection,
                        card1text: newValue,
                      },
                    }))
                  }
                  element="p"
                  className="text-gray-600 dark:text-gray-300"
                />
              </div>

              {/* Card 2 */}
              <div className="text-center">
                <div className="w-16 h-16 mx-auto mb-4">
                  <SimpleIconPicker
                    selectedIcon={aboutSection.icon2 || "Shield"}
                    onChange={(newIcon) =>
                      setTemplateData((prev) => ({
                        ...prev,
                        aboutSection: {
                          ...prev.aboutSection,
                          icon2: newIcon,
                        },
                      }))
                    }
                  />
                </div>
                <EditableText
                  maxLines={2}
                  maxChars={200}
                  text={aboutSection.card2header}
                  onSave={(newValue) =>
                    setTemplateData((prev) => ({
                      ...prev,
                      aboutSection: {
                        ...prev.aboutSection,
                        card2header: newValue,
                      },
                    }))
                  }
                  element="h4"
                  className="font-bold mb-2 text-xl"
                />
                <EditableText
                  maxLines={3}
                  maxChars={200}
                  text={aboutSection.card2text}
                  onSave={(newValue) =>
                    setTemplateData((prev) => ({
                      ...prev,
                      aboutSection: {
                        ...prev.aboutSection,
                        card2text: newValue,
                      },
                    }))
                  }
                  element="p"
                  className="text-gray-600 dark:text-gray-300"
                />
              </div>

              {/* Card 3 */}
              <div className="text-center">
                <div className="w-16 h-16 mx-auto mb-4">
                  <SimpleIconPicker
                    selectedIcon={aboutSection.icon3 || "Shield"}
                    onChange={(newIcon) =>
                      setTemplateData((prev) => ({
                        ...prev,
                        aboutSection: {
                          ...prev.aboutSection,
                          icon3: newIcon,
                        },
                      }))
                    }
                  />
                </div>
                <EditableText
                  maxLines={2}
                  maxChars={200}
                  text={aboutSection.card3header}
                  onSave={(newValue) =>
                    setTemplateData((prev) => ({
                      ...prev,
                      aboutSection: {
                        ...prev.aboutSection,
                        card3header: newValue,
                      },
                    }))
                  }
                  element="h4"
                  className="font-bold mb-2 text-xl"
                />
                <EditableText
                  maxLines={3}
                  maxChars={200}
                  text={aboutSection.card3text}
                  onSave={(newValue) =>
                    setTemplateData((prev) => ({
                      ...prev,
                      aboutSection: {
                        ...prev.aboutSection,
                        card3text: newValue,
                      },
                    }))
                  }
                  element="p"
                  className="text-gray-600 dark:text-gray-300"
                />
              </div>
            </div>
            <div className="flex flex-row items-center gap-2 mt-12">
              <div className="flex -space-x-2">
                <Avatar.Group>
                  {avatarUrls.map((url, index) => (
                    <Avatar
                      key={index}
                      src={url}
                      style={{
                        width: "2.8rem",
                        height: "2.8rem",
                        fontSize: "2.8rem",
                        backgroundColor: "#f0f0f0",
                        marginLeft: index === 0 ? "0" : "-15px",
                      }}
                    />
                  ))}
                </Avatar.Group>
              </div>
              <div className="flex items-start flex-col">
                <div className="flex">
                  <Rate
                    allowHalf
                    count={5}
                    value={templateData.rating?.stars2 || 0}
                    style={{ fontSize: "1.2rem" }}
                    onChange={(value) =>
                      setTemplateData((prev) => ({
                        ...prev,
                        rating: {
                          ...prev.rating,
                          stars2: value,
                        },
                      }))
                    }
                  />
                </div>
                <span className="ml-2 text-sm inline-flex justify-center">
                  <span className="ml-2 text-md inline-flex justify-center">
                    <EditableText
                      text={templateData.rating?.text2}
                      maxLines={1}
                      maxChars={70}
                      onSave={(newValue) =>
                        setTemplateData((prev) => ({
                          ...prev,
                          rating: {
                            ...prev.rating,
                            text2: newValue,
                          },
                        }))
                      }
                      element="span"
                    />
                  </span>
                </span>
              </div>
            </div>
            {/* Button */}
            <EditableButton
              text={templateData.buttonTwo}
              onSave={(newValue) =>
                setTemplateData((prev) => ({
                  ...prev,
                  buttonTwo: newValue,
                }))
              }
              className={`text-white ${
                isMobile
                  ? "px-8 py-4 md:px-8 md:py-4"
                  : "px-8 py-4 md:px-8 md:py-4 md:text-xl shadow-2xl"
              } rounded-full hover:opacity-90 transition-opacity text-xl relative mt-8`}
              style={{
                background: color,
                boxShadow: `inset 2px 2px 5px rgba(255, 255, 255, 0.4), 
      inset -2px -2px 5px rgba(0, 0, 0, 0.2),
      4px 4px 10px rgba(0, 0, 0, 0.3)`,
              }}
            />
          </div>
        </motion.section>
      )}
    </>
  );
};

export default XYZAboutSection;
