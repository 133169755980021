import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { PopUpComponent } from "../../../ConfirmPopup";
import Inputs from "../../../_NEW/shared/Input";
import Box from "../../../_NEW/LandingPageConfig/Layout/Box";
import Button from "../../../_NEW/LandingPageConfig/shared/Button";
import MediaUpload from "../../../_NEW/LandingPageConfig/shared/MediaUpload";
import { useLandingPage } from "../../context/LandingPageContext";
import ClosingFormularKonfig from "./ClosingFormularKonfig";

const MAX_QUESTIONS = 8;

const QuestionSettings = () => {
  const { id: landingpageId } = useParams();
  const { questionData, setQuestionData } = useLandingPage();
  const imageRefs = useRef([]);

  useEffect(() => {
    imageRefs.current = Array.from(
      { length: questionData.length },
      (_, index) => imageRefs.current[index]
    );
  }, [questionData]);

  const updateQuestion = (index, newData) => {
    setQuestionData((prevQuestions) =>
      prevQuestions.map((q, i) => (i === index ? { ...q, ...newData } : q))
    );
  };

  const updateAnswer = (questionIndex, answerIndex, newData) => {
    setQuestionData((prevQuestions) =>
      prevQuestions.map((q, i) =>
        i === questionIndex
          ? {
              ...q,
              answerOptions: q.answerOptions.map((option, j) =>
                j === answerIndex ? { ...option, ...newData } : option
              ),
            }
          : q
      )
    );
  };

  const addAnswer = (questionIndex) => {
    setQuestionData((prevQuestions) =>
      prevQuestions.map((q, i) =>
        i === questionIndex
          ? {
              ...q,
              answerOptions: [
                ...q.answerOptions,
                { text: "", consideredNiceToHaveAnswer: true },
              ],
            }
          : q
      )
    );
  };

  const deleteAnswer = (questionIndex, answerIndex) => {
    setQuestionData((prevQuestions) =>
      prevQuestions.map((q, i) =>
        i === questionIndex
          ? {
              ...q,
              answerOptions: q.answerOptions.filter(
                (_, j) => j !== answerIndex
              ),
            }
          : q
      )
    );
  };

  const deleteQuestion = (questionIndex) => {
    PopUpComponent({
      onConfirm: () => {
        setQuestionData((prevQuestions) =>
          prevQuestions.filter((_, i) => i !== questionIndex)
        );
      },
      heading: "Bist Du sicher, dass Du diese Frage löschen möchtest?",
      confirmButtonText: "Löschen",
    });
  };

  const addQuestion = () => {
    if (questionData.length < MAX_QUESTIONS) {
      setQuestionData((prevQuestions) => [
        ...prevQuestions,
        {
          user_id: null,
          landingpage_id: landingpageId,
          questionText: "Neue Frage hier eingeben",
          backgroundImage: "/landingPageBgs/finance.svg",
          answerOptions: [
            { text: "Positive Antwort", consideredNiceToHaveAnswer: true },
            { text: "Negative Antwort", consideredNiceToHaveAnswer: false },
          ],
        },
      ]);
    }
  };

  return (
    <div className="flex flex-col items-center p-4 gap-4 text-slate-900">
      <h3 className="text-2xl font-semibold mb-4 text-slate-950">
        Bearbeiten Sie Ihre Fragen
      </h3>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 w-full">
        {questionData.map((question, id) => (
          <Question
            key={id}
            title={`Frage ${id + 1}`}
            question={question}
            onChangeQuestionText={(e) =>
              updateQuestion(id, { questionText: e.target.value })
            }
            onChangeAnswer={(value, answerId) =>
              updateAnswer(id, answerId, { text: value })
            }
            onChangeNiceToHave={(value, answerId) =>
              updateAnswer(id, answerId, { consideredNiceToHaveAnswer: value })
            }
            onDeleteAnswer={(answerId) => deleteAnswer(id, answerId)}
            addAnswer={() => addAnswer(id)}
            deleteQuestion={() => deleteQuestion(id)}
            imageRef={(ref) => {
              imageRefs.current[id] = ref;
            }}
            backgroundImage={question.backgroundImage}
            setBackgroundImage={(newBackgroundImage) =>
              updateQuestion(id, { backgroundImage: newBackgroundImage })
            }
          />
        ))}
      </div>
      <Button
        onClick={addQuestion}
        text={"+ Frage Hinzufügen"}
        light
        display={questionData.length < MAX_QUESTIONS}
        className="mt-6"
      />
      <ClosingFormularKonfig visible={true} />
    </div>
  );
};

const Question = ({
  title,
  question,
  onChangeQuestionText,
  onChangeAnswer,
  onChangeNiceToHave,
  onDeleteAnswer,
  addAnswer,
  deleteQuestion,
  imageRef,
  backgroundImage,
  setBackgroundImage,
}) => {
  const { questionText, answerOptions } = question;

  return (
    <Box className="p-2 bg-white shadow border border-slate-200 rounded-lg dark:bg-slate-800 dark:border-slate-700 flex flex-col gap-2">
      {/* Titelzeile */}
      <div className="flex items-center justify-between">
        <h3 className="text-xs font-semibold text-slate-700 dark:text-white uppercase tracking-wide">
          {title}
        </h3>
        <button
          onClick={deleteQuestion}
          className="text-red-500 text-xs hover:underline"
        >
          Frage löschen
        </button>
      </div>

      {/* Eingabe der Frage */}
      <Inputs
        label="Frage"
        value={questionText}
        onChange={onChangeQuestionText}
        type="textarea"
        className="text-xs"
      />

      {/* Hintergrundbild */}
      <MediaUpload
        url={backgroundImage}
        setUrl={setBackgroundImage}
        id={`image-upload-${title}`}
        textSrcThere="Hintergrundbild ändern"
        textSrcNotThere="Hintergrundbild hochladen"
        ref={imageRef}
        className="rounded-md max-h-[80px] object-cover"
        style={{ borderRadius: "6px", maxHeight: "100px", objectFit: "cover" }}
      />

      {/* Antwortmöglichkeiten */}
      <div className="flex flex-col gap-1 mt-2">
        {answerOptions.map(({ consideredNiceToHaveAnswer, text }, id) => (
          <div
            key={id}
            className="bg-slate-50 dark:bg-slate-700 rounded-md px-2 py-1 border border-slate-200 dark:border-slate-600 flex items-center justify-between"
          >
            <div className="flex-1">
              <Inputs
                value={text}
                consideredNiceToHaveAnswer={consideredNiceToHaveAnswer}
                label={
                  consideredNiceToHaveAnswer ? "Antwort" : "Antwort (ablehnend)"
                }
                onChange={(e) => onChangeAnswer(e.target.value, id)}
                onChangeNiceToHave={(value) => onChangeNiceToHave(value, id)}
                withDropdown={true}
                className="!text-xs"
              />
            </div>
            <button
              onClick={() => onDeleteAnswer(id)}
              className="ml-2 text-red-500 hover:text-red-700 text-xs"
              title="Antwort löschen"
            >
              ✕
            </button>
          </div>
        ))}
      </div>

      {/* Aktionen */}
      <div className="flex justify-center pt-2">
        <Button
          onClick={addAnswer}
          text="+ Antwort hinzufügen"
          light
          className="text-xs px-2 py-1 leading-tight"
        />
      </div>
    </Box>
  );
};

export default QuestionSettings;
