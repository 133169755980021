import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { useLandingPage } from "../../../../context/LandingPageContext";
import EditableText from "../../shared/EditableField";

const XYZBannerSection = () => {
  const { templateData, setTemplateData } = useLandingPage();
  const { bannerSection } = templateData;
  const sectionVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };
  useEffect(() => {
    const setGradientBackground = () => {
      const isDarkMode = templateData.darkMode;
      const logosWrapper = document.querySelector(".logos-wrapper");

      if (!logosWrapper) {
        console.warn("logos-wrapper not found");
        return;
      }

      // Styles dynamisch ändern
      logosWrapper.style.setProperty(
        "--gradient-bg-left",
        isDarkMode
          ? "linear-gradient(to left, rgba(255, 255, 255, 0), rgb(30 41 59))"
          : "linear-gradient(to left, rgba(255, 255, 255, 0), white)"
      );

      logosWrapper.style.setProperty(
        "--gradient-bg-right",
        isDarkMode
          ? "linear-gradient(to right, rgba(255, 255, 255, 0), rgb(30 41 59))"
          : "linear-gradient(to right, rgba(255, 255, 255, 0), white)"
      );
    };

    setGradientBackground();

    document.documentElement.addEventListener(
      "classChange",
      setGradientBackground
    );
    return () => {
      document.documentElement.removeEventListener(
        "classChange",
        setGradientBackground
      );
    };
  }, [templateData.darkMode]);
  return (
    <>
      {bannerSection?.activate && (
        <motion.section
          className="w-full -mt-8"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.8 }}
          transition={{ duration: 0.8 }}
          variants={sectionVariants}
        >
          <EditableText
            text={templateData.bannerSection.header}
            maxLines={2}
            maxChars={100}
            onSave={(newValue) =>
              setTemplateData((prev) => ({
                ...prev,
                bannerSection: {
                  ...prev.bannerSection,
                  header: newValue,
                },
              }))
            }
            element="p"
            className="text-slate-600 dark:text-slate-300 max-w-3xl mx-auto group relative text-sm md:text-xl"
          />
          <div className="logos-wrapper">
            <div className="logos">
              {/* ERSTE Slide */}
              <div className="logos-slide">
                {bannerSection?.logos
                  .filter((Icon) => Icon) // Entfernt alle `null` und `undefined` Werte
                  .map((Icon, idx) => (
                    <img
                      className="icon"
                      key={idx}
                      src={Icon}
                      alt={`Logo ${idx}`}
                    />
                  ))}
              </div>

              {/* ZWEITE Slide */}
              <div className="logos-slide">
                {bannerSection?.logos
                  .filter((Icon) => Icon) // Entfernt alle `null` und `undefined` Werte
                  .map((Icon, idx) => (
                    <img
                      className="icon"
                      key={idx}
                      src={Icon}
                      alt={`Logo ${idx}`}
                    />
                  ))}
              </div>
            </div>
          </div>
        </motion.section>
      )}
    </>
  );
};

export default XYZBannerSection;
