import React, { useState, useEffect } from "react";
import { Rate } from "antd";
import { motion } from "framer-motion";
import {
  ThumbsUpIcon,
  StarIcon,
  MessageCircleIcon,
  CircleCheckBig,
  LoaderCircle,
  ShieldIcon,
  Pencil,
} from "lucide-react";
import { StarFilled, StarOutlined } from "@ant-design/icons";
import EditableText from "../../shared/EditableField";
import { useLandingPage } from "../../../../context/LandingPageContext";
import { useIsMobileView } from "../../../Shared/ViewToggle";
import useThemeColor from "../../../../hooks/useThemeColor";
import { fontMapping } from "../../shared/fontMapping";

const EditableNumber = ({
  value,
  onSave,
  className = "",
  allowAnyInput = false,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const handleSave = () => {
    if (allowAnyInput) {
      onSave(localValue);
    } else {
      let newNumber = parseFloat(localValue);
      if (isNaN(newNumber)) {
        newNumber = 0;
      } else if (newNumber > 999) {
        newNumber = 999;
      } else if (newNumber < 0) {
        newNumber = 0;
      }
      onSave(newNumber);
    }
    setIsEditing(false);
  };

  return isEditing ? (
    <input
      type="text"
      className={`text-xl font-bold ${className} max-w-[100px] text-slate-950`}
      value={localValue}
      onChange={(e) => {
        const inputVal = e.target.value;

        if (allowAnyInput) {
          setLocalValue(inputVal);
        } else {
          if (/^\d{0,3}([.,]\d{0,1})?$/.test(inputVal)) {
            setLocalValue(inputVal.replace(",", "."));
          }
        }
      }}
      onBlur={handleSave}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          handleSave();
        }
      }}
      autoFocus
    />
  ) : (
    <div
      className={`relative inline-flex items-center group cursor-pointer ${className}`}
      onClick={() => setIsEditing(true)}
    >
      <span className="text-3xl md:text-6xl font-bold">
        {allowAnyInput
          ? String(value)
          : value >= 1 && value <= 5
          ? Number(value).toFixed(1)
          : Number(value).toFixed(0)}
      </span>

      {/* ✏️ Edit Icon */}
      <Pencil
        size={16}
        className="ml-2 opacity-50 group-hover:opacity-100 transition-opacity text-gray-500"
      />
    </div>
  );
};

const XYZCardSection = () => {
  const { templateData, setTemplateData } = useLandingPage();
  const { cardSection } = templateData;
  const isMobile = useIsMobileView();
  const [color] = useThemeColor();
  const bgColor = color.replace(
    /rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*[\d.]+)?\)/,
    (match, r, g, b) => `rgba(${r},${g},${b},0.1)`
  );

  const sectionVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <>
      {cardSection?.activate && (
        <motion.section
          className={`${
            isMobile ? "px-6" : "px-6 md:px-16 lg:px-36"
          } py-8 md:py-12  dark:bg-slate-800 w-full override-font`}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.3 }}
          transition={{ duration: 0.8 }}
          variants={sectionVariants}
          style={{
            "--font-family":
              fontMapping[templateData.font] || fontMapping.modern,
          }}
        >
          <div className="flex flex-col items-center gap-0">
            {/* Bearbeitbarer Card-Header */}
            <EditableText
              text={cardSection.header}
              maxLines={2}
              onSave={(newValue) =>
                setTemplateData((prev) => ({
                  ...prev,
                  cardSection: { ...prev.cardSection, header: newValue },
                }))
              }
              element="h1"
              className={`font-bold mb-6 md:mb-8 ${
                isMobile ? "text-3xl" : "text-4xl"
              } mx-auto group relative text-center`}
            />
          </div>

          {/* Erste Zeile mit zwei Cards */}
          <div
            className={`grid gap-4 md:gap-6 mb-6 md:mb-8 ${
              isMobile ? "grid-cols-1" : "md:grid-cols-2"
            }`}
          >
            {/* 1. Stat Card (Light background) */}
            <div
              className="p-2 md:p-6 rounded-2xl w-full hidden md:flex flex-col items-center justify-start"
              style={{
                background: bgColor,
                color: color,
              }}
            >
              <div className="flex items-center gap-2 mb-2">
                <ThumbsUpIcon className="md:w-14 md:h-14 h-8 w-8" />
                <EditableNumber
                  value={cardSection.card1Number || 91}
                  allowAnyInput={true}
                  onSave={(newValue) =>
                    setTemplateData((prev) => ({
                      ...prev,
                      cardSection: {
                        ...prev.cardSection,
                        card1Number: newValue,
                      },
                    }))
                  }
                />
              </div>
              <EditableText
                text={cardSection.card1Text}
                maxLines={2}
                onSave={(newValue) =>
                  setTemplateData((prev) => ({
                    ...prev,
                    cardSection: { ...prev.cardSection, card1Text: newValue },
                  }))
                }
                element="p"
                className="text-xl"
              />
            </div>

            {/* 2. Stat Card (Solid background) */}
            <div
              className="md:p-6 p-4 rounded-2xl text-white flex flex-col items-center justify-start"
              style={{ background: color }}
            >
              <div className="flex items-center gap-2 mb-2">
                <EditableNumber
                  value={cardSection.card2Number || 5.0}
                  onSave={(newValue) =>
                    setTemplateData((prev) => ({
                      ...prev,
                      cardSection: {
                        ...prev.cardSection,
                        card2Number: newValue,
                      },
                    }))
                  }
                />
                <Rate
                  allowHalf
                  value={cardSection.card2Number || 5.0}
                  disabled
                  className="ml-2 text-4xl flex gap-1"
                  character={({ index }) => {
                    const rating = cardSection.card2Number || 5.0;
                    const wholeStars = Math.floor(rating);
                    const isHalfStar = rating - wholeStars >= 0.5;

                    const starSize = "1.5rem";

                    const starStyle = {
                      color: "white",
                      fontSize: starSize,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    };

                    if (index < wholeStars) {
                      return <StarFilled style={starStyle} />;
                    } else if (index === wholeStars && isHalfStar) {
                      return (
                        <span
                          className="relative inline-flex items-center justify-center"
                          style={{ width: starSize, height: starSize }}
                        >
                          {/* Volle Outline im Hintergrund */}
                          <StarOutlined
                            style={{
                              ...starStyle,
                              position: "absolute",
                              left: 0,
                              top: 0,
                              width: starSize,
                              height: starSize,
                            }}
                          />
                          {/* Linke Hälfte gefüllt */}
                          <span
                            className="absolute top-0 left-0 w-1/2 h-full overflow-hidden flex items-center justify-center"
                            style={{ width: "50%", height: "100%" }}
                          >
                            <StarFilled
                              style={{
                                ...starStyle,
                                position: "absolute",
                                left: 0,
                                width: starSize,
                                height: starSize,
                              }}
                            />
                          </span>
                        </span>
                      );
                    } else {
                      return <StarOutlined style={starStyle} />;
                    }
                  }}
                />
              </div>
              <EditableText
                text={cardSection.card2Text}
                maxLines={2}
                onSave={(newValue) =>
                  setTemplateData((prev) => ({
                    ...prev,
                    cardSection: { ...prev.cardSection, card2Text: newValue },
                  }))
                }
                element="p"
                className="text-xl"
              />
            </div>
          </div>

          {/* Zweite Zeile mit drei Cards */}
          <div
            className={`grid gap-4 md:gap-6 ${
              isMobile
                ? "grid-cols-1"
                : "grid-cols-2 sm:grid-cols-2 md:grid-cols-3"
            }`}
          >
            {/* 3. Stat Card (Solid background) */}
            <div
              className="md:p-6 p-4 rounded-2xl w-full md:hidden flex flex-col items-center justify-start"
              style={{
                background: bgColor,
                color: color,
              }}
            >
              <div className="flex items-center gap-2 mb-2">
                <ThumbsUpIcon className="md:w-14 md:h-14 h-8 w-8" />
                <EditableNumber
                  value={cardSection.card1Number || 91}
                  allowAnyInput={true}
                  onSave={(newValue) =>
                    setTemplateData((prev) => ({
                      ...prev,
                      cardSection: {
                        ...prev.cardSection,
                        card1Number: newValue,
                      },
                    }))
                  }
                />
              </div>
              <EditableText
                text={cardSection.card1Text}
                maxLines={2}
                onSave={(newValue) =>
                  setTemplateData((prev) => ({
                    ...prev,
                    cardSection: { ...prev.cardSection, card1Text: newValue },
                  }))
                }
                element="p"
                className="text-xl"
              />
            </div>
            <div
              className="md:p-6 p-4 rounded-2xl text-white flex flex-col items-center justify-start"
              style={{ background: color }}
            >
              <div className="flex items-center gap-2 mb-2">
                <LoaderCircle className="md:w-14 md:h-14 h-8 w-8" />
                <EditableNumber
                  value={cardSection.card3Number || 4.7}
                  allowAnyInput={true}
                  onSave={(newValue) =>
                    setTemplateData((prev) => ({
                      ...prev,
                      cardSection: {
                        ...prev.cardSection,
                        card3Number: newValue,
                      },
                    }))
                  }
                />
              </div>
              <EditableText
                maxLines={2}
                text={cardSection.card3Text}
                onSave={(newValue) =>
                  setTemplateData((prev) => ({
                    ...prev,
                    cardSection: { ...prev.cardSection, card3Text: newValue },
                  }))
                }
                element="p"
                className="text-xl"
              />
            </div>

            {/* 4. Stat Card (Light background) */}
            <div
              className="md:p-6 p-4 rounded-2xl flex flex-col items-center justify-start"
              style={{
                background: bgColor,
                color: color,
              }}
            >
              <div className="flex items-center gap-2 mb-2">
                <EditableNumber
                  value={cardSection.card4Number || 85}
                  onSave={(newValue) =>
                    setTemplateData((prev) => ({
                      ...prev,
                      cardSection: {
                        ...prev.cardSection,
                        card4Number: newValue,
                      },
                    }))
                  }
                />
                <Rate
                  allowHalf
                  value={cardSection.card4Number || 5.0}
                  disabled
                  className="ml-2 text-4xl flex gap-1 items-start"
                  character={({ index }) => {
                    const rating = cardSection.card4Number || 5.0;
                    const wholeStars = Math.floor(rating);
                    const isHalfStar = rating - wholeStars >= 0.5;

                    // Unterscheidung für mobile und größere Geräte
                    const starSizeMobile = "0.25rem";
                    const starSizeDesktop = "1.0rem";

                    const starStyle = {
                      color: color,
                      fontSize: `clamp(${starSizeMobile}, 2vw, ${starSizeDesktop})`,
                      width: `clamp(${starSizeMobile}, 2vw, ${starSizeDesktop})`,
                      height: `clamp(${starSizeMobile}, 2vw, ${starSizeDesktop})`,
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "center",
                    };

                    if (index < wholeStars) {
                      return <StarFilled style={starStyle} />;
                    } else if (index === wholeStars && isHalfStar) {
                      return (
                        <span
                          className="relative inline-flex items-start justify-center"
                          style={{
                            width: starStyle.width,
                            height: starStyle.height,
                            fontSize: starStyle.fontSize,
                            display: "inline-block",
                            position: "relative",
                          }}
                        >
                          {/* Volle Outline im Hintergrund */}
                          <StarOutlined
                            style={{
                              ...starStyle,
                              position: "absolute",
                              left: 0,
                              top: 0,
                            }}
                          />
                          {/* Linke Hälfte gefüllt */}
                          <span
                            className="absolute top-0 left-0 w-1/2 h-full overflow-hidden flex items-start justify-center"
                            style={{
                              width: "50%",
                              height: "100%",
                              position: "absolute",
                              left: 0,
                              top: 0,
                            }}
                          >
                            <StarFilled
                              style={{
                                ...starStyle,
                                position: "absolute",
                                left: 0,
                                top: 0,
                              }}
                            />
                          </span>
                        </span>
                      );
                    } else {
                      return <StarOutlined style={starStyle} />;
                    }
                  }}
                />
              </div>
              <EditableText
                text={cardSection.card4Text}
                maxLines={2}
                onSave={(newValue) =>
                  setTemplateData((prev) => ({
                    ...prev,
                    cardSection: { ...prev.cardSection, card4Text: newValue },
                  }))
                }
                element="p"
                className="text-md"
              />
            </div>

            {/* 5. Stat Card (Light background) */}
            <div
              className="md:p-6 p-4 rounded-2xl flex flex-col items-center justify-start"
              style={{
                background: bgColor,
                color: color,
              }}
            >
              <div className="flex items-center gap-2 mb-2">
                <CircleCheckBig className="md:w-14 md:h-14 h-8 w-8" />
                <EditableNumber
                  value={cardSection.card5Number || 92}
                  allowAnyInput={true}
                  onSave={(newValue) =>
                    setTemplateData((prev) => ({
                      ...prev,
                      cardSection: {
                        ...prev.cardSection,
                        card5Number: newValue,
                      },
                    }))
                  }
                />
              </div>
              <EditableText
                maxLines={2}
                text={cardSection.card5Text}
                onSave={(newValue) =>
                  setTemplateData((prev) => ({
                    ...prev,
                    cardSection: { ...prev.cardSection, card5Text: newValue },
                  }))
                }
                element="p"
                className="text-lg"
              />
            </div>
          </div>
        </motion.section>
      )}
    </>
  );
};

export default XYZCardSection;
