import React from "react";
import {
  CURVEDARROW,
  DOWNGREENARROW,
  GUTERTIPCARD,
  RIGhTGREENARROW,
} from "../../../assets/Images/index";

const Index = () => {
  return (
    <div
      id="uberuns"
      className={`bg-[#fafafa] rounded-[30px]  xs:w-auto w-[85%] m-auto    flex flex-col max-w-[1600px] `}
    >
      <h1 className="text-center !text-responsiveHeading sm:text-primary font-bold so-funktioniert-eingutertipp">
        So einfach funktioniert EinGuterTipp
      </h1>
      <div className="relative xl:m-auto my-10">
        <div className="relative flex secondary:w-[90%] w-full m-auto secondary:max-h-[600px] h-auto py-10  ">
          <div className="text-white secondary:block  secondary:relative text-primary numbers absolute secondary:top-[40px] top-[-100px] left-[0px] z-10 mt-[5rem] ">
            1
          </div>
          <div className="rounded-bg-blue absolute secondary:!h-[70%] h-full bottom-0 w-[95%] right-0 left-0  m-auto"></div>
          <div className="z-10  relative flex justify-between w-[90%] m-auto flex-wrap secondary:flex-nowrap ">
            <div className="z-10 m-0 secondary:my-10 secondary:mt-[14rem] tertiary:mt-[12rem] relative">
              <h1 className="text-primary font-bold text-white  secondary:block hidden">
                Erstelle Deine <wbr />
                Online Visitenkarte als Landingpage
              </h1>
              <h1 className=" secondary:hidden block text-responsiveHeading sm:text-start text-center font-bold text-white ">
                <p> Erstelle Deine Online Visitenkarte als Landingpage</p>
              </h1>
              <p className="text-[#D8D8D8] font-[600] text-[0.96rem] secondary:block hidden ">
                Hier gibt es bereits einige Vorlagen zu verschiedenen Branchen,
                die <br /> Du ganz einfach Nutzen kannst. Sie sind natürlich
                bereits optimal <br /> abgestimmt und Du kannst sie direkt so
                einsetzen. Wenn Du magst,
                <br /> kannst Du aber natürlich auch Individualisierungen
                vornehmen.
              </p>
              <div className="secondary:hidden grid xl:grid-cols-2 grid-cols-1 ">
                <p className="text-[#D8D8D8] font-[600] m-auto md:text-[20px]  text-sm my-10 flex justify-center items-center sm:text-start text-center">
                  <span>
                    Hier gibt es bereits einige Vorlagen zu verschiedenen
                    Branchen, die Du ganz einfach Nutzen kannst. Sie sind
                    natürlich bereits optimal abgestimmt und Du kannst sie
                    direkt so einsetzen. Wenn Du magst, kannst Du aber natürlich
                    auch Individualisierungen vornehmen.
                  </span>
                </p>
                <div className=" justify-center sm:hidden items-center relative flex sm:mt-0 mt-32">
                  <img
                    src={GUTERTIPCARD}
                    className="sm:relative absolute  sm:scale-0 scale-110 sm:rotate-0 -rotate-12 z-20 w-[300px] "
                    alt=""
                  />
                </div>
              </div>
            </div>
            <img
              src={GUTERTIPCARD}
              className=" z-20 secondary:block  xl:hidden xl:static absolute top-[-33%] right-[-7%] xl:w-auto w-[132px] sm:flex hidden"
              alt=""
            />
          </div>
        </div>
        <img
          src={DOWNGREENARROW}
          alt="cyan arrow"
          className="absolute w-[50px] z-50 left-[12%] bottom-[-14.8%] hidden secondary:block"
        />
      </div>
      <div className=" gap-20  grid md:grid-cols-2 grid-cols-1 !mx-auto items-stretch secondary:w-[90%] w-full  my-10">
        <div className="relative h-[100%]">
          <div className=" flex  m-auto  !bg-white relative  rounded-[30px]">
            <div className="text-white text-primary  numbers    absolute z-10   top-[-10px] secondary:left-[-10px] left-0 ">
              2
            </div>

            <div className="my-14 mx-10 ">
              <div className="">
                <h1 className="text-primary secondary:block hidden font-bold  text-center">
                  Lege Deine Empfehlungsgeber an
                </h1>
                <h1 className="secondary:hidden block text-[28px] font-bold  text-center">
                  Lege Deine Empfehlungsgeber an
                </h1>
                <p className="text-[#868686] font-[600] text-[1rem]">
                  Mit wenigen Klicks Empfehlungsgeber als Promoter im System
                  anlegen und den persönlichen Empfehlungs-Link versenden. Ab
                  jetzt sind Deine Promoter ausgestattet und können loslegen.
                </p>
              </div>
            </div>
            <img
              src={RIGhTGREENARROW}
              alt="cyan arrow"
              style={{ top: 120 }}
              className="absolute w-[150px] z-50 left-[92%] hidden secondary:block"
            />
          </div>
        </div>
        <div className=" ">
          <div className=" flex  m-auto relative  !bg-white rounded-[30px]">
            <div className="text-white text-primary numbers absolute z-10   top-[-10px] secondary:left-[-10px] left-0 ">
              3
            </div>

            <div className="my-14 mx-10 ">
              <div className="">
                <h1 className="text-[28px] font-bold secondary:hidden  text-center">
                  Empfehlungen melden sich bei Dir
                </h1>
                <h1 className="text-primary secondary:block hidden font-bold  text-center">
                  Empfehlungen melden sich bei Dir
                </h1>
                <p className="text-[#868686] font-[600] text-[1rem]">
                  Neue Empfehlungen gelangen über Deine Online Visitenkarte
                  direkt in Dein System. Du hast auch bereits erste
                  Informationen zu Ihren Hauptanliegen. Ab jetzt gilt es nur
                  noch Deinen Job zu machen! ;)
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="relative rounded-[30px] ">
          <img
            src={CURVEDARROW}
            alt="cyan arrow"
            className="absolute w-[90px] z-50 top-[-20%] right-[12%] hidden secondary:block"
          />
          <div className="relative flex justify-center secondary:w-[90%] w-[95%] m-auto sm:min-h-[400px]  ">
            <div className="text-white  text-primary numbers absolute z-10 secocondary:left-[-25px] left-[-10px] md:top-[60px] top-[-10px] sm:top-0">
              4
            </div>
            <div className="rounded-bg-blue-second  absolute md:!h-[80%] !h-full bottom-0 w-full right-0 left-0  m-auto"></div>
            <div className="z-10 items-center  secondary:flex hidden relative  gap-20 justify-around w-[90%] m-auto pt-14 ">
              <div className="w-[30%] py-5 border-r-2 border-r-white">
                <p className="text-primary w-[90%] text-start m-auto my-10 font-bold text-white">
                  Das clevere System
                </p>
              </div>

              <div className="w-[50%]">
                <p className="text-[#D8D8D8] text-[1.1rem]">
                  Nachfolgend weisst du immer genau was passiert. Du weisst
                  welche Empfehlung von welchem Empfehlungsgeber kommt. Das
                  System denkt für dich mit, übernimmt und erinnert Dich an
                  wichtige Aufgaben. Deine Promoter werden automatisch für jede
                  Empfehlung mit tollen Prämien belohnt. Es wird eine
                  individuelle Leistungsformel erstellt. So weisst Du ganz genau
                  an welchen Stellen Dein Arbeitskreislauf verbessert werden
                  kann. Und noch so viel mehr…
                </p>
              </div>
            </div>
            <div className="z-10 items-center  secondary:hidden block  relative flex-col gap-10 justify-around w-[90%] m-auto  ">
              <div className=" py-2 ">
                <p className="text-responsiveHeading  text-center  sm:text-start m-auto sm:my-10 pt-3 sm:pt-10 font-bold text-white">
                  Das clevere System
                </p>
              </div>

              <div className="mb-10 text-start">
                <p className="text-[#D8D8D8] text-sm">
                  Nachfolgend weisst du immer genau was passiert. Du weisst
                  welche Empfehlung von welchem Empfehlungsgeber kommt. Das
                  System denkt für dich mit, übernimmt und erinnert Dich an
                  wichtige Aufgaben. Deine Promoter werden automatisch für jede
                  Empfehlung mit tollen Prämien belohnt. Es wird eine
                  individuelle Leistungsformel erstellt. So weisst Du ganz genau
                  an welchen Stellen Dein Arbeitskreislauf verbessert werden
                  kann. Und noch so viel mehr…
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center sm:text-[1.75rem] text-responsiveHeading font-semibold my-10">
        <p> Keine Sorge - es bleibt einfach und Intuitiv! </p>
        <p>
          Und mit <u className="text-trkis">unserem kostenlosen ONBOARDING</u>{" "}
          bringen wir auch Technikgegner in die Spur!
        </p>
      </div>
      <div className="flex justify-center items-center ">
        <button className="bg-white relative bottom-[-30px] sm:bottom-[-50px] rounded-full landing-page-arrow-btn-drop flex justify-center items-center  sm:w-[100px] w-[60px] sm:h-[100px] h-[60px] flex-shrink-0">
          <a href="#onboard">
            <img
              src="/images/doubleArrowDown.png"
              className="sm:w-auto w-[30px]"
              alt=""
            />
          </a>
        </button>
      </div>
    </div>
  );
};

export default Index;
