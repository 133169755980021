import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Footer from "../footer/Footer";
import Video from "../../shared/Video";
import BasicForm from "../../shared/BasicForm";
import WhatsappButton from "../../shared/WhatsappButton";
import { sendEventUser } from "../../../../../utils/analytics";
import TypeButton from "../../shared/TypeButton";

const BasicView = ({
  landingPageData,
  type,
  promoter_id,
  landingPageId,
  user,
  promoter,
  clickid,
  affiliate,
}) => {
  const { id, promoterID } = useParams();
  const [buttonType, setButtonType] = useState(
    landingPageData.activateWhatsApp
  );
  const [isBasicQuestion, setIsBasicForm] = useState(
    landingPageData.activateBasicQuestion
  );
  const [disableButtons, setDisableButtons] = useState(true);
  const navigate = useNavigate();
  const replacePlaceholders = (text, replacements) => {
    if (typeof text !== "string") {
      console.warn("Invalid text input for replacement:", text);
      return text;
    }

    Object.keys(replacements).forEach((key) => {
      const value = replacements[key];
      text = text.replace(new RegExp(key, "g"), value);
    });

    return text;
  };
  const promoterReplacements = {
    "{Promoter Vorname}": promoter?.firstname?.trim() || "Promoter Vorname",
    "{Promoter Nachname}": promoter?.lastname?.trim() || "Promoter Nachname",
    "{PPartnerVorname}": promoter?.firstname?.trim() || "PPartner Vorname",
    "{PPartnerNachname}": promoter?.lastname?.trim() || "PPartner Nachname",
  };

  const transformText = (text) => {
    return replacePlaceholders(text || "", promoterReplacements);
  };

  const transformedIntroductoryText = transformText(
    landingPageData.introductoryText
  );
  const transformedAccompanyingTextOne = transformText(
    landingPageData.accompanyingTextOne
  );
  const transformedBasicClosingText = transformText(
    landingPageData.basicClosingText
  );
  const transformedWhatsappText = transformText(landingPageData.whatsappText);

  return (
    <div className="w-full p-4 secondary:p-16">
      {isBasicQuestion ? (
        <div className="flex flex-col items-center secondary:px-6 py-4 pt-0 space-y-6 max-w-2xl mx-auto gap-8 p-2">
          <img
            src={landingPageData.logoSrc}
            alt="logo"
            className="h-[150px] -mb-6"
          />
          <div
            className="flex justify-center items-center text-xl font-semibold text-center relative overflow-anywhere whitespace-pre-line secondary:leading-[25px] leading-[25px] text-wrap"
            dangerouslySetInnerHTML={{
              __html: transformedIntroductoryText,
            }}
          ></div>
          <div className="flex flex-col secondary:flex-row gap-8">
            <img
              src={landingPageData.primary_image}
              className="float-right xs:object-cover w-full secondary:w-[60%] rounded-xl object-contain"
              alt="Description"
            />
            <p
              className={`relative secondary:-mt-[0.6rem]`}
              dangerouslySetInnerHTML={{
                __html: landingPageData.accompanyingTextOne,
              }}
            ></p>
          </div>
          <div
            className="text-center relative mb-10 font-semibold overflow-anywhere secondary:mt-14 whitespace-pre-line secondary:leading-[25px] leading-[25px] text-wrap"
            dangerouslySetInnerHTML={{
              __html: landingPageData.accompanyingTextTwo,
            }}
          ></div>
          <TypeButton
            className="mx-4 mt-4 mb-14"
            type={type}
            text={landingPageData.questionaireButtonText}
            isBasicQuestion={true}
            style={{
              background: "linear-gradient(90deg, #FF7E5F 0%, #FEB47B 100%)",
              color: "#fff",
              border: "none",
              padding: "10px 20px",
              borderRadius: "8px",
            }}
            onClick={() => {
              try {
                sendEventUser({
                  category: "button",
                  action: `click_qf`,
                  label: "qf",
                  value: 1,
                  landingPageId: landingPageData._id,
                });
              } catch (error) {
                console.error("Analytics error (Vercel ENV):", error);
              }
              navigate(
                `/questionare/${type}/${landingPageId}/${promoter_id}/${clickid}`,
                { state: { affiliate } }
              );
            }}
          />
        </div>
      ) : (
        <div className="flex flex-col items-center secondary:px-6 py-4 pt-0 space-y-6 max-w-2xl mx-auto gap-4">
          <img
            src={landingPageData.logoSrc}
            alt="logo"
            className="h-[150px] -mb-6"
          />

          <div
            className="flex justify-center items-center text-xl font-semibold text-center relative overflow-anywhere whitespace-pre-line secondary:leading-[25px] leading-[25px] text-wrap"
            dangerouslySetInnerHTML={{
              __html: transformedIntroductoryText,
            }}
          ></div>

          <Video
            url={landingPageData.persentationVideo}
            thumbnail={landingPageData.thumbnail}
            className="max-w-full"
            type={"basic"}
          />

          <div
            className="text-center relative mb-10 font-semibold overflow-anywhere secondary:mt-14 whitespace-pre-line secondary:leading-[25px] leading-[25px] text-wrap"
            dangerouslySetInnerHTML={{
              __html: transformedAccompanyingTextOne,
            }}
          ></div>

          {buttonType ? (
            <WhatsappButton
              number={landingPageData?.whatsappNumber}
              whatsappText={transformedWhatsappText}
              className="py-14 mx-4 block"
              text={landingPageData.whatsappButtonTextOne}
              activateWhatsApp={landingPageData.activateWhatsApp}
              whatsAppButtonColor={landingPageData.whatsAppButtonColor}
              type="basic"
              sendGoogleAnalytics={() =>
                sendEventUser({
                  category: "button",
                  action: `click_wa1`,
                  label: "wa1",
                  value: 1,
                  landingPageId: landingPageData._id,
                })
              }
            />
          ) : (
            <BasicForm
              landingPageData={landingPageData}
              promoter_id={promoter_id}
              promoter={promoter}
              clickId={clickid}
              id={id}
              user={user}
            />
          )}
          <p className="text-sm text-gray-500">{transformedBasicClosingText}</p>
        </div>
      )}
      <div className="w-full">
        <Footer
          user={user}
          ladingPageId={landingPageId}
          landingPageData={landingPageData}
          promoter_id={promoter_id}
          type={type}
        />
      </div>
    </div>
  );
};

export default BasicView;
