import { Button, Card, Tooltip } from "antd";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Link } from "react-router-dom";
import { useMessageContext } from "../../../../context/messageContext";
import { phoneRegex } from "../../../../utils/validation";
import ClipboardButtonsBar from "../../../Dashboard/kampaign/ClipboardButtonsBar";
import Inputs from "../../shared/Input";
import Box from "../Layout/Box";
import SwitchTitle from "../shared/SwitchTitle";
import { useCredentials } from "../../../../hooks/useCredentails";

const WhatsAppConfig = forwardRef(
  (
    {
      landingPageData: {
        whatsappNumber,
        whatsappText,
        activateWhatsApp,
        theme,
        activateBasicQuestion,
      },
      disabled,
      onSwitchChange, // Neuer Callback
    },
    ref
  ) => {
    const { showMessage } = useMessageContext();
    const [whatsappNumberState, setWhatsappNumberState] =
      useState(whatsappNumber);
    const [whatsappTextState, setwhatsappTextState] = useState(whatsappText);
    const [activateWhatsAppState, setActivateWhatsappState] =
      useState(activateWhatsApp);
    const { admin } = useCredentials();
    const names = [
      "Promoter Vorname",
      "Promoter Nachname",
      ...(admin ? ["PPartnerVorname", "PPartnerNachname"] : []),
    ];

    useImperativeHandle(ref, () => ({
      async save() {
        if (disabled) return;

        console.log("WhatsAppConfig.save()");
        const deactivate =
          !phoneRegex.test(whatsappNumberState) && activateWhatsAppState;
        const changeTo49 =
          phoneRegex.test(whatsappNumberState) &&
          whatsappNumberState.startsWith("0");
        let numberToSend = whatsappNumberState;

        if (deactivate) {
          showMessage(
            "error",
            "WhatsApp deaktiviert: Bitte gültige Nummer einfügen."
          );
          setActivateWhatsappState(false);
        }

        if (changeTo49) {
          showMessage("info", "WhatsApp-Nummer verändert: +49");
          numberToSend = `+49 ${whatsappNumberState.substring(1)}`;
          setWhatsappNumberState(numberToSend);
        }

        return {
          landingPageData: {
            whatsappNumber: numberToSend,
            whatsappText: whatsappTextState,
            activateWhatsApp: deactivate ? false : activateWhatsAppState,
          },
        };
      },
    }));

    const handleSwitchChange = (value) => {
      setActivateWhatsappState(value);
      if (onSwitchChange) {
        onSwitchChange(value); // Callback an Elternkomponente
      }
    };

    return (
      <Box>
        <div className="flex gap-8 items-center">
          <SwitchTitle
            checked={disabled ? false : activateWhatsAppState}
            onChange={handleSwitchChange} // Verwendung des neuen Handlers
          >
            WhatsApp-Einstellungen
          </SwitchTitle>
          {theme === "basic" && !activateBasicQuestion && (
            <Tooltip
              placement="topLeft"
              title={
                !disabled && activateWhatsAppState
                  ? `Die Empfehlung gelangt in einen WhatsApp Chat mit dir.`
                  : `Anzeige Kontaktformularanzeige`
              }
            >
              <Button>i</Button>
            </Tooltip>
          )}
        </div>

        {disabled ? (
          <div className="h-[200px] relative">
            <div
              style={{ zIndex: 99999999999999999 }}
              className="absolute inset-0 bg-gray-100 backdrop-filter backdrop-blur-md"
            />
            <Card
              style={{ zIndex: 99999999999999999 }}
              className="absolute top-0 left-1/2 transform -translate-x-1/2 p-2 text-center"
            >
              <h2 className="text-2xl font-bold mb-4">Upgrade benötigt</h2>
              <p className="mb-4">
                Um auf diesen Bereich zugreifen zu können, musst du auf eine
                höhere Abonnementstufe upgraden.
              </p>
              <Link to="/dashboard/upgrade">
                <Button type="primary" className="bg-[#001D47] h-[40px] mr-2">
                  Upgrade
                </Button>
              </Link>
            </Card>
          </div>
        ) : (
          <>
            <ClipboardButtonsBar full={true} names={names} />
            <Inputs
              tel={true}
              value={whatsappNumberState}
              onChange={(e) => setWhatsappNumberState(e.target.value)}
              label={"Deine WhatsApp Telefonnummer"}
              emptyCheck={activateWhatsAppState}
            />
            <Inputs
              value={whatsappTextState}
              onChange={(e) => setwhatsappTextState(e.target.value)}
              label={"Vorbelegter Einleitungssatz"}
              emptyCheck={activateWhatsAppState}
            />
          </>
        )}
      </Box>
    );
  }
);

export default WhatsAppConfig;
