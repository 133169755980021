import React, { useState } from "react";
import { ChevronLeftIcon, ChevronRightIcon, ImagePlus } from "lucide-react";

// Alle verfügbaren Icons als Bilddateien
const imageList = [
  "bookmark.png",
  "bulb.png",
  "chart.png",
  "copy.png",
  "crown.png",
  "cube.png",
  "file.png",
  "flag.png",
  "flash.png",
  "folder.png",
  "forward.png",
  "gift.png",
  "link.png",
  "lock.png",
  "money.png",
  "pin.png",
  "plus.png",
  "rocket.png",
  "settings.png",
  "shield.png",
  "star.png",
  "target.png",
  "thumbs.png",
  "tick.png",
  "wallet.png",
];

// Der Ordnerpfad für die 3D-Icons
const IMAGE_PATH = "/3dicon/";

function ImagePicker({ selectedImage, onChange }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const ITEMS_PER_PAGE = 6;
  const totalPages = Math.ceil(imageList.length / ITEMS_PER_PAGE);
  const startIndex = pageIndex * ITEMS_PER_PAGE;
  const currentImages = imageList.slice(
    startIndex,
    startIndex + ITEMS_PER_PAGE
  );

  const handleNextPage = () => setPageIndex((prev) => (prev + 1) % totalPages);
  const handlePrevPage = () =>
    setPageIndex((prev) => (prev - 1 + totalPages) % totalPages);

  return (
    <div>
      {/* Anzeige des ausgewählten Icons */}
      <div className="relative w-fit">
        <button
          onClick={() => setIsModalOpen(true)}
          className="bg-transparent focus:outline-none"
        >
          {selectedImage ? (
            <img
              src={`${IMAGE_PATH}${selectedImage}`}
              alt="Selected Icon"
              className="w-32 h-32 object-contain rounded"
            />
          ) : (
            <div className="w-16 h-16 flex items-center justify-center bg-gray-200 text-gray-500 rounded-lg">
              Kein Bild
            </div>
          )}
        </button>

        {/* Overlay-Icon oben rechts */}
        <div
          onClick={() => setIsModalOpen(true)}
          className="absolute top-1 right-1 bg-white dark:bg-slate-700 p-1 rounded-full shadow cursor-pointer"
          title="Icon bearbeiten"
        >
          <ImagePlus className="w-5 h-5 text-gray-700 dark:text-gray-300" />
        </div>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          {/* Hintergrund-Overlay */}
          <div
            className="absolute inset-0 bg-black opacity-50"
            onClick={() => setIsModalOpen(false)}
          />

          {/* Modal-Fenster */}
          <div className="bg-white dark:bg-slate-800 p-4 rounded shadow-lg z-10 max-w-md w-full">
            <h2 className="text-xl mb-4">Wähle ein Bild</h2>

            {/* Grid mit Icons */}
            <div className="grid grid-cols-3 grid-rows-2 gap-4">
              {currentImages.map((img) => (
                <button
                  key={img}
                  onClick={() => {
                    onChange(img);
                    setIsModalOpen(false);
                  }}
                  className="flex flex-col items-center justify-center p-2 border rounded hover:bg-gray-100 dark:hover:bg-slate-700 bg-transparent"
                >
                  <img
                    src={`${IMAGE_PATH}${img}`}
                    alt={img}
                    className="w-32 h-32 object-contain"
                  />
                </button>
              ))}
            </div>

            {/* Navigation durch die Bilder */}
            <div className="flex justify-between items-center mt-4">
              <button
                onClick={handlePrevPage}
                className="flex items-center space-x-1 text-blue-600 hover:text-blue-800 transition"
              >
                <ChevronLeftIcon className="w-5 h-5" />
                <span>Zurück</span>
              </button>

              <button
                onClick={handleNextPage}
                className="flex items-center space-x-1 text-blue-600 hover:text-blue-800 transition"
              >
                <span>Weiter</span>
                <ChevronRightIcon className="w-5 h-5" />
              </button>
            </div>

            {/* Schließen-Buttons */}
            <div className="flex justify-end mt-4">
              <button
                onClick={() => {
                  onChange(null);
                  setIsModalOpen(false);
                }}
                className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 mr-4"
              >
                Kein Bild
              </button>
              <button
                onClick={() => setIsModalOpen(false)}
                className="text-blue-600 hover:underline"
              >
                Schließen
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ImagePicker;
