import React, { useState } from "react";

const EditableButton = ({
  text,
  onSave,
  className = "",
  style = {},
  ...props
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [currentText, setCurrentText] = useState(text);

  const handleSave = () => {
    onSave(currentText);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setCurrentText(text);
    setIsEditing(false);
  };

  if (isEditing) {
    return (
      <div className={`flex items-center ${className}`} style={style}>
        <input
          type="text"
          value={currentText}
          onChange={(e) => setCurrentText(e.target.value)}
          className="p-2 border border-gray-300 rounded text-slate-950"
        />
        <button
          onClick={handleSave}
          className="ml-2 px-2 py-1 bg-blue-500 text-white rounded"
        >
          OK
        </button>
        <button
          onClick={handleCancel}
          className="ml-2 px-2 py-1 bg-gray-300 text-black rounded"
        >
          Cancel
        </button>
      </div>
    );
  }

  return (
    <button
      onClick={() => setIsEditing(true)}
      className={className}
      style={style}
      {...props}
    >
      {currentText}
    </button>
  );
};

export default EditableButton;
