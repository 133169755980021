import { Dropdown, Space } from "antd";
import React from "react";
import FloatingLabel from "../../FloatingLabel/index";

const Inputs = ({
  type,
  value,
  onChange,
  label,
  withDropdown = false,
  tel = false,
  consideredNiceToHaveAnswer = true,
  onChangeNiceToHave = () => {},
  onDeleteAnswer = () => {},
  emptyCheck = true,
  emptyCheckWithText = true,
}) => {
  const isEmpty = emptyCheck && value === "";
  switch (type) {
    case "textarea":
      return (
        <FloatingLabel
          keepitfocused={true}
          style={{ color: "black" }}
          label={label}
          showEmptyText={isEmpty && emptyCheckWithText}
        >
          <textarea
            value={value}
            onChange={onChange}
            style={{ color: "black" }}
            className={`border-[1px] dark:!text-slate-950 !text-slate-950 font-medium !w-full transition-all ${
              consideredNiceToHaveAnswer
                ? "border-[#d8d8d8] "
                : "border-redattendtion pr-[42px] "
            } ${
              isEmpty && emptyCheckWithText
                ? "bg-red-100 bg-opacity-50 "
                : "bg-white "
            }  outline-none rounded-[15px] p-[15px_12.5px]`}
          />
          {withDropdown && (
            <DropdownMenu
              onChangeNiceToHave={onChangeNiceToHave}
              consideredNiceToHaveAnswer={consideredNiceToHaveAnswer}
              onDeleteAnswer={onDeleteAnswer}
            />
          )}
        </FloatingLabel>
      );

    case "input":
    default:
      return (
        <FloatingLabel
          label={label}
          className={"!w-full"}
          style={{ color: "black" }}
          unFocusClass="!top-[15px]  !text-[16px] !font-semibold"
          keepitfocused
          showEmptyText={isEmpty && emptyCheckWithText}
        >
          <input
            type={tel ? "tel" : "text"}
            autoComplete={tel ? "tel" : "false"}
            value={value}
            style={{ color: "black" }}
            onChange={onChange}
            className={`border-[1px] font-semibold !w-full !text-slate-950 dark:!text-slate-950 transition-all ${
              consideredNiceToHaveAnswer
                ? "border-[#d8d8d8] "
                : "border-redattendtion pr-[42px]"
            } ${
              isEmpty && emptyCheckWithText
                ? "bg-red-100 bg-opacity-50 "
                : "bg-white "
            }  outline-none rounded-[15px] p-[15px_12.5px]`}
          />
          {withDropdown && (
            <DropdownMenu
              onChangeNiceToHave={onChangeNiceToHave}
              consideredNiceToHaveAnswer={consideredNiceToHaveAnswer}
              onDeleteAnswer={onDeleteAnswer}
            />
          )}
        </FloatingLabel>
      );
  }
};

const DropdownMenu = ({
  onDeleteAnswer,
  consideredNiceToHaveAnswer,
  onChangeNiceToHave,
}) => {
  return (
    <Dropdown
      arrow={false}
      menu={{
        items: [
          {
            label: "Antwort löschen",
            onClick: () => onDeleteAnswer(),
          },
          {
            label: consideredNiceToHaveAnswer
              ? "Als Ablehnungsantwort markieren"
              : "Als akzeptierte Antwort markieren",
            onClick: () => onChangeNiceToHave(!consideredNiceToHaveAnswer),
          },
        ],
      }}
    >
      <Space className="flex items-start border border-lightgray bg-white rounded-full w-[30px] h-[30px] justify-center  p-0 absolute top-[15px] right-[12.5px]">
        ...
      </Space>
    </Dropdown>
  );
};

export default Inputs;
