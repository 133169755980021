import { Autoplay, FreeMode } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import { useEffect, useRef } from "react";

const Slide = ({ items, className, speed }) => {
  const swiperRef = useRef(null);
  console.log(items);

  useEffect(() => {
    if (!swiperRef.current) return;
    const swiperInstance = swiperRef.current?.swiper;
    if (!swiperInstance || !swiperInstance.el) return;
    const handleTouchStart = () => {
      if (swiperInstance && swiperInstance.autoplay.running) {
        swiperInstance.autoplay.stop();
      }
    };

    const handleTouchEnd = () => {
      if (swiperInstance && !swiperInstance.autoplay.running) {
        swiperInstance.autoplay.start();
      }
    };

    if (swiperInstance) {
      swiperInstance.el.addEventListener("touchstart", handleTouchStart);
      swiperInstance.el.addEventListener("touchend", handleTouchEnd);
    }

    return () => {
      if (swiperInstance?.el) {
        try {
          swiperInstance.el.removeEventListener("touchstart", handleTouchStart);
          swiperInstance.el.removeEventListener("touchend", handleTouchEnd);
        } catch (e) {
          console.error("Cleanup error:", e);
        }
      }
    };
  }, []);

  return (
    <Swiper
      ref={swiperRef}
      dir="ltr"
      loop={true}
      autoplay={{
        delay: 1700,
        disableOnInteraction: false,
      }}
      speed={speed}
      slidesPerView="auto"
      spaceBetween={0}
      grabCursor={true}
      modules={[FreeMode, Autoplay]}
      className={`${className} h-min`}
      touchEventsTarget="container"
      nested={true}
      passiveListeners={false}
    >
      {items.map((item, index) => (
        <SwiperSlide
          key={index}
          style={{ width: "fit-content", paddingLeft: 0 }}
          className={`my-auto`}
        >
          {item}
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Slide;
