import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useRef,
  useEffect,
} from "react";
import { Button, Tooltip } from "antd";
import { useScreenWidth } from "../../../LandingPageConfig/useScreenWidth";
import CollapseItem from "../shared/CollapseItem";
import HTMLTextEditor from "../../../Dashboard/kampaign/HtmlEditor";
import ClipboardButtonsBar from "../../../Dashboard/kampaign/ClipboardButtonsBar";
import UserService from "../../../../service/UserService";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { useCredentials } from "../../../../hooks/useCredentails";

const BegleitTextConfig = forwardRef(
  ({ landingPageData: { begleitTextTemplate } }, ref) => {
    const [versandTemplate, setVersandTemplate] = useState(
      begleitTextTemplate || ""
    );
    const quillRef = useRef();
    const { width } = useScreenWidth();
    const { admin } = useCredentials();
    const names = [
      "Promoter Vorname",
      "Promoter Nachname",
      "Partner Vorname",
      "Partner Nachname",
      "Promoterseite",
      "Promolink",
      ...(admin ? ["PPartner Vorname", "PPartner Nachname"] : []),
    ];

    const isEmptyHtml = (html) => {
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = html;
      return tempDiv.innerText.trim() === "";
    };

    useEffect(() => {
      const synchBegleitText = async () => {
        const { data } = await UserService.me();
        const isEmpty = isEmptyHtml(begleitTextTemplate);
        if (begleitTextTemplate === "" || isEmpty) {
          setVersandTemplate(data.user.promotionWhatsApp);
        } else {
          setVersandTemplate(begleitTextTemplate || "");
        }
      };
      synchBegleitText();
    }, [begleitTextTemplate]);

    useImperativeHandle(ref, () => ({
      async save() {
        console.log("BegleitTextConfig.save()");
        return {
          landingPageData: {
            begleitTextTemplate: versandTemplate,
          },
        };
      },
    }));

    return (
      <div className="flex w-full text-left">
        <CollapseItem
          style="p-8"
          reduceRandMobile
          title={
            <div className="flex items-center gap-3 trkisswitch">
              <div className="text-base font-semibold text-left lg:text-9xl sm:text-xl">
                Promotionlink Begleittext
              </div>
            </div>
          }
          icon={
            <Tooltip title="Kopierst du den Link in der Registerkarte des Promotors, wird er in diese Nachricht eingebettet um ihn deinem Promoter per Whatsapp zu senden. Die Formulierung ist aus der Sicht des Promoters gewählt, damit er Deine Nachricht nur kopieren und ohne Aufwand direkt weiterleiten kann. Im Empfehlungsportal dient es der Nachrichtenvorlage und dein Promoter kann diese selbst anpassen.">
              <Button
                type="default"
                className={`${
                  width < 550
                    ? "min-w-[25px] h-[25px]"
                    : "min-w-[40px] h-[40px]"
                } p-0 justify-center items-center rounded-[10px] text-center border-[1px] border-[#d8d8d8] `}
              >
                <AiOutlineInfoCircle fontSize={width < 550 ? 14 : 20} />
              </Button>
            </Tooltip>
          }
        >
          <div className="flex flex-col items-start justify-start gap-[1.88rem] py-4">
            <ClipboardButtonsBar names={names} />
            <div className="relative w-full text-[1rem]">
              {typeof versandTemplate === "string" && (
                <HTMLTextEditor
                  label={"Nachricht WhatsApp"}
                  value={versandTemplate}
                  onChange={(e) => {
                    setVersandTemplate(e);
                  }}
                  quillattributes={{
                    className: "w-full",
                  }}
                  options={{ ref: quillRef }}
                  variables={[
                    "{Promoter Vorname}",
                    "{Promoter Nachname}",
                    "{Partner Vorname}",
                    "{Partner Nachname}",
                    "{Promoterseite}",
                    "{Promolink}",
                  ]}
                />
              )}
            </div>
          </div>
        </CollapseItem>
      </div>
    );
  }
);

export default BegleitTextConfig;
